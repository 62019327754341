// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: min-common.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "min";

export enum ServiceSource {
  UNKNOWN = 0,
  GATEWAY = 1,
  LPU = 2,
  STT = 3,
  TTS = 4,
  VAD = 5,
  ANIMATION = 6,
  UNRECOGNIZED = -1,
}

export function serviceSourceFromJSON(object: any): ServiceSource {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return ServiceSource.UNKNOWN;
    case 1:
    case "GATEWAY":
      return ServiceSource.GATEWAY;
    case 2:
    case "LPU":
      return ServiceSource.LPU;
    case 3:
    case "STT":
      return ServiceSource.STT;
    case 4:
    case "TTS":
      return ServiceSource.TTS;
    case 5:
    case "VAD":
      return ServiceSource.VAD;
    case 6:
    case "ANIMATION":
      return ServiceSource.ANIMATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ServiceSource.UNRECOGNIZED;
  }
}

export function serviceSourceToJSON(object: ServiceSource): string {
  switch (object) {
    case ServiceSource.UNKNOWN:
      return "UNKNOWN";
    case ServiceSource.GATEWAY:
      return "GATEWAY";
    case ServiceSource.LPU:
      return "LPU";
    case ServiceSource.STT:
      return "STT";
    case ServiceSource.TTS:
      return "TTS";
    case ServiceSource.VAD:
      return "VAD";
    case ServiceSource.ANIMATION:
      return "ANIMATION";
    case ServiceSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Codec {
  mimeType: string;
  clockRate: number;
  channels: number;
}

export interface Text {
  text: string;
  isFinal: boolean;
}

export interface Speech {
  audio: Uint8Array;
  codec?: Codec | undefined;
}

export interface Video {
  video: Uint8Array;
  codec?: Codec | undefined;
}

export interface Image {
  image: Uint8Array;
  codec?: string | undefined;
}

export interface Empty {
}

export interface SingledId {
  id: string;
}

export interface MapData {
  data: { [key: string]: string };
}

export interface MapData_DataEntry {
  key: string;
  value: string;
}

function createBaseCodec(): Codec {
  return { mimeType: "", clockRate: 0, channels: 0 };
}

export const Codec = {
  encode(message: Codec, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mimeType !== "") {
      writer.uint32(10).string(message.mimeType);
    }
    if (message.clockRate !== 0) {
      writer.uint32(16).uint32(message.clockRate);
    }
    if (message.channels !== 0) {
      writer.uint32(24).uint32(message.channels);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Codec {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCodec();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mimeType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.clockRate = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.channels = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Codec {
    return {
      mimeType: isSet(object.mimeType) ? globalThis.String(object.mimeType) : "",
      clockRate: isSet(object.clockRate) ? globalThis.Number(object.clockRate) : 0,
      channels: isSet(object.channels) ? globalThis.Number(object.channels) : 0,
    };
  },

  toJSON(message: Codec): unknown {
    const obj: any = {};
    if (message.mimeType !== "") {
      obj.mimeType = message.mimeType;
    }
    if (message.clockRate !== 0) {
      obj.clockRate = Math.round(message.clockRate);
    }
    if (message.channels !== 0) {
      obj.channels = Math.round(message.channels);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Codec>, I>>(base?: I): Codec {
    return Codec.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Codec>, I>>(object: I): Codec {
    const message = createBaseCodec();
    message.mimeType = object.mimeType ?? "";
    message.clockRate = object.clockRate ?? 0;
    message.channels = object.channels ?? 0;
    return message;
  },
};

function createBaseText(): Text {
  return { text: "", isFinal: false };
}

export const Text = {
  encode(message: Text, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== "") {
      writer.uint32(10).string(message.text);
    }
    if (message.isFinal !== false) {
      writer.uint32(16).bool(message.isFinal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Text {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isFinal = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Text {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      isFinal: isSet(object.isFinal) ? globalThis.Boolean(object.isFinal) : false,
    };
  },

  toJSON(message: Text): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.isFinal !== false) {
      obj.isFinal = message.isFinal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Text>, I>>(base?: I): Text {
    return Text.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Text>, I>>(object: I): Text {
    const message = createBaseText();
    message.text = object.text ?? "";
    message.isFinal = object.isFinal ?? false;
    return message;
  },
};

function createBaseSpeech(): Speech {
  return { audio: new Uint8Array(0), codec: undefined };
}

export const Speech = {
  encode(message: Speech, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.audio.length !== 0) {
      writer.uint32(10).bytes(message.audio);
    }
    if (message.codec !== undefined) {
      Codec.encode(message.codec, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Speech {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpeech();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.audio = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.codec = Codec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Speech {
    return {
      audio: isSet(object.audio) ? bytesFromBase64(object.audio) : new Uint8Array(0),
      codec: isSet(object.codec) ? Codec.fromJSON(object.codec) : undefined,
    };
  },

  toJSON(message: Speech): unknown {
    const obj: any = {};
    if (message.audio.length !== 0) {
      obj.audio = base64FromBytes(message.audio);
    }
    if (message.codec !== undefined) {
      obj.codec = Codec.toJSON(message.codec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Speech>, I>>(base?: I): Speech {
    return Speech.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Speech>, I>>(object: I): Speech {
    const message = createBaseSpeech();
    message.audio = object.audio ?? new Uint8Array(0);
    message.codec = (object.codec !== undefined && object.codec !== null) ? Codec.fromPartial(object.codec) : undefined;
    return message;
  },
};

function createBaseVideo(): Video {
  return { video: new Uint8Array(0), codec: undefined };
}

export const Video = {
  encode(message: Video, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.video.length !== 0) {
      writer.uint32(10).bytes(message.video);
    }
    if (message.codec !== undefined) {
      Codec.encode(message.codec, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Video {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVideo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.video = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.codec = Codec.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Video {
    return {
      video: isSet(object.video) ? bytesFromBase64(object.video) : new Uint8Array(0),
      codec: isSet(object.codec) ? Codec.fromJSON(object.codec) : undefined,
    };
  },

  toJSON(message: Video): unknown {
    const obj: any = {};
    if (message.video.length !== 0) {
      obj.video = base64FromBytes(message.video);
    }
    if (message.codec !== undefined) {
      obj.codec = Codec.toJSON(message.codec);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Video>, I>>(base?: I): Video {
    return Video.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Video>, I>>(object: I): Video {
    const message = createBaseVideo();
    message.video = object.video ?? new Uint8Array(0);
    message.codec = (object.codec !== undefined && object.codec !== null) ? Codec.fromPartial(object.codec) : undefined;
    return message;
  },
};

function createBaseImage(): Image {
  return { image: new Uint8Array(0), codec: undefined };
}

export const Image = {
  encode(message: Image, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.image.length !== 0) {
      writer.uint32(10).bytes(message.image);
    }
    if (message.codec !== undefined) {
      writer.uint32(18).string(message.codec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Image {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.image = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.codec = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Image {
    return {
      image: isSet(object.image) ? bytesFromBase64(object.image) : new Uint8Array(0),
      codec: isSet(object.codec) ? globalThis.String(object.codec) : undefined,
    };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.image.length !== 0) {
      obj.image = base64FromBytes(message.image);
    }
    if (message.codec !== undefined) {
      obj.codec = message.codec;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.image = object.image ?? new Uint8Array(0);
    message.codec = object.codec ?? undefined;
    return message;
  },
};

function createBaseEmpty(): Empty {
  return {};
}

export const Empty = {
  encode(_: Empty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Empty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Empty {
    return {};
  },

  toJSON(_: Empty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Empty>, I>>(base?: I): Empty {
    return Empty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Empty>, I>>(_: I): Empty {
    const message = createBaseEmpty();
    return message;
  },
};

function createBaseSingledId(): SingledId {
  return { id: "" };
}

export const SingledId = {
  encode(message: SingledId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingledId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingledId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingledId {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: SingledId): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SingledId>, I>>(base?: I): SingledId {
    return SingledId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SingledId>, I>>(object: I): SingledId {
    const message = createBaseSingledId();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseMapData(): MapData {
  return { data: {} };
}

export const MapData = {
  encode(message: MapData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.data).forEach(([key, value]) => {
      MapData_DataEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = MapData_DataEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.data[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapData {
    return {
      data: isObject(object.data)
        ? Object.entries(object.data).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: MapData): unknown {
    const obj: any = {};
    if (message.data) {
      const entries = Object.entries(message.data);
      if (entries.length > 0) {
        obj.data = {};
        entries.forEach(([k, v]) => {
          obj.data[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapData>, I>>(base?: I): MapData {
    return MapData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapData>, I>>(object: I): MapData {
    const message = createBaseMapData();
    message.data = Object.entries(object.data ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseMapData_DataEntry(): MapData_DataEntry {
  return { key: "", value: "" };
}

export const MapData_DataEntry = {
  encode(message: MapData_DataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapData_DataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapData_DataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapData_DataEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: MapData_DataEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MapData_DataEntry>, I>>(base?: I): MapData_DataEntry {
    return MapData_DataEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MapData_DataEntry>, I>>(object: I): MapData_DataEntry {
    const message = createBaseMapData_DataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
