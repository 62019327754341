import Markdown from "react-markdown";
import { materialLight } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import React from "react";
import "./MarkDown.scss";
import CodeCopyButton from "./CodeCopyButton";
import { Box, breakPoints } from "mds";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const MarkDown = ({ markdownSource }: { markdownSource: string }) => {
  const Pre = ({ children }: Props) => (
    <pre className="blog-pre">
      <CodeCopyButton>{children}</CodeCopyButton>
      {children}
    </pre>
  );

  return (
    <Box
      sx={{
        maxWidth: "calc(100% - 65px)",
        [`@media (max-width: ${breakPoints.sm}px)`]: {
          "& .line-break p": {
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          },
        },
      }}
    >
      <Markdown
        className={"line-break"}
        components={{
          pre: Pre,
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");

            return !inline && match ? (
              <SyntaxHighlighter
                // @ts-ignore
                style={materialLight}
                PreTag="div"
                language={match[1]}
                children={String(children).replace(/\n$/, "")}
                {...props}
              />
            ) : (
              <code className={className ? className : "boldcode"} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {markdownSource}
      </Markdown>
    </Box>
  );
};
export default MarkDown;
