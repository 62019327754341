import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, breakPoints, InputBox } from "mds";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ApiError } from "api/eurekaApi";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import SendPromptIcon from "./../SendPromptIcon";
import SuggestionBox from "../SugesstionBox";
import {
  setNavigateTo,
  setPrompt,
  setSemanticSuggestions,
  setShowSuggestions,
  setSuggestions,
} from "./textPromptSlice";
import { submitPrompt } from "./textPromptThunks";

const TextPrompt = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { roomID } = useParams();
  const formRef = useRef<HTMLFormElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const navigateTo = useAppSelector((state) => state.prompt.navigateToRoom);

  const prompt = useAppSelector((state) => state.prompt.prompt);
  const showSuggestions = useAppSelector(
    (state) => state.prompt.showSuggestions,
  );
  const suggestions = useAppSelector((state) => state.prompt.suggestions);
  const semanticSuggestions = useAppSelector(
    (state) => state.prompt.semanticSuggestions,
  );

  const [activeSuggestionIndex, setActiveSuggestionIndex] =
    useState<number>(-1);

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
      dispatch(setNavigateTo(null));
    }
  }, [navigateTo, dispatch, navigate]);

  const getSuggestions = useCallback(
    (value: string) => {
      if (value !== "") {
        setActiveSuggestionIndex(-1);
        api.prompt
          .suggestPrompts({ q: value })
          .then((res) => {
            if (res.data && res.data.suggestions) {
              dispatch(setShowSuggestions(true));
              dispatch(setSuggestions(res.data.suggestions));
            } else {
              dispatch(setSuggestions([]));
            }
          })
          .catch(async (res) => {
            dispatch(setSuggestions([]));
            const err = (await res.json()) as ApiError;
            console.log(err);
          });
        api.prompt
          .semanticSuggestionPrompts({ q: value })
          .then((res) => {
            if (res.data && res.data.suggestions) {
              dispatch(setShowSuggestions(true));
              dispatch(setSemanticSuggestions(res.data.suggestions));
            } else {
              dispatch(setSemanticSuggestions([]));
            }
          })
          .catch(async (res) => {
            dispatch(setSemanticSuggestions([]));
            const err = (await res.json()) as ApiError;
            console.log(err);
          });
      } else {
        dispatch(setSuggestions([]));
        dispatch(setSemanticSuggestions([]));
      }
    },
    [dispatch],
  );

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        dispatch(setShowSuggestions(false));
      }
    },
    [dispatch, wrapperRef],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (showSuggestions) {
        switch (e.key) {
          case "ArrowUp":
            console.log("here");
            e.preventDefault();
            setActiveSuggestionIndex((index) => Math.max(index - 1, -1));
            break;
          case "ArrowDown":
            e.preventDefault();
            setActiveSuggestionIndex((index) =>
              Math.min(index + 1, suggestions.length - 1),
            );
            break;
          case "Enter":
            if (
              activeSuggestionIndex > -1 &&
              activeSuggestionIndex < suggestions.length
            ) {
              dispatch(setPrompt(suggestions[activeSuggestionIndex].question!));
              dispatch(setShowSuggestions(false));
              e.preventDefault();
            }
            break;
          default:
            break;
        }
      }
    },
    [activeSuggestionIndex, showSuggestions, suggestions, dispatch],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleKeyDown, dispatch, handleClickOutside]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPrompt(e.target.value));
    setActiveSuggestionIndex(-1);
    if (e.target.value === "") {
      dispatch(setSuggestions([]));
      dispatch(setSemanticSuggestions([]));
    }
    onChangeDebounced(e.target.value);
  };

  const onChangeDebounced = useMemo(() => {
    return debounce(getSuggestions, 300);
  }, [getSuggestions]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
      ref={wrapperRef}
    >
      <form
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          if (activeSuggestionIndex >= 0) {
            dispatch(
              submitPrompt({
                inPrompt: suggestions[activeSuggestionIndex].question!,
                roomID: roomID,
              }),
            );
          } else {
            dispatch(
              submitPrompt({
                inPrompt: prompt,
                roomID: roomID,
              }),
            );
          }
        }}
        style={{ fontSize: 16, width: "100%" }}
      >
        {showSuggestions && (
          <div>
            {semanticSuggestions.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 16,
                  fontSize: 12,
                  marginBottom: 8,
                  marginRight: 30,
                  "& .suggestion-box": {
                    flex: "1 1 0px",
                    boxShadow: "0px 0px 8px #D3D3D3",
                  },
                  [`@media (max-width: ${breakPoints.md}px)`]: {
                    marginRight: 10,
                    flexDirection: "column",
                  },
                }}
              >
                {semanticSuggestions.map((sem, i) => (
                  <SuggestionBox
                    key={`sug-${i}`}
                    text={sem.question!}
                    onClick={() => {
                      dispatch(setPrompt(sem.question!));
                    }}
                  />
                ))}
              </Box>
            )}
            {suggestions.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 4,
                  marginBottom: 8,
                  marginRight: 30,
                  fontSize: 14,
                  color: "#666",
                  backgroundColor: "white",
                  fontFamily: "Inter, sans-serif",
                  boxShadow: "0px 0px 8px #D3D3D3",
                  "div:last-child": {
                    border: "0 !important",
                    paddingBottom: 8,
                    borderBottomRightRadius: 4,
                    borderBottomLeftRadius: 4,
                  },
                  "div:first-child": {
                    paddingTop: 8,
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                  },
                  [`@media (max-width: ${breakPoints.md}px)`]: {
                    marginRight: 10,
                    maxHeight: 200,
                    overflowY: "scroll",
                  },
                }}
              >
                {suggestions.map((s, index) => (
                  <Box
                    key={`semsug-${index}`}
                    style={{
                      padding: 22,
                      paddingTop: 16,
                      paddingBottom: 16,
                      background:
                        activeSuggestionIndex === index ? "#F7F9FC" : "inherit",
                      color:
                        activeSuggestionIndex === index ? "#000" : "inherit",
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        background: "rgb(221, 221, 221)",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setPrompt(s.question!));
                    }}
                  >
                    {s.question}
                  </Box>
                ))}
              </Box>
            )}
          </div>
        )}
        <Box
          sx={{
            marginRight: 30,
            "& #send-message": {
              height: 62,
              borderRadius: 6,
              boxShadow: "0px 0px 8px #EDF1F6",
              border: 0,
              paddingLeft: 26,
            },
            "& .overlayAction button": {
              background: "unset",
              width: 55,
              height: 55,
            },
            [`@media (max-width: ${breakPoints.md}px)`]: {
              marginRight: 10,
            },
          }}
        >
          <InputBox
            style={{ fontSize: 16 }}
            id={"send-message"}
            placeholder={"Ask me anything..."}
            value={prompt}
            onChange={onChange}
            onFocus={(event) => dispatch(setShowSuggestions(true))}
            overlayIcon={<SendPromptIcon />}
            overlayAction={() =>
              dispatch(submitPrompt({ inPrompt: prompt, roomID: roomID }))
            }
            required
          />
        </Box>
      </form>
    </div>
  );
};

export default TextPrompt;
