import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import App from "./screens/app/App";
import Logout from "./screens/Logout";
import Login from "./screens/login/Login";

function MainRouter() {
  return (
    <Routes>
      <Route path={"/login"} element={<Login />} />
      <Route path={"/logout"} element={<Logout />} />
      <Route path={"*"} element={<ProtectedRoute Component={App} />} />
      <Route path={"*"} element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default MainRouter;
