import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";
import { ApiError } from "api/eurekaApi";
import { setErrorSnackMessage } from "screens/systemSlice";

export const getRoomAsync = createAsyncThunk(
  "chat/getRoomAsync",
  async (_, { getState, rejectWithValue, dispatch }) => {
    return api.room
      .createRoom()
      .then((res) => {
        if (res.data !== undefined) {
          return res.data;
        }
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        dispatch(setErrorSnackMessage(err));
        return rejectWithValue(false);
      });
  },
);

export const startNewConversationAsync = createAsyncThunk(
  "chat/startNewConversationAsync",
  async (_, { getState, rejectWithValue, dispatch }) => {
    return api.room
      .createRoom()
      .then((res) => {
        if (res.data !== undefined) {
          return res.data;
        }
        return null;
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        dispatch(setErrorSnackMessage(err));
        return null;
      });
  },
);
