import { Button, ModalBox } from "mds";
import React from "react";

const DeleteDomain = ({
  onDelete,
  onCancel,
}: {
  onDelete: Function;
  onCancel: Function;
}) => {
  return (
    <ModalBox
      title={"Delete Domain"}
      open={true}
      onClose={() => onCancel()}
      customMaxWidth={420}
    >
      <div>
        <p>Are you sure you want to delete the domain?</p>
        <div
          style={{
            display: "flex",

            justifyContent: "end",
            gap: 8,
          }}
        >
          <Button
            id={"delete"}
            variant={"secondary"}
            onClick={() => onDelete()}
          >
            Delete
          </Button>
          <Button id={"cancel"} onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalBox>
  );
};
export default DeleteDomain;
