import { SectionTitle } from "mds";
import { SectionTitleProps } from "mds/dist/esm/types/components/SectionTitle/SectionTitle.types";

const ESectionTitle: React.FC<SectionTitleProps> = ({ children, ...props }) => (
  <SectionTitle
    sx={{
      borderBottom: 0,
      color: "#12222C",
      font: "normal normal bold 22px/26px Inter",
      "& div": { margin: 0 },
    }}
    {...props}
  >
    {children}
  </SectionTitle>
);

export default ESectionTitle;
