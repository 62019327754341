// Copyright 2024 - MinIO, Inc. All rights reserved.

import React, { Fragment } from "react";
import { useAppSelector } from "../../app/hooks";
import { Box } from "mds";
import RoomEventList from "./EventList";
import { useParams } from "react-router-dom";
import { MinSignal, useEmitSignalMutation } from "api/gatewayApiRTK";
// import { MinSignal, useEmitSignalMutation } from "../../api/gatewayApiRTK";

const DevMenu = () => {
  const { roomID } = useParams();

  const [emitSignal] = useEmitSignalMutation();

  const showMenu = useAppSelector((state) => state.system.showMenu);
  if (!showMenu) {
    return <Fragment />;
  }

  const sendSignal = (signal: MinSignal) => {
    if (roomID) {
      emitSignal({ minRoomSignal: { signal: signal, roomId: roomID } });
    }
  };

  return (
    <div style={{ flexGrow: 0, width: 320, borderLeft: "1px solid #dedede" }}>
      <Box sx={{ borderBottom: "1px solid #dedede", padding: 8 }}>
        <Box sx={{ fontWeight: "bold", marginBottom: 8 }}>Signals</Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 8,
            "& .signal": {
              fontSize: 12,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 2,
              paddingBottom: 2,
              borderRadius: 6,
              backgroundColor: "#dedede",
              "&:hover": {
                backgroundColor: "#e6e6e6",
                fontWeight: 600,
                cursor: "pointer",
              },
            },
          }}
        >
          <Box
            className={"signal"}
            onClick={() => sendSignal("SPEECH_STARTED")}
          >
            Speech Started
          </Box>
          <Box className={"signal"} onClick={() => sendSignal("SPEECH_DONE")}>
            Speech Done
          </Box>
          <Box className={"signal"} onClick={() => sendSignal("THINKING")}>
            Thinking
          </Box>
          <Box className={"signal"} onClick={() => sendSignal("IDLE")}>
            Idle
          </Box>
        </Box>
      </Box>
      <RoomEventList />
    </div>
  );
};
export default DevMenu;
