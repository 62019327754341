import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ApiError, Index } from "../../../api/eurekaApi";
import { api } from "../../../api";
import {
  AddIcon,
  DeleteIcon,
  IconButton,
  Loader,
  RecoverIcon,
  SectionTitle,
  SuccessIcon,
} from "mds";
import DeleteIndex from "./DeleteIndex";
import ErrorDisplay from "../../../components/ErrorDisplay";
import EDataTable from "components/EDataTable";
import GoodIcon from "../../../components/icons/GoodIcon";

const ListIndexes = ({
  collectionID,
  rerender,
}: {
  collectionID: string;
  rerender: boolean;
}) => {
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [results, setResults] = useState<Index[]>([]);
  const [holdIndex, setHoldIndex] = useState<Index | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  const getRecords = useCallback(() => {
    setError(null);
    api.collections
      .listIndexes(collectionID)
      .then((res) => {
        if (res.data && res.data.results) {
          setResults(res.data.results);
        }
        setLoading(false);
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        setError(err);
        setLoading(false);
      });
  }, [collectionID]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      setLoading(true);
      getRecords();
    }
  }, [iniLoad, getRecords]);

  useEffect(() => {
    if (iniLoad !== rerender) {
      setIniLoad(false);
      setLoading(true);
      getRecords();
    }
  }, [iniLoad, rerender, getRecords]);

  return (
    <div>
      {holdIndex !== null && (
        <DeleteIndex
          onDelete={() => {
            setLoading(true);
            api.collections
              .deleteIndex(holdIndex?.id!, collectionID)
              .then((res) => {
                setHoldIndex(null);
                setIniLoad(false);
                setLoading(false);
                getRecords();
              })
              .catch(async (res) => {
                const err = (await res.json()) as ApiError;
                setError(err);
                setHoldIndex(null);
                setLoading(false);
              });
          }}
          onCancel={() => setHoldIndex(null)}
        />
      )}
      <SectionTitle
        actions={
          <Fragment>
            <IconButton
              id={"add-collection"}
              onClick={() => {
                api.collections
                  .addIndex(collectionID)
                  .then(() => {
                    getRecords();
                  })
                  .catch(async (res) => {
                    const err = (await res.json()) as ApiError;
                    setError(err);
                    setLoading(false);
                  });
              }}
              disabled={loading}
            >
              <AddIcon />
            </IconButton>
          </Fragment>
        }
      >
        Indexes
      </SectionTitle>
      {error && <ErrorDisplay err={error} />}
      <EDataTable
        isLoading={loading}
        loadingMessage={<Loader />}
        itemActions={[
          {
            type: <SuccessIcon />,
            tooltip: "Set Default",
            onClick: (item: Index) => {
              api.collections
                .setDefaultIndex(item.id!, collectionID)
                .then((res) => {
                  getRecords();
                })
                .catch(async (res) => {
                  console.log(res);
                  const err = (await res.json()) as ApiError;
                  setError(err);
                });
            },
            isDisabled: (item: Index) => {
              if (item && item.is_default) {
                return item.is_default;
              }
              return false;
            },
          },
          {
            type: <RecoverIcon />,
            tooltip: "Re-Index",
            onClick: (item: Index) => {
              api.collections.reindex(item.id!, collectionID).then(() => {
                console.log("done");
              });
            },
          },
          {
            type: <DeleteIcon />,
            tooltip: "Delete",
            onClick: (item: Index) => {
              setHoldIndex(item);
            },
          },
        ]}
        columns={[
          {
            label: "Name",
            elementKey: "name",
          },
          {
            label: "Num Docs",
            elementKey: "num_docs",
            renderFullObject: true,
            renderFunction: (o: Index) => {
              return (
                <span>{(o.statistics?.row_count ?? 0).toLocaleString()}</span>
              );
            },
          },
          {
            label: "Status",
            elementKey: "status",
          },
          {
            label: "Default",
            elementKey: "is_default",
            renderFunction: (x) => {
              if (x) {
                return <GoodIcon />;
              }
              return <Fragment />;
            },
          },
        ]}
        entityName="Indexs"
        records={results}
      />
    </div>
  );
};
export default ListIndexes;
