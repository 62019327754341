import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../../app/store";
import { api } from "../../../api";
import { ApiError } from "../../../api/eurekaApi";
import { setErrorSnackMessage } from "../../systemSlice";

export const crawlAsync = createAsyncThunk(
  "crawl/crawlAsync",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as AppState;
    const url = state.crawl.url;
    const crawlEntireFlag = state.crawl.crawlEntireFlag;
    const maxPages = state.crawl.maxPages;
    return api.crawl
      .crawl({ url: url, crawl: crawlEntireFlag, num_pages_cap: maxPages })
      .then((res) => {
        if (res.data !== undefined) {
          return res.data;
        }
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        dispatch(setErrorSnackMessage(err));
        return null;
      });
  },
);
