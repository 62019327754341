import React from "react";

const CloseDrawerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.398"
      height="12.654"
      viewBox="0 0 12.398 12.654"
    >
      <g
        id="collapse-menu.37358aee3d41cc5598255cc50dd0794e"
        transform="translate(0.35 0.356)"
      >
        <path
          id="Trazado_8279"
          data-name="Trazado 8279"
          d="M3.48,5.34H11.2a.447.447,0,0,1,.37.11.36.36,0,0,1,.11.38.388.388,0,0,1-.3.29,1.275,1.275,0,0,1-.2.01H3.45c.05.05.08.09.11.12L6.33,9.02a.378.378,0,0,1,.12.4.388.388,0,0,1-.3.29.394.394,0,0,1-.37-.13l-.63-.63C4.2,8,3.24,7.05,2.3,6.09a.746.746,0,0,1-.18-.31.411.411,0,0,1,.15-.38c1-1,2-1.99,2.99-2.99.18-.18.36-.37.55-.54a.393.393,0,0,1,.48-.05.339.339,0,0,1,.17.41.565.565,0,0,1-.16.24c-.91.92-1.83,1.83-2.75,2.75l-.11.11Z"
          fill="#278ead"
          stroke="#278ead"
          strokeWidth="0.7"
        />
        <path
          id="Trazado_8280"
          data-name="Trazado 8280"
          d="M.83,5.97V.53A.749.749,0,0,0,.81.32.412.412,0,0,0,.51.01.387.387,0,0,0,.12.13.472.472,0,0,0,0,.52V5.97H0v5.44a.749.749,0,0,0,.02.21.4.4,0,0,0,.3.31.368.368,0,0,0,.39-.12.508.508,0,0,0,.12-.39V5.97h0Z"
          fill="#278ead"
          stroke="#278ead"
          strokeWidth="0.7"
        />
      </g>
    </svg>
  );
};
export default CloseDrawerIcon;
