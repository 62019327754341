import React, { Fragment, useEffect, useState } from "react";
import { Domain } from "../../../api/eurekaApi";
import { api } from "../../../api";
import { AddIcon } from "mds";
import { useNavigate } from "react-router-dom";
import EButton from "components/EButton";
import ESectionTitle from "components/ESectionTitle";
import EDataTable from "components/EDataTable";
import BadIcon from "../../../components/icons/BadIcon";
import GoodIcon from "../../../components/icons/GoodIcon";

const ListDomains = () => {
  const navigate = useNavigate();
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [results, setResults] = useState<Domain[]>([]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      api.domains
        .listDomains()
        .then((res) => {
          if (res.data && res.data.results) {
            setResults(res.data.results);
          }
        })
        .catch((e) => {
          console.warn("Error loading domains", e);
        });
    }
  }, [iniLoad]);

  return (
    <div>
      <ESectionTitle
        actions={
          <Fragment>
            <EButton
              id={"add-domain"}
              onClick={() => {
                navigate("/management/domains/add");
              }}
              icon={<AddIcon />}
            >
              Add
            </EButton>
          </Fragment>
        }
      >
        Domains
      </ESectionTitle>
      <EDataTable
        itemActions={[
          {
            type: "view",
            onClick: (item: Domain) => {
              navigate(`/management/domains/${item.id}`);
            },
          },
        ]}
        columns={[
          {
            label: "Name",
            elementKey: "name",
          },
          {
            label: "Collection",
            elementKey: "collection_id",
          },
          {
            label: "Threshold",
            elementKey: "match_threshold",
          },
          {
            label: "Endpoint",
            elementKey: "llm_endpoint",
          },
          {
            label: "Mode",
            elementKey: "mode",
          },
          {
            label: "Active",
            elementKey: "is_active",
            renderFunction: (x) => {
              if (x) {
                return <GoodIcon />;
              }
              return <BadIcon />;
            },
          },
          {
            label: "Default",
            elementKey: "is_default",
            renderFunction: (x) => {
              if (x) {
                return <GoodIcon />;
              }
              return <Fragment />;
            },
          },
        ]}
        entityName="Domains"
        records={results}
      />
    </div>
  );
};
export default ListDomains;
