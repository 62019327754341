import { DataTable, DataTableProps } from "mds";

const EDataTable: React.FC<DataTableProps> = ({ ...props }) => (
  <DataTable
    sx={{
      border: 0,
      font: "normal normal normal 14px/17px Inter",
      "& .rowLine:not(.ReactVirtualized__Table__headerRow)": {
        borderBottom: 0,
      },
    }}
    {...props}
  />
);

export default EDataTable;
