import React, { useState } from "react";
import { Button, ModalBox, Select } from "mds";
import { useAppSelector } from "../../../app/hooks";
import { SelectorType } from "mds/dist/cjs/types/global/global.types";
import { api } from "../../../api";
import { ApiError, ContentType } from "../../../api/eurekaApi";

const AddReport = ({
  onDone,
  onCancel,
}: {
  onDone: Function;
  onCancel: Function;
}) => {
  const domains = useAppSelector((state) => state.system.domains);
  const [error, setError] = useState<ApiError | null>(null);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const options: SelectorType[] = domains.map((v) => {
    return { label: v.name!, value: v.id! };
  });

  return (
    <ModalBox
      title={"Start Report"}
      open={true}
      onClose={() => onCancel()}
      customMaxWidth={420}
    >
      <div>
        <p>Select domain to start report:</p>
        {error && (
          <div
            style={{
              backgroundColor: "#ffa6a6",
              border: "1px solid red",
              borderRadius: 4,
              padding: 8,
              margin: 8,
            }}
          >
            <b>{error.message}</b>
            <p>{error.detailedMessage}</p>
          </div>
        )}
        <Select
          sx={{
            width: 320,
          }}
          id="domain-select"
          onChange={(newValue) => setSelectedDomain(newValue)}
          options={options}
          value={selectedDomain ?? ""}
        />
        <br />
        <div
          style={{
            display: "flex",

            justifyContent: "end",
            gap: 8,
          }}
        >
          <Button id={"cancel"} onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            id={"delete"}
            variant={"callAction"}
            disabled={!selectedDomain}
            onClick={() => {
              api.reports
                .addReport(
                  {
                    domain_id: selectedDomain!,
                  },
                  {
                    type: ContentType.Json,
                  },
                )
                .then((res) => {
                  onDone();
                })
                .catch((e) => {
                  setError(e.error);
                });
            }}
          >
            Start
          </Button>
        </div>
      </div>
    </ModalBox>
  );
};
export default AddReport;
