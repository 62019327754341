import React from "react";

const ETextIcon = () => {
  return (
    <svg
      id="Grupo_5912"
      data-name="Grupo 5912"
      xmlns="http://www.w3.org/2000/svg"
      width="10.231"
      height="14"
      viewBox="0 0 10.231 14"
      className={"min-icon"}
    >
      <rect id="Rectángulo_2678" width="10.231" height="2.692" fill="#268ead" />
      <rect
        id="Rectángulo_2679"
        width="8.611"
        height="2.692"
        transform="translate(0 5.923)"
        fill="#268ead"
      />
      <rect
        id="Rectángulo_2680"
        width="10.231"
        height="2.692"
        transform="translate(0 11.308)"
        fill="#268ead"
      />
    </svg>
  );
};
export default ETextIcon;
