import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import counterReducer from "features/counter/counterSlice";
import loginReducer from "screens/login/loginSlice";
import systemReducer from "screens/systemSlice";
import searchReducer from "screens/search/searchSlice";
import chatReducer from "screens/chat/chatSlice";
import talk2meReducer from "screens/chat/talk2meSlice";
import { websocketMiddleware } from "websockets/websocketMiddleware";
import transcriptReducer from "screens/document/transcript/transcriptSlice";
import questionsReducer from "screens/document/questions/questionsSlice";
import crawlSliceReducer from "screens/management/crawler/crawlSlice";
import textPromptReducer from "screens/chat/TextPrompt/textPromptSlice";
import { eurekaApi, eurekaApiV2 } from "../api/eurekaApiSlice";
import webrtcMiddleware from "../websockets/webrtcMiddleware";

let objectsWS: WebSocket;

const rootReducer = combineReducers({
  system: systemReducer,
  counter: counterReducer,
  login: loginReducer,
  search: searchReducer,
  chat: chatReducer,
  talk2me: talk2meReducer,
  transcript: transcriptReducer,
  questions: questionsReducer,
  crawl: crawlSliceReducer,
  prompt: textPromptReducer,
  [eurekaApi.reducerPath]: eurekaApi.reducer,
  [eurekaApiV2.reducerPath]: eurekaApiV2.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(eurekaApi.middleware)
      .concat(eurekaApiV2.middleware)
      .concat(websocketMiddleware(objectsWS))
      .concat(webrtcMiddleware()),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
