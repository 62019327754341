import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ListReports from "./ListReports";
import ReportDetails from "./ReportDetails";

const Reports = () => {
  return (
    <Routes>
      <Route path={"/:reportID"} element={<ReportDetails />} />
      <Route path={"/"} element={<ListReports />} />
      <Route path={"*"} element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Reports;
