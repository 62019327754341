/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Principal {
  STSAccessKeyID?: string;
  STSSecretAccessKey?: string;
  STSSessionToken?: string;
  accountAccessKey?: string;
  hm?: boolean;
  ob?: boolean;
  customStyleOb?: string;
}

export interface ApiError {
  message?: string;
  detailedMessage?: string;
}

export interface LoginDetails {
  loginStrategy?:
    | "form"
    | "redirect"
    | "service-account"
    | "redirect-service-account";
  redirectRules?: RedirectRule[];
  isK8S?: boolean;
  animatedLogin?: boolean;
}

export interface LoginOauth2AuthRequest {
  state: string;
  code: string;
}

export interface LoginRequest {
  accessKey?: string;
  secretKey?: string;
  sts?: string;
  features?: {
    hide_menu?: boolean;
  };
}

export interface LoginSuccess {
  token?: string;
}

export interface LoginResponse {
  sessionId?: string;
  IDPRefreshToken?: string;
}

export interface LogoutRequest {
  state?: string;
}

export interface PermissionResource {
  resource?: string;
  conditionOperator?: string;
  prefixes?: string[];
}

export interface EnvironmentConstants {
  maxConcurrentUploads?: number;
  maxConcurrentDownloads?: number;
}

export interface SessionResponse {
  features?: string[];
  status?: "ok";
  operator?: boolean;
  distributedMode?: boolean;
  serverEndPoint?: string;
  permissions?: Record<string, string[]>;
  customStyles?: string;
  allowResources?: PermissionResource[];
  envConstants?: EnvironmentConstants;
}

export interface RedirectRule {
  redirect?: string;
  displayName?: string;
  serviceType?: string;
}

export interface User {
  accessKey?: string;
  policy?: string[];
  memberOf?: string[];
  status?: string;
  hasPolicy?: boolean;
}

export interface IamPolicy {
  version?: string;
  statement?: IamPolicyStatement[];
}

export interface IamPolicyStatement {
  effect?: string;
  action?: string[];
  resource?: string[];
  condition?: Record<string, object>;
}

export interface SearchResult {
  /** Object Key including the bucket where the result is located */
  key?: string;
  text?: string;
  data_source?: string;
  /** Temporary URL to access the document */
  pre_signed_url?: string;
  /** Temporary URL to access linked image (if applicable) */
  linked_image_url?: string;
  /** Temporary URL to access linked actual, related data (if applicable) */
  linked_data_url?: string;
  /** Source webpage URL for content that was scraped from the web (only applicable for Webpage category) */
  web_source_url?: string;
  /** @format float */
  score?: number;
  category?: string;
  bucket?: string;
  source_chunk?: string;
  source_location?: string;
}

export interface SearchDomains {
  results?: SearchResult[];
}

export interface SearchResults {
  domains?: Record<string, SearchDomains>;
}

export type MinioLambda = object;

export interface ChatMsg {
  room_id?: string;
  id?: string;
  from?: "user" | "system";
  text?: string;
  /** @format int64 */
  sys_created_date?: number;
  domain?: string;
  metadata?: {
    prompt?: string;
    sub_type?: "error" | "thinking" | "supplementing" | "done";
    search_results?: SearchResult[];
    is_follow_up_question?: string;
    rephrase_follow_up_question?: string;
    extracted_info?: string;
    suggested_questions?: string[];
  };
}

export interface Room {
  id?: string;
  title?: string;
  /** @format int64 */
  sys_created_date?: number;
  total_messages?: number;
}

export interface RoomList {
  results?: Room[];
}

export interface SynthesizeVoiceInput {
  text?: string;
}

export interface QueueCounts {
  queues?: Record<string, Record<string, number>>;
}

export interface Chunk {
  /** @format int64 */
  number?: number;
  text?: string;
}

export interface ChunkList {
  results?: Chunk[];
}

export interface ZoomResponse {
  plainToken?: string;
  encryptedToken?: string;
}

export interface Domain {
  id?: string;
  name?: string;
  description?: string;
  collection_id?: string;
  collection?: Collection;
  /** @format float */
  match_threshold?: number;
  prompt_template?: string;
  is_default?: boolean;
  is_active?: boolean;
  mode?: "text" | "audio" | "video";
  llm_endpoint?: string;
  /** @format float */
  max_new_tokens?: number;
  /** @format float */
  temperature?: number;
  /** @format float */
  top_p?: number;
  /** @format float */
  repetition_penalty?: number;
}

export interface Index {
  id?: string;
  collection_id?: string;
  name?: string;
  status?: string;
  is_default?: boolean;
  statistics?: {
    /** @format int64 */
    row_count?: number;
  };
}

export interface IndexList {
  results?: Index[];
}

export interface Collection {
  name?: string;
  /** @format int32 */
  sequence_length?: number;
  /** @format int32 */
  num_dimensions?: number;
  endpoint?: string;
  query_prefix?: string;
  document_prefix?: string;
  vector_index_func?: string;
  vector_index_type?: string;
  vector_index_params?: Record<string, Record<string, any>>;
  collection_type?: string;
  active_index?: Index;
}

export interface CollectionList {
  results?: Collection[];
}

export interface DomainList {
  results?: Domain[];
}

export interface Question {
  text?: string;
  chunk?: string;
}

export interface QuestionList {
  results?: Question[];
}

export interface EurekaEvent {
  id?: string;
  title?: string;
  event_type?: string;
  room_id?: string;
  domain_id?: string;
  data?: Record<string, any>;
}

export interface TestPrompt {
  id?: string;
  /** @format date-time */
  sys_created_date?: string;
  input_text?: string;
}

export interface TestPromptList {
  results?: TestPrompt[];
}

export interface Report {
  id?: string;
  name?: string;
  /** @format date-time */
  sys_created_date?: string;
  domain_id?: string;
  domain_snapshot?: Record<string, any>;
  collection_snapshot?: Record<string, any>;
  counts?: {
    /** @format int64 */
    tests?: number;
    /** @format int64 */
    completed?: number;
    /** @format int64 */
    goods?: number;
    /** @format int64 */
    bads?: number;
  };
}

export interface ReportList {
  results?: Report[];
}

export interface ReportPrompts {
  id?: string;
  /** @format date-time */
  sys_created_date?: string;
  report?: string;
  task?: string;
  prompt_used?: string;
}

export interface ReportInputTest {
  id?: string;
  /** @format date-time */
  sys_created_date?: string;
  report?: string;
  input?: string;
  room?: string;
  graded?: string;
  output?: string;
  output_metadata?: Record<string, any>;
}

export interface ReportInputTestList {
  results?: ReportInputTest[];
}

export interface GradeRequest {
  grade?: "good" | "bad";
}

export interface CrawlRequest {
  url?: string;
  crawl?: boolean;
  num_pages_cap?: number;
}

export interface CrawlMessage {
  id?: string;
  /** @format date-time */
  initiated_date?: string;
}

export interface Suggestion {
  question?: string;
  id?: string;
}

export interface SuggestionList {
  suggestions?: Suggestion[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`,
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title MinIO SubnetOps
 * @version 0.1.0
 * @baseUrl /api/v1
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  login = {
    /**
     * No description
     *
     * @tags Auth
     * @name LoginDetail
     * @summary Returns login strategy, form or sso.
     * @request GET:/login
     */
    loginDetail: (params: RequestParams = {}) =>
      this.request<LoginDetails, ApiError>({
        path: `/login`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @summary Login to Console
     * @request POST:/login
     */
    login: (body: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginSuccess, ApiError>({
        path: `/login`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name LoginOauth2Auth
     * @summary Identity Provider oauth2 callback endpoint.
     * @request POST:/login/oauth2/auth
     */
    loginOauth2Auth: (
      body: LoginOauth2AuthRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ApiError>({
        path: `/login/oauth2/auth`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  logout = {
    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @summary Logout from Console.
     * @request POST:/logout
     * @secure
     */
    logout: (body: LogoutRequest, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/logout`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  session = {
    /**
     * No description
     *
     * @tags Auth
     * @name SessionCheck
     * @summary Endpoint to check if your session is still valid
     * @request GET:/session
     * @secure
     */
    sessionCheck: (params: RequestParams = {}) =>
      this.request<SessionResponse, ApiError>({
        path: `/session`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags Search
     * @name Search
     * @summary Performa a search
     * @request GET:/search
     * @secure
     */
    search: (
      query: {
        /** Search query to retrieve */
        query: string;
        /** optional key to restrict search around */
        filterKey?: string;
        /** domain to restruct the search */
        domain?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResults, ApiError>({
        path: `/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  index = {
    /**
     * No description
     *
     * @tags Indexing
     * @name Indexing
     * @summary Endpoint to index documents fed via webhook
     * @request POST:/index
     * @secure
     */
    indexing: (body: MinioLambda, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/index`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),
  };
  process = {
    /**
     * No description
     *
     * @tags Processing
     * @name Processing
     * @summary Endpoint to process documents fed via webhook
     * @request POST:/process
     * @secure
     */
    processing: (body: MinioLambda, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/process`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),
  };
  domains = {
    /**
     * No description
     *
     * @tags Domains
     * @name ListDomains
     * @summary List of domains
     * @request GET:/domains
     * @secure
     */
    listDomains: (
      query?: {
        active?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DomainList, ApiError>({
        path: `/domains`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name AddDomain
     * @summary Add a domain
     * @request POST:/domains
     * @secure
     */
    addDomain: (domain: Domain, params: RequestParams = {}) =>
      this.request<Domain, ApiError>({
        path: `/domains`,
        method: "POST",
        body: domain,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name GetDomain
     * @summary Get domain details
     * @request GET:/domains/{id}
     * @secure
     */
    getDomain: (id: string, params: RequestParams = {}) =>
      this.request<Domain, ApiError>({
        path: `/domains/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name UpdateDomain
     * @summary Update domain details
     * @request PUT:/domains/{id}
     * @secure
     */
    updateDomain: (id: string, domain: Domain, params: RequestParams = {}) =>
      this.request<Domain, ApiError>({
        path: `/domains/${id}`,
        method: "PUT",
        body: domain,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name DeleteDomain
     * @summary Delete domain
     * @request DELETE:/domains/{id}
     * @secure
     */
    deleteDomain: (id: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/domains/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Domains
     * @name DuplicateDomain
     * @summary Duplicate a Domain
     * @request POST:/domains/{id}/duplicate
     * @secure
     */
    duplicateDomain: (id: string, params: RequestParams = {}) =>
      this.request<Domain, ApiError>({
        path: `/domains/${id}/duplicate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  collections = {
    /**
     * No description
     *
     * @tags Collections
     * @name ListCollections
     * @summary List of collections
     * @request GET:/collections
     * @secure
     */
    listCollections: (
      query?: {
        /** @default "docs" */
        type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CollectionList, ApiError>({
        path: `/collections`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name AddCollection
     * @summary Add a collection
     * @request POST:/collections
     * @secure
     */
    addCollection: (collection: Collection, params: RequestParams = {}) =>
      this.request<Collection, ApiError>({
        path: `/collections`,
        method: "POST",
        body: collection,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollection
     * @summary Get collection details
     * @request GET:/collections/{id}
     * @secure
     */
    getCollection: (id: string, params: RequestParams = {}) =>
      this.request<Collection, ApiError>({
        path: `/collections/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name UpdateCollection
     * @summary Update collection details
     * @request PUT:/collections/{id}
     * @secure
     */
    updateCollection: (
      id: string,
      collection: Collection,
      params: RequestParams = {},
    ) =>
      this.request<Collection, ApiError>({
        path: `/collections/${id}`,
        method: "PUT",
        body: collection,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name DeleteCollection
     * @summary Delete collection
     * @request DELETE:/collections/{id}
     * @secure
     */
    deleteCollection: (id: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/collections/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name ListIndexes
     * @summary List of indexes
     * @request GET:/collections/{collection_id}/indexes
     * @secure
     */
    listIndexes: (collectionId: string, params: RequestParams = {}) =>
      this.request<IndexList, ApiError>({
        path: `/collections/${collectionId}/indexes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name AddIndex
     * @summary Add a index
     * @request POST:/collections/{collection_id}/indexes
     * @secure
     */
    addIndex: (collectionId: string, params: RequestParams = {}) =>
      this.request<Index, ApiError>({
        path: `/collections/${collectionId}/indexes`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name GetIndex
     * @summary Get index details
     * @request GET:/collections/{collection_id}/indexes/{id}
     * @secure
     */
    getIndex: (id: string, collectionId: string, params: RequestParams = {}) =>
      this.request<Index, ApiError>({
        path: `/collections/${collectionId}/indexes/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name UpdateIndex
     * @summary Update index details
     * @request PUT:/collections/{collection_id}/indexes/{id}
     * @secure
     */
    updateIndex: (
      id: string,
      collectionId: string,
      index: Index,
      params: RequestParams = {},
    ) =>
      this.request<Index, ApiError>({
        path: `/collections/${collectionId}/indexes/${id}`,
        method: "PUT",
        body: index,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name DeleteIndex
     * @summary Delete index
     * @request DELETE:/collections/{collection_id}/indexes/{id}
     * @secure
     */
    deleteIndex: (
      id: string,
      collectionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ApiError>({
        path: `/collections/${collectionId}/indexes/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name Reindex
     * @summary Rebuild the Index
     * @request POST:/collections/{collection_id}/indexes/{id}/reindex
     * @secure
     */
    reindex: (id: string, collectionId: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/collections/${collectionId}/indexes/${id}/reindex`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Indexes
     * @name SetDefaultIndex
     * @summary Set Index as Default
     * @request POST:/collections/{collection_id}/indexes/{id}/set-default
     * @secure
     */
    setDefaultIndex: (
      id: string,
      collectionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ApiError>({
        path: `/collections/${collectionId}/indexes/${id}/set-default`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  room = {
    /**
     * No description
     *
     * @tags Chat
     * @name ListRooms
     * @summary List of rooms
     * @request GET:/room
     * @secure
     */
    listRooms: (params: RequestParams = {}) =>
      this.request<RoomList, ApiError>({
        path: `/room`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat
     * @name CreateRoom
     * @summary Create a new Room
     * @request POST:/room
     * @secure
     */
    createRoom: (params: RequestParams = {}) =>
      this.request<Room, ApiError>({
        path: `/room`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  synthesize = {
    /**
     * No description
     *
     * @tags Voice
     * @name SynthesizeVoice
     * @summary Text To Speech Generator
     * @request POST:/synthesize
     * @secure
     */
    synthesizeVoice: (body: SynthesizeVoiceInput, params: RequestParams = {}) =>
      this.request<File, ApiError>({
        path: `/synthesize`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  queues = {
    /**
     * No description
     *
     * @tags Management
     * @name QueueTotal
     * @summary Returns a count for all queues
     * @request GET:/queues
     * @secure
     */
    queueTotal: (params: RequestParams = {}) =>
      this.request<QueueCounts, ApiError>({
        path: `/queues`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  docs = {
    /**
     * No description
     *
     * @tags Docs
     * @name GetTranscript
     * @summary Returns a transcript, optionally starting at chunk_number
     * @request GET:/docs/transcript
     * @secure
     */
    getTranscript: (
      query: {
        objectKey: string;
        /** @format int64 */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChunkList, ApiError>({
        path: `/docs/transcript`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Docs
     * @name ListQuestions
     * @summary Returns a list of questions for the passed document
     * @request GET:/docs/questions
     * @secure
     */
    listQuestions: (
      query: {
        objectKey: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuestionList, ApiError>({
        path: `/docs/questions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  prompt = {
    /**
     * No description
     *
     * @tags Prompts
     * @name SuggestPrompts
     * @summary Returns a list of suggestions for the on-going prompts
     * @request GET:/prompt/suggestions
     * @secure
     */
    suggestPrompts: (
      query: {
        q: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SuggestionList, ApiError>({
        path: `/prompt/suggestions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompts
     * @name SemanticSuggestionPrompts
     * @summary Returns a list of suggestions based on semantic Search
     * @request GET:/prompt/suggestions/semantic
     * @secure
     */
    semanticSuggestionPrompts: (
      query: {
        q: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SuggestionList, ApiError>({
        path: `/prompt/suggestions/semantic`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name Zoom
     * @summary Webhook for zoom recordings
     * @request POST:/webhooks/zoom
     */
    zoom: (body: MinioLambda, params: RequestParams = {}) =>
      this.request<ZoomResponse, ApiError>({
        path: `/webhooks/zoom`,
        method: "POST",
        body: body,
        format: "json",
        ...params,
      }),
  };
  reports = {
    /**
     * @description Create a new Report
     *
     * @tags Validation
     * @name AddReport
     * @request POST:/reports/
     * @secure
     */
    addReport: (body: Report, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/reports/`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),

    /**
     * @description List all Reports
     *
     * @tags Validation
     * @name ListReports
     * @request GET:/reports/
     * @secure
     */
    listReports: (params: RequestParams = {}) =>
      this.request<ReportList, ApiError>({
        path: `/reports/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a specific Report
     *
     * @tags Validation
     * @name GetReport
     * @request GET:/reports/{id}/
     * @secure
     */
    getReport: (id: string, params: RequestParams = {}) =>
      this.request<Report, ApiError>({
        path: `/reports/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a specific Report
     *
     * @tags Validation
     * @name UpdateReport
     * @request PUT:/reports/{id}/
     * @secure
     */
    updateReport: (id: string, body: Report, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/reports/${id}/`,
        method: "PUT",
        body: body,
        secure: true,
        ...params,
      }),

    /**
     * @description Delete a specific Report
     *
     * @tags Validation
     * @name DeleteReport
     * @request DELETE:/reports/{id}/
     * @secure
     */
    deleteReport: (id: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/reports/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Lists test for a report
     *
     * @tags Validation
     * @name ListReportTests
     * @request GET:/reports/{id}/tests
     * @secure
     */
    listReportTests: (id: string, params: RequestParams = {}) =>
      this.request<ReportInputTestList, ApiError>({
        path: `/reports/${id}/tests`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Grade the quality of a result
     *
     * @tags Validation
     * @name GradeReportTests
     * @request POST:/reports/{id}/tests/{testId}/grade
     * @secure
     */
    gradeReportTests: (
      id: string,
      testId: string,
      body: GradeRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ApiError>({
        path: `/reports/${id}/tests/${testId}/grade`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  testPrompts = {
    /**
     * @description Create a new TestPrompt
     *
     * @tags Validation
     * @name AddTestPrompt
     * @request POST:/test-prompts/
     * @secure
     */
    addTestPrompt: (body: TestPrompt, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/test-prompts/`,
        method: "POST",
        body: body,
        secure: true,
        ...params,
      }),

    /**
     * @description List all TestPrompts
     *
     * @tags Validation
     * @name ListTestPrompts
     * @request GET:/test-prompts/
     * @secure
     */
    listTestPrompts: (params: RequestParams = {}) =>
      this.request<TestPromptList, ApiError>({
        path: `/test-prompts/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a specific TestPrompt
     *
     * @tags Validation
     * @name GetTestPrompt
     * @request GET:/test-prompts/{id}/
     * @secure
     */
    getTestPrompt: (id: string, params: RequestParams = {}) =>
      this.request<TestPrompt, ApiError>({
        path: `/test-prompts/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a specific TestPrompt
     *
     * @tags Validation
     * @name UpdateTestPrompt
     * @request PUT:/test-prompts/{id}/
     * @secure
     */
    updateTestPrompt: (
      id: string,
      body: TestPrompt,
      params: RequestParams = {},
    ) =>
      this.request<void, ApiError>({
        path: `/test-prompts/${id}/`,
        method: "PUT",
        body: body,
        secure: true,
        ...params,
      }),

    /**
     * @description Delete a specific TestPrompt
     *
     * @tags Validation
     * @name DeleteTestPrompt
     * @request DELETE:/test-prompts/{id}/
     * @secure
     */
    deleteTestPrompt: (id: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/test-prompts/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Duplicate a specific TestPrompt
     *
     * @tags Validation
     * @name DuplicateTestPrompt
     * @request POST:/test-prompts/{id}/duplicate
     * @secure
     */
    duplicateTestPrompt: (id: string, params: RequestParams = {}) =>
      this.request<TestPrompt, ApiError>({
        path: `/test-prompts/${id}/duplicate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  crawl = {
    /**
     * No description
     *
     * @tags Crawling
     * @name Crawl
     * @summary Perform a web crawl
     * @request POST:/crawl
     * @secure
     */
    crawl: (body: CrawlRequest, params: RequestParams = {}) =>
      this.request<CrawlMessage, ApiError>({
        path: `/crawl`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
