import React from "react";
import ETextIcon from "./ETextIcon";

const SuggestionBox = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={"suggestion-box"}
      onClick={() => onClick && onClick()}
      style={{
        backgroundColor: "#EDF1F6",
        borderRadius: 6,
        paddingTop: 12,
        paddingBottom: 10,
        paddingLeft: 22,
        paddingRight: 22,
        fontSize: 10,
        fontWeight: "bold",
        color: "#12222C",
        display: "flex",
        gap: 8,
        cursor: "pointer",
      }}
    >
      <div style={{ flexGrow: 0 }}>
        <ETextIcon />
      </div>
      <div
        style={{ flexGrow: 1, whiteSpace: "pre-wrap", wordBreak: "break-word" }}
      >
        {text}
      </div>
    </div>
  );
};
export default SuggestionBox;
