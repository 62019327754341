import React from "react";

const FormField = ({
  children,
  title,
}: {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div style={{ marginBottom: 40, color: "#12222C" }}>
      <b style={{ font: "normal normal bold 16px/20px Inter" }}>{title}</b>
      <hr style={{ borderBottom: "1px solid #EDF1F6" }} />
      <div style={{ fontSize: 14 }}>{children}</div>
    </div>
  );
};
export default FormField;
