import React, { useEffect, useState } from "react";
import { Collection } from "../../../api/eurekaApi";
import { api } from "../../../api";
import { AddIcon, Box, ProgressBar, RefreshIcon } from "mds";
import { useNavigate } from "react-router-dom";
import ESectionTitle from "components/ESectionTitle";
import EButton from "components/EButton";
import ESelect from "components/ESelect";
import EDataTable from "components/EDataTable";

const ListCollections = () => {
  const navigate = useNavigate();
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Collection[]>([]);
  const [listType, setListType] = useState<"docs" | "questions">("docs");

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      setLoading(true);
      api.collections
        .listCollections({ type: listType })
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.results) {
            setResults(res.data.results);
          } else {
            setResults([]);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.warn("Error loading collections", e);
        });
    }
  }, [iniLoad, listType]);

  return (
    <div>
      <ESectionTitle
        actions={
          <Box style={{ display: "flex", gap: 8 }}>
            <ESelect
              id={"col_type"}
              value={listType}
              onChange={(e) => {
                if (e === "docs" || e === "questions") {
                  setListType(e);
                  setIniLoad(false);
                }
              }}
              options={[
                {
                  label: "Documents",
                  value: "docs",
                },
                {
                  label: "Questions",
                  value: "questions",
                },
              ]}
            />
            <EButton
              id={"add-collection"}
              onClick={() => {
                navigate("/management/collections/add");
              }}
              icon={<AddIcon />}
            >
              Add
            </EButton>
            <EButton
              id={"add-collection"}
              onClick={() => {
                setIniLoad(false);
              }}
              icon={<RefreshIcon />}
            >
              Refresh
            </EButton>
          </Box>
        }
      >
        Collections
      </ESectionTitle>
      <EDataTable
        isLoading={loading}
        loadingMessage={<ProgressBar value={30} variant="indeterminate" />}
        itemActions={[
          {
            type: "view",
            onClick: (item: Collection) => {
              navigate(`/management/collections/${item.name}`);
            },
          },
        ]}
        columns={[
          {
            label: "Name",
            elementKey: "name",
          },
          {
            label: "Sequence Length",
            elementKey: "sequence_length",
          },
          {
            label: "Num Dimensions",
            elementKey: "num_dimensions",
          },
          {
            label: "Endpoint",
            elementKey: "endpoint",
          },
        ]}
        entityName="Collections"
        records={results}
      />
    </div>
  );
};
export default ListCollections;
