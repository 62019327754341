import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ApiError, ReportInputTest } from "../../../api/eurekaApi";
import { api } from "../../../api";
import { AddIcon, ThumbsDownIcon, ThumbsUpIcon } from "mds";
import ErrorDisplay from "components/ErrorDisplay";
import { useNavigate } from "react-router-dom";
import EButton from "components/EButton";
import ESectionTitle from "components/ESectionTitle";
import STIconButton from "components/STIconButton";
import ETextIcon from "../../chat/ETextIcon";
import ESwitch from "components/ESwitch";

const ListReportInputTests = ({
  reportID,
  rerender,
}: {
  reportID: string;
  rerender: boolean;
}) => {
  const navigate = useNavigate();
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [results, setResults] = useState<ReportInputTest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formatted, setFormatted] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  const getRecords = useCallback(() => {
    setError(null);
    api.reports
      .listReportTests(reportID)
      .then((res) => {
        if (res.data && res.data.results) {
          setResults(res.data.results);
        }
        setLoading(false);
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        setError(err);
        setLoading(false);
      });
  }, [reportID]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      setLoading(true);
      getRecords();
    }
  }, [iniLoad, getRecords]);

  useEffect(() => {
    if (iniLoad !== rerender) {
      setIniLoad(false);
      setLoading(true);
      getRecords();
    }
  }, [iniLoad, rerender, getRecords]);

  return (
    <div>
      <ESectionTitle
        actions={
          <Fragment>
            <ESwitch
              id="Switch"
              indicatorLabels={["Formatted", "Unformatted"]}
              onClick={() => setFormatted(!formatted)}
            />
            <EButton
              id={"add-collection"}
              onClick={() => {
                api.collections
                  .addIndex(reportID)
                  .then(() => {
                    getRecords();
                  })
                  .catch(async (res) => {
                    const err = (await res.json()) as ApiError;
                    setError(err);
                    setLoading(false);
                  });
              }}
              disabled={loading}
              icon={<AddIcon />}
            >
              Add
            </EButton>
          </Fragment>
        }
      >
        Test Results
      </ESectionTitle>
      {error && <ErrorDisplay err={error} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {results.map((rit) => (
          <Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #dedede",
              }}
            >
              <div
                style={{
                  display: "flex",

                  gap: 8,
                  padding: 4,
                  backgroundColor: "#F7F9FC",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                    font: "normal normal bold 16px/20px Inter",
                    color: "#12222C",
                  }}
                >
                  {rit.input}
                </div>
                <div
                  style={{
                    flexShrink: 1,
                    flexGrow: 0,
                    display: "flex",

                    gap: 8,
                  }}
                >
                  <STIconButton
                    id={"good"}
                    onClick={() => {
                      api.reports
                        .gradeReportTests(reportID, rit.id!, {
                          grade: "good",
                        })
                        .then(() => getRecords())
                        .catch(async (res) => {
                          const err = (await res.json()) as ApiError;
                          setError(err);
                        });
                    }}
                  >
                    <ThumbsUpIcon
                      style={{
                        fill: rit.graded === "good" ? "#4ccb92" : "#c5cddb",
                      }}
                    />
                  </STIconButton>
                  <STIconButton
                    id={"bad"}
                    onClick={() => {
                      api.reports
                        .gradeReportTests(reportID, rit.id!, {
                          grade: "bad",
                        })
                        .then(() => getRecords())
                        .catch(async (res) => {
                          const err = (await res.json()) as ApiError;
                          setError(err);
                        });
                    }}
                  >
                    <ThumbsDownIcon
                      style={{
                        fill:
                          rit.graded === "bad" ? "rgb(216,46,101)" : "#c5cddb",
                      }}
                    />
                  </STIconButton>
                  <STIconButton
                    id={"go-to-room"}
                    onClick={() => {
                      navigate(`/chat/${rit?.room}`);
                    }}
                  >
                    <ETextIcon />
                  </STIconButton>
                </div>
              </div>
              <div style={{ borderTop: "1px solid #eeeeee", padding: 4 }}>
                {!formatted && <Fragment>{rit.output ?? "n/a"}</Fragment>}
                {formatted && (
                  <pre
                    style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                  >
                    {rit.output ?? "n/a"}
                  </pre>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
export default ListReportInputTests;
