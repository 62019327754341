import styled from "@emotion/styled";
import { Button } from "mds";

const EButton = styled(Button)`
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  border: 1px solid #278ead;
  color: #278ead;

  svg.min-icon {
    fill: #278ead;
    color: #278ead;
  }
`;
export default EButton;
