import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";
import { ApiError } from "api/eurekaApi";
import { AppState } from "app/store";
import { setErrorSnackMessage } from "screens/systemSlice";
import { b64EncodeUnicode } from "utils/wsUtils";

export const listQuestionsAsync = createAsyncThunk(
  "questions/listQuestionsAsync",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as AppState;
    const filterKey =
      state.questions.filterKey !== "" ? state.questions.filterKey : undefined;

    const encKey = b64EncodeUnicode(`${filterKey}`);

    return api.docs
      .listQuestions({ objectKey: encKey })
      .then((res) => {
        if (res.data !== undefined) {
          return res.data;
        }
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        dispatch(setErrorSnackMessage(err));
        return rejectWithValue(false);
      });
  },
);
