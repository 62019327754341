import { Box } from "mds";
import React, { useState } from "react";
import { MinIODocument } from "./ChatMessage.types";
import { useAppDispatch } from "../../app/hooks";
import { setFilterQuery } from "../search/searchSlice";
import { searchAsync } from "../search/searchThunks";
import { openSearchDrawerFor } from "./chatSlice";
import { openTranscriptDrawer } from "../document/transcript/transcriptSlice";
import { getChunksAsync } from "../document/transcript/transcriptThunks";
import { openQuestionsDrawer } from "../document/questions/questionsSlice";
import { listQuestionsAsync } from "../document/questions/questionsThunks";
import { ChatMsg } from "../../protos/gateway";

const DocumentOptions = ({
  msg,
  doc,
  onPreview,
}: {
  msg: ChatMsg;
  doc: MinIODocument;
  onPreview: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        width: 18,
        height: 18,
        border: "1px solid #278EAD",
        color: "#278EAD",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        overflow: "hidden",
        transition: "width 0.3s ease",
        fontSize: 10,
        "&.expanded": {
          width: 300,
          paddingLeft: 8,
          paddingRight: 8,
        },
      }}
      className={`container ${expanded ? "expanded" : ""}`}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          sx={{
            "& div:hover": { fontWeight: "bold" },
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              onPreview();
            }}
          >
            Preview
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              window.open(doc.search_results[0].preSignedUrl, "_blank");
            }}
          >
            Download
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (msg.metadata && msg.metadata.prompt) {
                dispatch(
                  setFilterQuery({
                    query: msg.metadata?.prompt,
                    filterKey: doc.key,
                    domain: msg.domain!,
                  }),
                );
                dispatch(searchAsync());
                dispatch(openSearchDrawerFor(msg));
              }
            }}
          >
            Context
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(openTranscriptDrawer({ open: true, document: doc.key }));
              dispatch(getChunksAsync());
            }}
          >
            Conversation
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(openQuestionsDrawer({ open: true, document: doc.key }));
              dispatch(listQuestionsAsync());
            }}
          >
            Questions
          </div>
        </Box>
      ) : (
        "..."
      )}
    </Box>
  );
};

export default DocumentOptions;
