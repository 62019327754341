// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: gateway.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { EurekaEvent } from "./events";
import { Empty, SingledId } from "./min-common";
import { SearchResult } from "./search";
import { Signal, signalFromJSON, SignalMessage, signalToJSON } from "./signal";

export const protobufPackage = "min";

export interface MessageList {
  messages: ChatMsg[];
  roomId: string;
}

export interface EventList {
  events: EurekaEvent[];
  roomId: string;
}

export interface RoomSignal {
  signal: Signal;
  roomId: string;
}

export interface ChatMsg {
  domain?: string | undefined;
  from: ChatMsg_ChatMsgFrom;
  id: string;
  metadata?: ChatMsg_MessageMetadata | undefined;
  roomId: string;
  sysCreatedDate?: number | undefined;
  text: string;
}

export enum ChatMsg_ChatMsgFrom {
  USER = 0,
  SYSTEM = 1,
  UNRECOGNIZED = -1,
}

export function chatMsg_ChatMsgFromFromJSON(object: any): ChatMsg_ChatMsgFrom {
  switch (object) {
    case 0:
    case "USER":
      return ChatMsg_ChatMsgFrom.USER;
    case 1:
    case "SYSTEM":
      return ChatMsg_ChatMsgFrom.SYSTEM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChatMsg_ChatMsgFrom.UNRECOGNIZED;
  }
}

export function chatMsg_ChatMsgFromToJSON(object: ChatMsg_ChatMsgFrom): string {
  switch (object) {
    case ChatMsg_ChatMsgFrom.USER:
      return "USER";
    case ChatMsg_ChatMsgFrom.SYSTEM:
      return "SYSTEM";
    case ChatMsg_ChatMsgFrom.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ChatMsg_MessageMetadata {
  extractedInfo: string;
  isFollowUpQuestion: string;
  prompt: string;
  rephraseFollowUpQuestion: string;
  searchResults: SearchResult[];
  subType: ChatMsg_MessageMetadata_SubType;
  suggestedQuestions: string[];
}

export enum ChatMsg_MessageMetadata_SubType {
  UNKNOWN = 0,
  ERROR = 1,
  THINKING = 2,
  SUPPLEMENTING = 3,
  DONE = 4,
  UNRECOGNIZED = -1,
}

export function chatMsg_MessageMetadata_SubTypeFromJSON(object: any): ChatMsg_MessageMetadata_SubType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return ChatMsg_MessageMetadata_SubType.UNKNOWN;
    case 1:
    case "ERROR":
      return ChatMsg_MessageMetadata_SubType.ERROR;
    case 2:
    case "THINKING":
      return ChatMsg_MessageMetadata_SubType.THINKING;
    case 3:
    case "SUPPLEMENTING":
      return ChatMsg_MessageMetadata_SubType.SUPPLEMENTING;
    case 4:
    case "DONE":
      return ChatMsg_MessageMetadata_SubType.DONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChatMsg_MessageMetadata_SubType.UNRECOGNIZED;
  }
}

export function chatMsg_MessageMetadata_SubTypeToJSON(object: ChatMsg_MessageMetadata_SubType): string {
  switch (object) {
    case ChatMsg_MessageMetadata_SubType.UNKNOWN:
      return "UNKNOWN";
    case ChatMsg_MessageMetadata_SubType.ERROR:
      return "ERROR";
    case ChatMsg_MessageMetadata_SubType.THINKING:
      return "THINKING";
    case ChatMsg_MessageMetadata_SubType.SUPPLEMENTING:
      return "SUPPLEMENTING";
    case ChatMsg_MessageMetadata_SubType.DONE:
      return "DONE";
    case ChatMsg_MessageMetadata_SubType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Room {
  id: string;
  sysCreatedDate: number;
  title: string;
  totalMessages: number;
}

export interface WebRTCMsg {
  type: WebRTCMsg_WebRTCMsgType;
  payload: string;
  roomId: string;
}

export enum WebRTCMsg_WebRTCMsgType {
  SIGNAL = 0,
  OFFER = 1,
  ANSWER = 2,
  CANDIDATE = 3,
  CONFIG = 4,
  UNRECOGNIZED = -1,
}

export function webRTCMsg_WebRTCMsgTypeFromJSON(object: any): WebRTCMsg_WebRTCMsgType {
  switch (object) {
    case 0:
    case "SIGNAL":
      return WebRTCMsg_WebRTCMsgType.SIGNAL;
    case 1:
    case "OFFER":
      return WebRTCMsg_WebRTCMsgType.OFFER;
    case 2:
    case "ANSWER":
      return WebRTCMsg_WebRTCMsgType.ANSWER;
    case 3:
    case "CANDIDATE":
      return WebRTCMsg_WebRTCMsgType.CANDIDATE;
    case 4:
    case "CONFIG":
      return WebRTCMsg_WebRTCMsgType.CONFIG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WebRTCMsg_WebRTCMsgType.UNRECOGNIZED;
  }
}

export function webRTCMsg_WebRTCMsgTypeToJSON(object: WebRTCMsg_WebRTCMsgType): string {
  switch (object) {
    case WebRTCMsg_WebRTCMsgType.SIGNAL:
      return "SIGNAL";
    case WebRTCMsg_WebRTCMsgType.OFFER:
      return "OFFER";
    case WebRTCMsg_WebRTCMsgType.ANSWER:
      return "ANSWER";
    case WebRTCMsg_WebRTCMsgType.CANDIDATE:
      return "CANDIDATE";
    case WebRTCMsg_WebRTCMsgType.CONFIG:
      return "CONFIG";
    case WebRTCMsg_WebRTCMsgType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WsMessage {
  chatMsg?: ChatMsg | undefined;
  room?: Room | undefined;
  eurekaEvent?: EurekaEvent | undefined;
  webrtc?: WebRTCMsg | undefined;
  signal?: SignalMessage | undefined;
  voiceTranscription?: VoiceTranscription | undefined;
  subscribeToRoom?: SubscribeToRoom | undefined;
}

export interface VoiceTranscription {
  roomId: string;
  text: string;
}

export interface SubscribeToRoom {
  roomId: string;
}

function createBaseMessageList(): MessageList {
  return { messages: [], roomId: "" };
}

export const MessageList = {
  encode(message: MessageList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.messages) {
      ChatMsg.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messages.push(ChatMsg.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessageList {
    return {
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => ChatMsg.fromJSON(e)) : [],
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
    };
  },

  toJSON(message: MessageList): unknown {
    const obj: any = {};
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => ChatMsg.toJSON(e));
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageList>, I>>(base?: I): MessageList {
    return MessageList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageList>, I>>(object: I): MessageList {
    const message = createBaseMessageList();
    message.messages = object.messages?.map((e) => ChatMsg.fromPartial(e)) || [];
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseEventList(): EventList {
  return { events: [], roomId: "" };
}

export const EventList = {
  encode(message: EventList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.events) {
      EurekaEvent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events.push(EurekaEvent.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventList {
    return {
      events: globalThis.Array.isArray(object?.events) ? object.events.map((e: any) => EurekaEvent.fromJSON(e)) : [],
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
    };
  },

  toJSON(message: EventList): unknown {
    const obj: any = {};
    if (message.events?.length) {
      obj.events = message.events.map((e) => EurekaEvent.toJSON(e));
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventList>, I>>(base?: I): EventList {
    return EventList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventList>, I>>(object: I): EventList {
    const message = createBaseEventList();
    message.events = object.events?.map((e) => EurekaEvent.fromPartial(e)) || [];
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseRoomSignal(): RoomSignal {
  return { signal: 0, roomId: "" };
}

export const RoomSignal = {
  encode(message: RoomSignal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signal !== 0) {
      writer.uint32(8).int32(message.signal);
    }
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoomSignal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoomSignal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.signal = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoomSignal {
    return {
      signal: isSet(object.signal) ? signalFromJSON(object.signal) : 0,
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
    };
  },

  toJSON(message: RoomSignal): unknown {
    const obj: any = {};
    if (message.signal !== 0) {
      obj.signal = signalToJSON(message.signal);
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoomSignal>, I>>(base?: I): RoomSignal {
    return RoomSignal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoomSignal>, I>>(object: I): RoomSignal {
    const message = createBaseRoomSignal();
    message.signal = object.signal ?? 0;
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseChatMsg(): ChatMsg {
  return { domain: undefined, from: 0, id: "", metadata: undefined, roomId: "", sysCreatedDate: undefined, text: "" };
}

export const ChatMsg = {
  encode(message: ChatMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.domain !== undefined) {
      writer.uint32(10).string(message.domain);
    }
    if (message.from !== 0) {
      writer.uint32(16).int32(message.from);
    }
    if (message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    if (message.metadata !== undefined) {
      ChatMsg_MessageMetadata.encode(message.metadata, writer.uint32(34).fork()).ldelim();
    }
    if (message.roomId !== "") {
      writer.uint32(42).string(message.roomId);
    }
    if (message.sysCreatedDate !== undefined) {
      writer.uint32(48).int64(message.sysCreatedDate);
    }
    if (message.text !== "") {
      writer.uint32(58).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatMsg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.domain = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.from = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.metadata = ChatMsg_MessageMetadata.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sysCreatedDate = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatMsg {
    return {
      domain: isSet(object.domain) ? globalThis.String(object.domain) : undefined,
      from: isSet(object.from) ? chatMsg_ChatMsgFromFromJSON(object.from) : 0,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      metadata: isSet(object.metadata) ? ChatMsg_MessageMetadata.fromJSON(object.metadata) : undefined,
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      sysCreatedDate: isSet(object.sysCreatedDate) ? globalThis.Number(object.sysCreatedDate) : undefined,
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: ChatMsg): unknown {
    const obj: any = {};
    if (message.domain !== undefined) {
      obj.domain = message.domain;
    }
    if (message.from !== 0) {
      obj.from = chatMsg_ChatMsgFromToJSON(message.from);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.metadata !== undefined) {
      obj.metadata = ChatMsg_MessageMetadata.toJSON(message.metadata);
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.sysCreatedDate !== undefined) {
      obj.sysCreatedDate = Math.round(message.sysCreatedDate);
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatMsg>, I>>(base?: I): ChatMsg {
    return ChatMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatMsg>, I>>(object: I): ChatMsg {
    const message = createBaseChatMsg();
    message.domain = object.domain ?? undefined;
    message.from = object.from ?? 0;
    message.id = object.id ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? ChatMsg_MessageMetadata.fromPartial(object.metadata)
      : undefined;
    message.roomId = object.roomId ?? "";
    message.sysCreatedDate = object.sysCreatedDate ?? undefined;
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseChatMsg_MessageMetadata(): ChatMsg_MessageMetadata {
  return {
    extractedInfo: "",
    isFollowUpQuestion: "",
    prompt: "",
    rephraseFollowUpQuestion: "",
    searchResults: [],
    subType: 0,
    suggestedQuestions: [],
  };
}

export const ChatMsg_MessageMetadata = {
  encode(message: ChatMsg_MessageMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractedInfo !== "") {
      writer.uint32(10).string(message.extractedInfo);
    }
    if (message.isFollowUpQuestion !== "") {
      writer.uint32(18).string(message.isFollowUpQuestion);
    }
    if (message.prompt !== "") {
      writer.uint32(26).string(message.prompt);
    }
    if (message.rephraseFollowUpQuestion !== "") {
      writer.uint32(34).string(message.rephraseFollowUpQuestion);
    }
    for (const v of message.searchResults) {
      SearchResult.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.subType !== 0) {
      writer.uint32(48).int32(message.subType);
    }
    for (const v of message.suggestedQuestions) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatMsg_MessageMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatMsg_MessageMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractedInfo = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.isFollowUpQuestion = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.prompt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rephraseFollowUpQuestion = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.searchResults.push(SearchResult.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.subType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.suggestedQuestions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatMsg_MessageMetadata {
    return {
      extractedInfo: isSet(object.extractedInfo) ? globalThis.String(object.extractedInfo) : "",
      isFollowUpQuestion: isSet(object.isFollowUpQuestion) ? globalThis.String(object.isFollowUpQuestion) : "",
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
      rephraseFollowUpQuestion: isSet(object.rephraseFollowUpQuestion)
        ? globalThis.String(object.rephraseFollowUpQuestion)
        : "",
      searchResults: globalThis.Array.isArray(object?.searchResults)
        ? object.searchResults.map((e: any) => SearchResult.fromJSON(e))
        : [],
      subType: isSet(object.subType) ? chatMsg_MessageMetadata_SubTypeFromJSON(object.subType) : 0,
      suggestedQuestions: globalThis.Array.isArray(object?.suggestedQuestions)
        ? object.suggestedQuestions.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ChatMsg_MessageMetadata): unknown {
    const obj: any = {};
    if (message.extractedInfo !== "") {
      obj.extractedInfo = message.extractedInfo;
    }
    if (message.isFollowUpQuestion !== "") {
      obj.isFollowUpQuestion = message.isFollowUpQuestion;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    if (message.rephraseFollowUpQuestion !== "") {
      obj.rephraseFollowUpQuestion = message.rephraseFollowUpQuestion;
    }
    if (message.searchResults?.length) {
      obj.searchResults = message.searchResults.map((e) => SearchResult.toJSON(e));
    }
    if (message.subType !== 0) {
      obj.subType = chatMsg_MessageMetadata_SubTypeToJSON(message.subType);
    }
    if (message.suggestedQuestions?.length) {
      obj.suggestedQuestions = message.suggestedQuestions;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatMsg_MessageMetadata>, I>>(base?: I): ChatMsg_MessageMetadata {
    return ChatMsg_MessageMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatMsg_MessageMetadata>, I>>(object: I): ChatMsg_MessageMetadata {
    const message = createBaseChatMsg_MessageMetadata();
    message.extractedInfo = object.extractedInfo ?? "";
    message.isFollowUpQuestion = object.isFollowUpQuestion ?? "";
    message.prompt = object.prompt ?? "";
    message.rephraseFollowUpQuestion = object.rephraseFollowUpQuestion ?? "";
    message.searchResults = object.searchResults?.map((e) => SearchResult.fromPartial(e)) || [];
    message.subType = object.subType ?? 0;
    message.suggestedQuestions = object.suggestedQuestions?.map((e) => e) || [];
    return message;
  },
};

function createBaseRoom(): Room {
  return { id: "", sysCreatedDate: 0, title: "", totalMessages: 0 };
}

export const Room = {
  encode(message: Room, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.sysCreatedDate !== 0) {
      writer.uint32(16).int64(message.sysCreatedDate);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.totalMessages !== 0) {
      writer.uint32(32).int32(message.totalMessages);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Room {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sysCreatedDate = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalMessages = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Room {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sysCreatedDate: isSet(object.sysCreatedDate) ? globalThis.Number(object.sysCreatedDate) : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      totalMessages: isSet(object.totalMessages) ? globalThis.Number(object.totalMessages) : 0,
    };
  },

  toJSON(message: Room): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sysCreatedDate !== 0) {
      obj.sysCreatedDate = Math.round(message.sysCreatedDate);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.totalMessages !== 0) {
      obj.totalMessages = Math.round(message.totalMessages);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Room>, I>>(base?: I): Room {
    return Room.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Room>, I>>(object: I): Room {
    const message = createBaseRoom();
    message.id = object.id ?? "";
    message.sysCreatedDate = object.sysCreatedDate ?? 0;
    message.title = object.title ?? "";
    message.totalMessages = object.totalMessages ?? 0;
    return message;
  },
};

function createBaseWebRTCMsg(): WebRTCMsg {
  return { type: 0, payload: "", roomId: "" };
}

export const WebRTCMsg = {
  encode(message: WebRTCMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.payload !== "") {
      writer.uint32(18).string(message.payload);
    }
    if (message.roomId !== "") {
      writer.uint32(26).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebRTCMsg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebRTCMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.payload = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebRTCMsg {
    return {
      type: isSet(object.type) ? webRTCMsg_WebRTCMsgTypeFromJSON(object.type) : 0,
      payload: isSet(object.payload) ? globalThis.String(object.payload) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
    };
  },

  toJSON(message: WebRTCMsg): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = webRTCMsg_WebRTCMsgTypeToJSON(message.type);
    }
    if (message.payload !== "") {
      obj.payload = message.payload;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebRTCMsg>, I>>(base?: I): WebRTCMsg {
    return WebRTCMsg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebRTCMsg>, I>>(object: I): WebRTCMsg {
    const message = createBaseWebRTCMsg();
    message.type = object.type ?? 0;
    message.payload = object.payload ?? "";
    message.roomId = object.roomId ?? "";
    return message;
  },
};

function createBaseWsMessage(): WsMessage {
  return {
    chatMsg: undefined,
    room: undefined,
    eurekaEvent: undefined,
    webrtc: undefined,
    signal: undefined,
    voiceTranscription: undefined,
    subscribeToRoom: undefined,
  };
}

export const WsMessage = {
  encode(message: WsMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chatMsg !== undefined) {
      ChatMsg.encode(message.chatMsg, writer.uint32(10).fork()).ldelim();
    }
    if (message.room !== undefined) {
      Room.encode(message.room, writer.uint32(18).fork()).ldelim();
    }
    if (message.eurekaEvent !== undefined) {
      EurekaEvent.encode(message.eurekaEvent, writer.uint32(26).fork()).ldelim();
    }
    if (message.webrtc !== undefined) {
      WebRTCMsg.encode(message.webrtc, writer.uint32(34).fork()).ldelim();
    }
    if (message.signal !== undefined) {
      SignalMessage.encode(message.signal, writer.uint32(42).fork()).ldelim();
    }
    if (message.voiceTranscription !== undefined) {
      VoiceTranscription.encode(message.voiceTranscription, writer.uint32(50).fork()).ldelim();
    }
    if (message.subscribeToRoom !== undefined) {
      SubscribeToRoom.encode(message.subscribeToRoom, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WsMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWsMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chatMsg = ChatMsg.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.room = Room.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eurekaEvent = EurekaEvent.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.webrtc = WebRTCMsg.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.signal = SignalMessage.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.voiceTranscription = VoiceTranscription.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.subscribeToRoom = SubscribeToRoom.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WsMessage {
    return {
      chatMsg: isSet(object.chatMsg) ? ChatMsg.fromJSON(object.chatMsg) : undefined,
      room: isSet(object.room) ? Room.fromJSON(object.room) : undefined,
      eurekaEvent: isSet(object.eurekaEvent) ? EurekaEvent.fromJSON(object.eurekaEvent) : undefined,
      webrtc: isSet(object.webrtc) ? WebRTCMsg.fromJSON(object.webrtc) : undefined,
      signal: isSet(object.signal) ? SignalMessage.fromJSON(object.signal) : undefined,
      voiceTranscription: isSet(object.voiceTranscription)
        ? VoiceTranscription.fromJSON(object.voiceTranscription)
        : undefined,
      subscribeToRoom: isSet(object.subscribeToRoom) ? SubscribeToRoom.fromJSON(object.subscribeToRoom) : undefined,
    };
  },

  toJSON(message: WsMessage): unknown {
    const obj: any = {};
    if (message.chatMsg !== undefined) {
      obj.chatMsg = ChatMsg.toJSON(message.chatMsg);
    }
    if (message.room !== undefined) {
      obj.room = Room.toJSON(message.room);
    }
    if (message.eurekaEvent !== undefined) {
      obj.eurekaEvent = EurekaEvent.toJSON(message.eurekaEvent);
    }
    if (message.webrtc !== undefined) {
      obj.webrtc = WebRTCMsg.toJSON(message.webrtc);
    }
    if (message.signal !== undefined) {
      obj.signal = SignalMessage.toJSON(message.signal);
    }
    if (message.voiceTranscription !== undefined) {
      obj.voiceTranscription = VoiceTranscription.toJSON(message.voiceTranscription);
    }
    if (message.subscribeToRoom !== undefined) {
      obj.subscribeToRoom = SubscribeToRoom.toJSON(message.subscribeToRoom);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WsMessage>, I>>(base?: I): WsMessage {
    return WsMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WsMessage>, I>>(object: I): WsMessage {
    const message = createBaseWsMessage();
    message.chatMsg = (object.chatMsg !== undefined && object.chatMsg !== null)
      ? ChatMsg.fromPartial(object.chatMsg)
      : undefined;
    message.room = (object.room !== undefined && object.room !== null) ? Room.fromPartial(object.room) : undefined;
    message.eurekaEvent = (object.eurekaEvent !== undefined && object.eurekaEvent !== null)
      ? EurekaEvent.fromPartial(object.eurekaEvent)
      : undefined;
    message.webrtc = (object.webrtc !== undefined && object.webrtc !== null)
      ? WebRTCMsg.fromPartial(object.webrtc)
      : undefined;
    message.signal = (object.signal !== undefined && object.signal !== null)
      ? SignalMessage.fromPartial(object.signal)
      : undefined;
    message.voiceTranscription = (object.voiceTranscription !== undefined && object.voiceTranscription !== null)
      ? VoiceTranscription.fromPartial(object.voiceTranscription)
      : undefined;
    message.subscribeToRoom = (object.subscribeToRoom !== undefined && object.subscribeToRoom !== null)
      ? SubscribeToRoom.fromPartial(object.subscribeToRoom)
      : undefined;
    return message;
  },
};

function createBaseVoiceTranscription(): VoiceTranscription {
  return { roomId: "", text: "" };
}

export const VoiceTranscription = {
  encode(message: VoiceTranscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(18).string(message.roomId);
    }
    if (message.text !== "") {
      writer.uint32(26).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoiceTranscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoiceTranscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoiceTranscription {
    return {
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: VoiceTranscription): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoiceTranscription>, I>>(base?: I): VoiceTranscription {
    return VoiceTranscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoiceTranscription>, I>>(object: I): VoiceTranscription {
    const message = createBaseVoiceTranscription();
    message.roomId = object.roomId ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseSubscribeToRoom(): SubscribeToRoom {
  return { roomId: "" };
}

export const SubscribeToRoom = {
  encode(message: SubscribeToRoom, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roomId !== "") {
      writer.uint32(10).string(message.roomId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeToRoom {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToRoom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roomId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscribeToRoom {
    return { roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "" };
  },

  toJSON(message: SubscribeToRoom): unknown {
    const obj: any = {};
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscribeToRoom>, I>>(base?: I): SubscribeToRoom {
    return SubscribeToRoom.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscribeToRoom>, I>>(object: I): SubscribeToRoom {
    const message = createBaseSubscribeToRoom();
    message.roomId = object.roomId ?? "";
    return message;
  },
};

export interface Gateway {
  EmitSignal(request: RoomSignal): Promise<Empty>;
  RoomMessages(request: SingledId): Promise<MessageList>;
  RoomEvents(request: SingledId): Promise<EventList>;
}

export const GatewayServiceName = "min.Gateway";
export class GatewayClientImpl implements Gateway {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || GatewayServiceName;
    this.rpc = rpc;
    this.EmitSignal = this.EmitSignal.bind(this);
    this.RoomMessages = this.RoomMessages.bind(this);
    this.RoomEvents = this.RoomEvents.bind(this);
  }
  EmitSignal(request: RoomSignal): Promise<Empty> {
    const data = RoomSignal.encode(request).finish();
    const promise = this.rpc.request(this.service, "EmitSignal", data);
    return promise.then((data) => Empty.decode(_m0.Reader.create(data)));
  }

  RoomMessages(request: SingledId): Promise<MessageList> {
    const data = SingledId.encode(request).finish();
    const promise = this.rpc.request(this.service, "RoomMessages", data);
    return promise.then((data) => MessageList.decode(_m0.Reader.create(data)));
  }

  RoomEvents(request: SingledId): Promise<EventList> {
    const data = SingledId.encode(request).finish();
    const promise = this.rpc.request(this.service, "RoomEvents", data);
    return promise.then((data) => EventList.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
