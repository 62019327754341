import React, { Fragment, useCallback, useState } from "react";
import { Box, breakPoints, InfoIcon, Loader } from "mds";

import MarkDown from "../../components/MarkDown";
import TextTyper from "../search/TextTyper";
import { MinIODocument } from "./ChatMessage.types";
import SourceMaterial from "./SourceMaterial";
import avatar from "./avatar-256.jpg";
import user from "./user.jpg";
import { useAppDispatch } from "../../app/hooks";
import SuggestionBox from "./SugesstionBox";
import { scrollToBottom } from "./chatSlice";
import {
  ChatMsg,
  ChatMsg_ChatMsgFrom,
  ChatMsg_MessageMetadata_SubType,
  WsMessage,
} from "../../protos/gateway";

const ChatMessage = ({ msg }: { msg: ChatMsg }) => {
  const [explain, setExplain] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const systemThinking = (msg: ChatMsg): boolean => {
    if (
      msg.from &&
      msg.from === ChatMsg_ChatMsgFrom.SYSTEM &&
      msg.metadata &&
      msg.metadata.subType === ChatMsg_MessageMetadata_SubType.THINKING
    ) {
      return true;
    }
    return false;
  };

  const isSuggestedQuestions = (msg: ChatMsg): boolean => {
    if (
      msg.from &&
      msg.from === ChatMsg_ChatMsgFrom.SYSTEM &&
      msg.metadata &&
      msg.metadata.suggestedQuestions &&
      msg.metadata.suggestedQuestions.length > 0
    ) {
      return true;
    }
    return false;
  };

  const submitPrompt = useCallback(
    async (inPrompt: string, msg: ChatMsg) => {
      console.log("submitPrompt");
      if (inPrompt === "") {
        return;
      }
      let roomId = msg.roomId;
      if (!roomId) {
        return;
      }

      let follow_msg: ChatMsg = {
        ...msg,
        roomId: roomId,
        text: inPrompt,
      };
      let wsMsg: WsMessage = {
        chatMsg: follow_msg,
      };
      dispatch({ type: "socket/postMessage", payload: wsMsg });
    },
    [dispatch],
  );

  let documents: { [objKey: string]: MinIODocument } = {};

  if (explain && msg.metadata && msg.metadata.searchResults) {
    msg.metadata.searchResults.forEach((res) => {
      if (!documents.hasOwnProperty(res.key!)) {
        documents[res.key!] = new MinIODocument(res.key!, [res]);
      } else {
        documents[res.key!].search_results.push(res);
      }
    });
    console.log(documents);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        borderRadius: 10,
        marginBottom: 16,
        padding: 8,
        backgroundColor:
          msg.metadata &&
          msg.metadata.subType === ChatMsg_MessageMetadata_SubType.ERROR
            ? "#ffc0c0"
            : msg.from === ChatMsg_ChatMsgFrom.USER
            ? "inherit"
            : "#F7F9FC",
        ":hover": {
          backgroundColor:
            msg.metadata &&
            msg.metadata.subType === ChatMsg_MessageMetadata_SubType.ERROR
              ? "#ffb1b1"
              : msg.from === ChatMsg_ChatMsgFrom.USER
              ? "#eef0f3"
              : "#F7F9FC",
        },
      }}
      className={"chat-bubble"}
    >
      <Box
        sx={{
          display: "flex",
          gap: 8,
        }}
        key={`chat-msg-${msg.id}`}
      >
        <div>
          <Box
            sx={{
              width: 48,
              height: 48,
              fontSize: 32,
              textAlign: "center",
              backgroundColor:
                msg.from === ChatMsg_ChatMsgFrom.USER ? "#012b35" : "#278EAD",
              color: "white",
              backgroundImage: !systemThinking(msg)
                ? msg.from !== ChatMsg_ChatMsgFrom.USER
                  ? `url(${avatar})`
                  : `url(${user})`
                : "inherit",
              backgroundSize: "cover",
              borderRadius: "50%",
            }}
          >
            {systemThinking(msg) && (
              <Loader style={{ height: 44, width: 44, margin: 2 }} />
            )}
          </Box>
        </div>
        <Box
          sx={{
            flexGrow: 1,
            paddingTop: 0,
            fontSize: 16,
            font:
              msg.from === ChatMsg_ChatMsgFrom.USER
                ? "normal normal bold 14px/17px Inter"
                : "inherit",
            width: "100%",
            color:
              msg.from === ChatMsg_ChatMsgFrom.USER ? "#55585D" : "#000000",
            position: "relative",
          }}
        >
          {msg.text && msg.text !== "" && (
            <MarkDown markdownSource={msg.text ?? ""} />
          )}
          {(!msg.text || msg.text === "") && systemThinking(msg) && (
            <div style={{ height: 32, marginTop: 16, color: "#9f9f9f" }}>
              <TextTyper text={"Thinking..."} />
            </div>
          )}
          {msg.from === ChatMsg_ChatMsgFrom.SYSTEM && (
            <Fragment>
              {isSuggestedQuestions(msg) && (
                <Box sx={{ marginTop: 8, marginBottom: 8 }}>
                  <b style={{ textTransform: "uppercase", fontSize: 12 }}>
                    Suggested Follow-Ups:{" "}
                  </b>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 4,
                      fontSize: 12,
                      marginBottom: 8,
                      [`@media (max-width: ${breakPoints.md}px)`]: {
                        maxWidth: "calc(100% - 65px)",
                      },
                    }}
                  >
                    {msg.metadata &&
                      msg.metadata.suggestedQuestions &&
                      msg.metadata.suggestedQuestions.map((item, si) => (
                        <SuggestionBox
                          key={`suggestion-box-${si}`}
                          text={item}
                          onClick={() => {
                            dispatch(scrollToBottom());
                            submitPrompt(item!, msg);
                          }}
                        />
                      ))}
                  </Box>
                </Box>
              )}
              {!explain &&
                msg.metadata &&
                msg.metadata.searchResults &&
                msg.metadata.subType !==
                  ChatMsg_MessageMetadata_SubType.THINKING && (
                  <Box
                    style={{
                      backgroundColor: "#EDF1F6",
                      borderRadius: 10,
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                      width: "fit-content",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 9,
                      color: "#12222C",
                    }}
                    onClick={() => setExplain(!explain)}
                    sx={{ "& .min-icon": { width: 10, fill: "#CFD1D3" } }}
                  >
                    EXPLAIN <InfoIcon />
                  </Box>
                )}
              {explain && msg.metadata && msg.metadata.searchResults && (
                <Box
                  sx={{
                    paddingTop: 0,
                    paddingLeft: 16,
                    paddingRight: 48,
                    paddingBottom: 32,
                    background: "#EDF1F6",
                    borderRadius: 10,
                    fontSize: 13,
                    marginBottom: 16,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: 9,
                      backgroundColor: "#EDF1F6",
                      borderRadius: 10,
                      paddingTop: 12,
                      paddingBottom: 12,
                      fontWeight: "bold",
                      paddingRight: 16,
                      width: "fit-content",
                      cursor: "pointer",
                      color: "#12222C",
                      "& .min-icon": { width: 10, fill: "#CFD1D3" },
                    }}
                    onClick={() => setExplain(!explain)}
                  >
                    EXPLAIN <InfoIcon />
                  </Box>
                  <p style={{ marginTop: 0 }}>
                    This answer was generated based on the following sources:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Object.keys(documents).map((docKey) => (
                      <SourceMaterial msg={msg} doc={documents[docKey]} />
                    ))}
                  </div>
                </Box>
              )}
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatMessage;
