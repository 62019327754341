import React, { Fragment, useEffect, useState } from "react";
import { Domain, TestPrompt } from "../../../api/eurekaApi";
import { api } from "../../../api";
import { AddIcon } from "mds";
import { useNavigate } from "react-router-dom";
import ESectionTitle from "components/ESectionTitle";
import EButton from "components/EButton";
import EDataTable from "components/EDataTable";

const ListTestPrompts = () => {
  const navigate = useNavigate();
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [results, setResults] = useState<TestPrompt[]>([]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      api.testPrompts
        .listTestPrompts()
        .then((res) => {
          if (res.data && res.data.results) {
            setResults(res.data.results);
          }
        })
        .catch((e) => {
          console.warn("Error loading records", e);
        });
    }
  }, [iniLoad]);

  return (
    <div>
      <ESectionTitle
        actions={
          <Fragment>
            <EButton
              id={"add-test-prompts"}
              onClick={() => {
                navigate("/management/test-prompts/add");
              }}
              icon={<AddIcon />}
            >
              Add
            </EButton>
          </Fragment>
        }
      >
        Test Prompts
      </ESectionTitle>
      <EDataTable
        itemActions={[
          {
            type: "view",
            onClick: (item: Domain) => {
              navigate(`/management/test-prompts/${item.id}`);
            },
          },
        ]}
        columns={[
          {
            label: "Text",
            elementKey: "input_text",
          },
        ]}
        entityName="Test Inputs"
        records={results}
      />
    </div>
  );
};
export default ListTestPrompts;
