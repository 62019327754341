import { useAppDispatch, useAppSelector } from "app/hooks";
import { FormLayout, InputBox, Loader, SearchIcon } from "mds";
import React, { Fragment } from "react";
import SearchResults from "./SearchResults";
import { setFilterQuery, setQuery } from "./searchSlice";
import { searchAsync } from "./searchThunks";

const Search = ({ lockFileKey = false }: { lockFileKey?: boolean }) => {
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => state.search.status);
  const query = useAppSelector((state) => state.search.query);
  const filterKey = useAppSelector((state) => state.search.filterKey);
  const initialSearch = useAppSelector((state) => state.search.initialSearch);
  const currentDomain = useAppSelector((state) => {
    if (state.search.filterDomain) {
      return state.search.filterDomain;
    }
    return state.system.selectedDomain;
  });
  const showMenu = useAppSelector((state) => state.system.showMenu);
  const domain = useAppSelector((state) => {
    if (state.system.selectedDomain) {
      for (let i = 0; i < state.system.domains.length; i++) {
        if (state.system.domains[i].id === state.system.selectedDomain) {
          return state.system.domains[i];
        }
      }
    }
  });

  const results = useAppSelector((state) => {
    if (
      currentDomain &&
      state.search.results &&
      state.search.results.domains &&
      state.search.results.domains.hasOwnProperty(currentDomain)
    ) {
      return state.search.results.domains[currentDomain].results;
    }
  });

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",

        alignItems: "center",
        placeItems: initialSearch ? "start" : "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 720,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        {status === "loading" && <Loader />}
        {status !== "loading" && (
          <Fragment>
            <FormLayout sx={{ marginTop: 32, width: "100%", maxWidth: 720 }}>
              {initialSearch && results === null && <div>No Results</div>}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(searchAsync());
                }}
              >
                <InputBox
                  id={"search-query"}
                  value={query}
                  overlayIcon={<SearchIcon />}
                  placeholder={"Enter query..."}
                  autoFocus={true}
                  required
                  onChange={(e) => {
                    if (lockFileKey) {
                      dispatch(
                        setFilterQuery({
                          query: e.target.value,
                          filterKey: filterKey,
                        }),
                      );
                    } else {
                      dispatch(setQuery(e.target.value));
                    }
                  }}
                />
              </form>
            </FormLayout>

            {initialSearch && results && results.length > 0 && (
              <Fragment>
                {filterKey !== "" && (
                  <div style={{ fontSize: 11 }}>
                    Results in the context of: <i>{filterKey}</i>
                  </div>
                )}
                {showMenu && domain && (
                  <div
                    style={{
                      borderRadius: 4,
                      backgroundColor: "#efefefef",
                      margin: 16,
                      padding: 16,
                    }}
                  >
                    <div>
                      <b>Domain:</b> {domain.name}
                    </div>
                    <div>
                      <b>Collection:</b> {domain.collection_id}
                    </div>
                    <div>
                      <b>Threshold:</b> {domain.match_threshold}
                    </div>
                  </div>
                )}
                <SearchResults />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Search;
