import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ListTestPrompts from "./ListTestPrompts";
import TestPromptDetails from "./TestPromptDetails";
import AddTestPrompt from "./AddTestPrompt";

const TestPrompts = () => {
  return (
    <Routes>
      <Route path={"/add"} element={<AddTestPrompt />} />
      <Route path={"/:inputID"} element={<TestPromptDetails />} />
      <Route path={"/"} element={<ListTestPrompts />} />
      <Route path={"*"} element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default TestPrompts;
