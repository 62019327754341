export const splitConversations = (input: string[]): string[] => {
  const speakerPattern = /(SPEAKER_\d+):/g;
  let output: string[] = [];
  let currentSpeaker: string | null = null;

  for (let line of input) {
    // Extract all the speakers in the line
    const speakersInLine = Array.from(
      line.matchAll(speakerPattern),
      (m) => m[1],
    );

    // If no speaker is found in the line, append it to the last conversation if available
    if (speakersInLine.length === 0) {
      if (output.length > 0) {
        output[output.length - 1] = line;
      } else {
        output.push(line);
      }
      continue;
    }

    // If the current speaker starts the line, append the segment to their conversation
    if (speakersInLine[0] === currentSpeaker) {
      const endx = line.indexOf(speakersInLine[1] || "");
      let segment = line.substring(0, endx).trim();
      // remove the duplicate speaker at the start of the segment
      // debugger;
      segment = segment.substring(currentSpeaker.length + 1).trim();
      output[output.length - 1] = output[output.length - 1] + " " + segment;
      line = line.substring(endx).trim();
      speakersInLine.shift(); // remove the current speaker from the array
    }

    // Split the line by the found speakers
    for (let i = 0; i < speakersInLine.length; i++) {
      currentSpeaker = speakersInLine[i];
      const startIdx = line.indexOf(currentSpeaker);
      const endIdx = speakersInLine[i + 1]
        ? currentSpeaker === speakersInLine[i + 1]
          ? line.indexOf(
              speakersInLine[i + 1],
              startIdx + currentSpeaker.length,
            )
          : line.indexOf(speakersInLine[i + 1])
        : line.length;
      const segment = line.substring(startIdx, endIdx).trim();
      if (segment !== `${currentSpeaker}:`) {
        output.push(segment);
      }

      line = line.substring(endIdx).trim();
    }
  }

  return output;
};
