import React from "react";
import { useAppSelector } from "../../app/hooks";
import EventItem from "./EventItem";
import { useParams } from "react-router-dom";
import { useRoomEventsQuery } from "../../api/gatewayApiRTK";

const RoomEventList = () => {
  const { roomID } = useParams();

  useRoomEventsQuery({ id: roomID ?? "" });

  const events = useAppSelector((state) => {
    if (!roomID) {
      return [];
    }
    if (
      !state.system.selectedDomain ||
      !state.chat.events[state.system.selectedDomain!]
    ) {
      return [];
    }

    return state.chat.events[state.system.selectedDomain!][roomID] ?? [];
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 420,
        paddingBottom: 100,
      }}
    >
      {events.length === 0 && (
        <div style={{ textAlign: "center", paddingTop: 8 }}>No Events</div>
      )}
      {events.map((e) => (
        <EventItem key={e.id} event={e} />
      ))}
    </div>
  );
};
export default RoomEventList;
