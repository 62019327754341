import { api } from "api";
import {
  ApiError,
  Collection,
  ContentType,
  Domain,
  Report,
} from "api/eurekaApi";
import { Button, DeleteIcon, Loader, RefreshIcon, SectionTitle } from "mds";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteReport from "./DeleteReport";
import ListReportInputTests from "./ListReportInputTests";
import EButton from "../../../components/EButton";
import PromptStyle from "../../../components/PromptStyle";
import FormField from "../FormField";

const ReportDetails = ({ newRecord = false }: { newRecord?: boolean }) => {
  const navigate = useNavigate();

  const { reportID } = useParams();

  const [editMode, setEditMode] = useState<boolean>(newRecord);
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [delOpen, setDelOpen] = useState<boolean>(false);
  const [record, setRecord] = useState<Report | null>(newRecord ? {} : null);
  const [origRecord, setOrigRecord] = useState<Report | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const getRecord = useCallback(() => {
    if (!reportID) {
      return;
    }
    api.reports
      .getReport(reportID)
      .then((res) => {
        if (res.data) {
          setRecord(res.data);
        }
      })
      .catch((e) => {
        console.warn("Error loading domain", e);
        setError(e.error);
      });
  }, [reportID]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      if (!newRecord) {
        getRecord();
      }
    }
  }, [iniLoad, getRecord, newRecord]);

  if (!reportID) {
    return <Fragment />;
  }

  const domain: Domain | null = (record?.domain_snapshot as Domain) ?? null;
  const collection: Collection | null =
    record?.collection_snapshot as Collection;

  return (
    <Fragment>
      {!record && <Loader />}
      {record && (
        <Fragment>
          {delOpen && (
            <DeleteReport
              onCancel={() => setDelOpen(false)}
              onDelete={() => {
                api.reports
                  .deleteReport(reportID, {
                    type: ContentType.Json,
                  })
                  .then((res) => {
                    navigate("/management/reports/");
                  })
                  .catch((e) => {
                    setError(e.error);
                  });
              }}
            />
          )}
          <SectionTitle
            actions={
              <Fragment>
                {editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    `
                    <Button
                      id={"save-edit-domain"}
                      variant={"callAction"}
                      onClick={() => {
                        const outDom: Domain = { ...record };

                        if (newRecord) {
                          api.reports
                            .addReport(outDom, { type: ContentType.Json })
                            .then((res) => {
                              navigate("/management/reports/");
                            })
                            .catch(async (res) => {
                              const err = (await res.json()) as ApiError;
                              setError(err);
                            });
                        } else {
                          api.reports
                            .updateReport(reportID, outDom, {
                              type: ContentType.Json,
                            })
                            .then((res) => {
                              setEditMode(false);
                              getRecord();
                            })
                            .catch(async (res) => {
                              const err = (await res.json()) as ApiError;
                              setError(err);
                            });
                        }
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      id={"save-edit-domain"}
                      variant={"secondary"}
                      onClick={() => {
                        if (newRecord) {
                          navigate("/management/test-inputs");
                        } else {
                          setRecord(origRecord);
                          setOrigRecord(null);
                          setEditMode(false);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <EButton
                      id={"edit-domain"}
                      onClick={() => setDelOpen(true)}
                      icon={<DeleteIcon />}
                    >
                      Delete
                    </EButton>
                    <EButton
                      id={"refresh"}
                      onClick={() => setIniLoad(false)}
                      icon={<RefreshIcon />}
                    >
                      Refresh
                    </EButton>
                  </div>
                )}
              </Fragment>
            }
            separator
          >
            Report
          </SectionTitle>
          {error && (
            <div
              style={{
                backgroundColor: "#ffa6a6",
                border: "1px solid red",
                borderRadius: 4,
                padding: 8,
                margin: 8,
              }}
            >
              <b>{error.message}</b>
              <p>{error.detailedMessage}</p>
            </div>
          )}
          <div
            style={{
              display: "flex",

              gap: 16,
              marginTop: 16,
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <FormField title={"ID"}>{record.id}</FormField>
              <FormField title={"Name"}>{record.name}</FormField>
              <FormField title={"Domain"}>{domain?.name}</FormField>
              <FormField title={"Index"}>
                {collection?.active_index?.name ?? "n/a"}
              </FormField>
            </div>
            <div style={{ width: "50%" }}>
              <FormField title={"Prompt"}>
                <PromptStyle>{domain?.prompt_template}</PromptStyle>
              </FormField>
            </div>
          </div>
          {!editMode && !newRecord && (
            <Fragment>
              {reportID && (
                <ListReportInputTests reportID={reportID} rerender={iniLoad} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default ReportDetails;
