import { Box, Select } from "mds";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SelectorType } from "mds/dist/cjs/types/global/global.types";
import { setDomain } from "screens/systemSlice";
import { useListDomainsQuery } from "../../api/eurekaApiRTK";

const DomainSelector = () => {
  const dispath = useAppDispatch();

  useListDomainsQuery({});

  const showMenu = useAppSelector((state) => state.system.showMenu);
  const domains = useAppSelector((state) => state.system.domains);
  const currentDomain = useAppSelector((state) => state.system.selectedDomain);
  const options: SelectorType[] = domains.map((v) => {
    return { label: v.name!, value: v.id! };
  });

  return (
    <Box>
      {showMenu && (
        <Select
          sx={{
            width: 320,
          }}
          id="domain-select"
          onChange={(newValue) => dispath(setDomain(newValue))}
          options={options}
          value={currentDomain ?? ""}
        />
      )}
    </Box>
  );
};

export default DomainSelector;
