import { eurekaApi as api } from "./eurekaApiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginDetail: build.query<LoginDetailApiResponse, LoginDetailApiArg>({
      query: () => ({ url: `/login` }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/login`,
        method: "POST",
        body: queryArg.loginRequest,
      }),
    }),
    loginOauth2Auth: build.mutation<
      LoginOauth2AuthApiResponse,
      LoginOauth2AuthApiArg
    >({
      query: (queryArg) => ({
        url: `/login/oauth2/auth`,
        method: "POST",
        body: queryArg.loginOauth2AuthRequest,
      }),
    }),
    logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
      query: (queryArg) => ({
        url: `/logout`,
        method: "POST",
        body: queryArg.logoutRequest,
      }),
    }),
    sessionCheck: build.query<SessionCheckApiResponse, SessionCheckApiArg>({
      query: () => ({ url: `/session` }),
    }),
    search: build.query<SearchApiResponse, SearchApiArg>({
      query: (queryArg) => ({
        url: `/search`,
        params: {
          query: queryArg.query,
          filterKey: queryArg.filterKey,
          domain: queryArg.domain,
        },
      }),
    }),
    indexing: build.mutation<IndexingApiResponse, IndexingApiArg>({
      query: (queryArg) => ({
        url: `/index`,
        method: "POST",
        body: queryArg.minioLambda,
      }),
    }),
    processing: build.mutation<ProcessingApiResponse, ProcessingApiArg>({
      query: (queryArg) => ({
        url: `/process`,
        method: "POST",
        body: queryArg.minioLambda,
      }),
    }),
    listDomains: build.query<ListDomainsApiResponse, ListDomainsApiArg>({
      query: (queryArg) => ({
        url: `/domains`,
        params: { active: queryArg.active },
      }),
    }),
    addDomain: build.mutation<AddDomainApiResponse, AddDomainApiArg>({
      query: (queryArg) => ({
        url: `/domains`,
        method: "POST",
        body: queryArg.domain,
      }),
    }),
    getDomain: build.query<GetDomainApiResponse, GetDomainApiArg>({
      query: (queryArg) => ({ url: `/domains/${queryArg.id}` }),
    }),
    updateDomain: build.mutation<UpdateDomainApiResponse, UpdateDomainApiArg>({
      query: (queryArg) => ({
        url: `/domains/${queryArg.id}`,
        method: "PUT",
        body: queryArg.domain,
      }),
    }),
    deleteDomain: build.mutation<DeleteDomainApiResponse, DeleteDomainApiArg>({
      query: (queryArg) => ({
        url: `/domains/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    duplicateDomain: build.mutation<
      DuplicateDomainApiResponse,
      DuplicateDomainApiArg
    >({
      query: (queryArg) => ({
        url: `/domains/${queryArg.id}/duplicate`,
        method: "POST",
      }),
    }),
    listCollections: build.query<
      ListCollectionsApiResponse,
      ListCollectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/collections`,
        params: { type: queryArg["type"] },
      }),
    }),
    addCollection: build.mutation<
      AddCollectionApiResponse,
      AddCollectionApiArg
    >({
      query: (queryArg) => ({
        url: `/collections`,
        method: "POST",
        body: queryArg.collection,
      }),
    }),
    getCollection: build.query<GetCollectionApiResponse, GetCollectionApiArg>({
      query: (queryArg) => ({ url: `/collections/${queryArg.id}` }),
    }),
    updateCollection: build.mutation<
      UpdateCollectionApiResponse,
      UpdateCollectionApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.id}`,
        method: "PUT",
        body: queryArg.collection,
      }),
    }),
    deleteCollection: build.mutation<
      DeleteCollectionApiResponse,
      DeleteCollectionApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listIndexes: build.query<ListIndexesApiResponse, ListIndexesApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes`,
      }),
    }),
    addIndex: build.mutation<AddIndexApiResponse, AddIndexApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes`,
        method: "POST",
      }),
    }),
    getIndex: build.query<GetIndexApiResponse, GetIndexApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes/${queryArg.id}`,
      }),
    }),
    updateIndex: build.mutation<UpdateIndexApiResponse, UpdateIndexApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes/${queryArg.id}`,
        method: "PUT",
        body: queryArg.index,
      }),
    }),
    deleteIndex: build.mutation<DeleteIndexApiResponse, DeleteIndexApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    reindex: build.mutation<ReindexApiResponse, ReindexApiArg>({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes/${queryArg.id}/reindex`,
        method: "POST",
      }),
    }),
    setDefaultIndex: build.mutation<
      SetDefaultIndexApiResponse,
      SetDefaultIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/indexes/${queryArg.id}/set-default`,
        method: "POST",
      }),
    }),
    listRooms: build.query<ListRoomsApiResponse, ListRoomsApiArg>({
      query: () => ({ url: `/room` }),
    }),
    createRoom: build.mutation<CreateRoomApiResponse, CreateRoomApiArg>({
      query: () => ({ url: `/room`, method: "POST" }),
    }),
    synthesizeVoice: build.mutation<
      SynthesizeVoiceApiResponse,
      SynthesizeVoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/synthesize`,
        method: "POST",
        body: queryArg.synthesizeVoiceInput,
      }),
    }),
    queueTotal: build.query<QueueTotalApiResponse, QueueTotalApiArg>({
      query: () => ({ url: `/queues` }),
    }),
    getTranscript: build.query<GetTranscriptApiResponse, GetTranscriptApiArg>({
      query: (queryArg) => ({
        url: `/docs/transcript`,
        params: { objectKey: queryArg.objectKey, offset: queryArg.offset },
      }),
    }),
    listQuestions: build.query<ListQuestionsApiResponse, ListQuestionsApiArg>({
      query: (queryArg) => ({
        url: `/docs/questions`,
        params: { objectKey: queryArg.objectKey },
      }),
    }),
    suggestPrompts: build.query<
      SuggestPromptsApiResponse,
      SuggestPromptsApiArg
    >({
      query: (queryArg) => ({
        url: `/prompt/suggestions`,
        params: { q: queryArg.q },
      }),
    }),
    semanticSuggestionPrompts: build.query<
      SemanticSuggestionPromptsApiResponse,
      SemanticSuggestionPromptsApiArg
    >({
      query: (queryArg) => ({
        url: `/prompt/suggestions/semantic`,
        params: { q: queryArg.q },
      }),
    }),
    zoom: build.mutation<ZoomApiResponse, ZoomApiArg>({
      query: (queryArg) => ({
        url: `/webhooks/zoom`,
        method: "POST",
        body: queryArg.minioLambda,
      }),
    }),
    addReport: build.mutation<AddReportApiResponse, AddReportApiArg>({
      query: (queryArg) => ({
        url: `/reports/`,
        method: "POST",
        body: queryArg.report,
      }),
    }),
    listReports: build.query<ListReportsApiResponse, ListReportsApiArg>({
      query: () => ({ url: `/reports/` }),
    }),
    getReport: build.query<GetReportApiResponse, GetReportApiArg>({
      query: (queryArg) => ({ url: `/reports/${queryArg.id}/` }),
    }),
    updateReport: build.mutation<UpdateReportApiResponse, UpdateReportApiArg>({
      query: (queryArg) => ({
        url: `/reports/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.report,
      }),
    }),
    deleteReport: build.mutation<DeleteReportApiResponse, DeleteReportApiArg>({
      query: (queryArg) => ({
        url: `/reports/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    listReportTests: build.query<
      ListReportTestsApiResponse,
      ListReportTestsApiArg
    >({
      query: (queryArg) => ({ url: `/reports/${queryArg.id}/tests` }),
    }),
    gradeReportTests: build.mutation<
      GradeReportTestsApiResponse,
      GradeReportTestsApiArg
    >({
      query: (queryArg) => ({
        url: `/reports/${queryArg.id}/tests/${queryArg.testId}/grade`,
        method: "POST",
        body: queryArg.gradeRequest,
      }),
    }),
    addTestPrompt: build.mutation<
      AddTestPromptApiResponse,
      AddTestPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/test-prompts/`,
        method: "POST",
        body: queryArg.testPrompt,
      }),
    }),
    listTestPrompts: build.query<
      ListTestPromptsApiResponse,
      ListTestPromptsApiArg
    >({
      query: () => ({ url: `/test-prompts/` }),
    }),
    getTestPrompt: build.query<GetTestPromptApiResponse, GetTestPromptApiArg>({
      query: (queryArg) => ({ url: `/test-prompts/${queryArg.id}/` }),
    }),
    updateTestPrompt: build.mutation<
      UpdateTestPromptApiResponse,
      UpdateTestPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/test-prompts/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.testPrompt,
      }),
    }),
    deleteTestPrompt: build.mutation<
      DeleteTestPromptApiResponse,
      DeleteTestPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/test-prompts/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    duplicateTestPrompt: build.mutation<
      DuplicateTestPromptApiResponse,
      DuplicateTestPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/test-prompts/${queryArg.id}/duplicate`,
        method: "POST",
      }),
    }),
    crawl: build.mutation<CrawlApiResponse, CrawlApiArg>({
      query: (queryArg) => ({
        url: `/crawl`,
        method: "POST",
        body: queryArg.crawlRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as eurekaApiRTK };
export type LoginDetailApiResponse =
  /** status 200 A successful response. */ LoginDetails;
export type LoginDetailApiArg = void;
export type LoginApiResponse =
  /** status 200 A successful login. */ LoginSuccess;
export type LoginApiArg = {
  loginRequest: LoginRequest;
};
export type LoginOauth2AuthApiResponse =
  /** status 204 A successful login. */ void;
export type LoginOauth2AuthApiArg = {
  loginOauth2AuthRequest: LoginOauth2AuthRequest;
};
export type LogoutApiResponse = /** status 200 A successful response. */ void;
export type LogoutApiArg = {
  logoutRequest: LogoutRequest;
};
export type SessionCheckApiResponse =
  /** status 200 A successful response. */ SessionResponse;
export type SessionCheckApiArg = void;
export type SearchApiResponse = /** status 200 Search Results */ SearchResults;
export type SearchApiArg = {
  /** Search query to retrieve */
  query: string;
  /** optional key to restrict search around */
  filterKey?: string;
  /** domain to restruct the search */
  domain?: string;
};
export type IndexingApiResponse =
  /** status 204 Indexing Chunk accepted */ void;
export type IndexingApiArg = {
  minioLambda: MinioLambda;
};
export type ProcessingApiResponse =
  /** status 204 Indexing Chunk accepted */ void;
export type ProcessingApiArg = {
  minioLambda: MinioLambda;
};
export type ListDomainsApiResponse =
  /** status 200 Domains Results */ DomainList;
export type ListDomainsApiArg = {
  active?: boolean;
};
export type AddDomainApiResponse = /** status 200 Domains Results */ Domain;
export type AddDomainApiArg = {
  domain: Domain;
};
export type GetDomainApiResponse = /** status 200 Domains Details */ Domain;
export type GetDomainApiArg = {
  id: string;
};
export type UpdateDomainApiResponse = /** status 200 Domains Details */ Domain;
export type UpdateDomainApiArg = {
  id: string;
  domain: Domain;
};
export type DeleteDomainApiResponse = /** status 200 Domains Deleted */ void;
export type DeleteDomainApiArg = {
  id: string;
};
export type DuplicateDomainApiResponse =
  /** status 200 Duplicated Domain */ Domain;
export type DuplicateDomainApiArg = {
  id: string;
};
export type ListCollectionsApiResponse =
  /** status 200 Collections Results */ CollectionList;
export type ListCollectionsApiArg = {
  type?: string;
};
export type AddCollectionApiResponse =
  /** status 200 Collections Results */ Collection;
export type AddCollectionApiArg = {
  collection: Collection;
};
export type GetCollectionApiResponse =
  /** status 200 Collections Details */ Collection;
export type GetCollectionApiArg = {
  id: string;
};
export type UpdateCollectionApiResponse =
  /** status 200 Collections Details */ Collection;
export type UpdateCollectionApiArg = {
  id: string;
  collection: Collection;
};
export type DeleteCollectionApiResponse =
  /** status 200 Collections Deleted */ void;
export type DeleteCollectionApiArg = {
  id: string;
};
export type ListIndexesApiResponse =
  /** status 200 Indexes Results */ IndexList;
export type ListIndexesApiArg = {
  collectionId: string;
};
export type AddIndexApiResponse = /** status 200 Indexes Results */ Index;
export type AddIndexApiArg = {
  collectionId: string;
};
export type GetIndexApiResponse = /** status 200 Indexes Details */ Index;
export type GetIndexApiArg = {
  id: string;
  collectionId: string;
};
export type UpdateIndexApiResponse = /** status 200 Indexes Details */ Index;
export type UpdateIndexApiArg = {
  id: string;
  collectionId: string;
  index: Index;
};
export type DeleteIndexApiResponse = /** status 200 Indexes Deleted */ void;
export type DeleteIndexApiArg = {
  id: string;
  collectionId: string;
};
export type ReindexApiResponse = /** status 200 Indexes Details */ void;
export type ReindexApiArg = {
  id: string;
  collectionId: string;
};
export type SetDefaultIndexApiResponse =
  /** status 200 Operation succeeded */ void;
export type SetDefaultIndexApiArg = {
  id: string;
  collectionId: string;
};
export type ListRoomsApiResponse = /** status 200 Search Results */ RoomList;
export type ListRoomsApiArg = void;
export type CreateRoomApiResponse = /** status 200 Created Room */ Room;
export type CreateRoomApiArg = void;
export type SynthesizeVoiceApiResponse = unknown;
export type SynthesizeVoiceApiArg = {
  synthesizeVoiceInput: SynthesizeVoiceInput;
};
export type QueueTotalApiResponse = /** status 200 Queue Counts */ QueueCounts;
export type QueueTotalApiArg = void;
export type GetTranscriptApiResponse = /** status 200 Chunk List */ ChunkList;
export type GetTranscriptApiArg = {
  objectKey: string;
  offset?: number;
};
export type ListQuestionsApiResponse =
  /** status 200 Chunk List */ QuestionList;
export type ListQuestionsApiArg = {
  objectKey: string;
};
export type SuggestPromptsApiResponse =
  /** status 200 Suggestion List */ SuggestionList;
export type SuggestPromptsApiArg = {
  q: string;
};
export type SemanticSuggestionPromptsApiResponse =
  /** status 200 Suggestion List */ SuggestionList;
export type SemanticSuggestionPromptsApiArg = {
  q: string;
};
export type ZoomApiResponse = /** status 200 Webhook Accepted */ ZoomResponse;
export type ZoomApiArg = {
  minioLambda: MinioLambda;
};
export type AddReportApiResponse = /** status 201 Report created */ void;
export type AddReportApiArg = {
  report: Report;
};
export type ListReportsApiResponse =
  /** status 200 An array of Reports */ ReportList;
export type ListReportsApiArg = void;
export type GetReportApiResponse = /** status 200 Specific Report */ Report;
export type GetReportApiArg = {
  id: string;
};
export type UpdateReportApiResponse = /** status 200 Report updated */ void;
export type UpdateReportApiArg = {
  id: string;
  report: Report;
};
export type DeleteReportApiResponse = /** status 204 Report deleted */ void;
export type DeleteReportApiArg = {
  id: string;
};
export type ListReportTestsApiResponse =
  /** status 200 Specific Report */ ReportInputTestList;
export type ListReportTestsApiArg = {
  id: string;
};
export type GradeReportTestsApiResponse = /** status 200 Graded */ void;
export type GradeReportTestsApiArg = {
  id: string;
  testId: string;
  gradeRequest: GradeRequest;
};
export type AddTestPromptApiResponse =
  /** status 201 TestPrompt created */ void;
export type AddTestPromptApiArg = {
  testPrompt: TestPrompt;
};
export type ListTestPromptsApiResponse =
  /** status 200 An array of TestPrompts */ TestPromptList;
export type ListTestPromptsApiArg = void;
export type GetTestPromptApiResponse =
  /** status 200 Specific TestPrompt */ TestPrompt;
export type GetTestPromptApiArg = {
  id: string;
};
export type UpdateTestPromptApiResponse =
  /** status 200 TestPrompt updated */ void;
export type UpdateTestPromptApiArg = {
  id: string;
  testPrompt: TestPrompt;
};
export type DeleteTestPromptApiResponse =
  /** status 204 TestPrompt deleted */ void;
export type DeleteTestPromptApiArg = {
  id: string;
};
export type DuplicateTestPromptApiResponse =
  /** status 200 Specific TestPrompt */ TestPrompt;
export type DuplicateTestPromptApiArg = {
  id: string;
};
export type CrawlApiResponse =
  /** status 200 Crawl Initiated Message */ CrawlMessage;
export type CrawlApiArg = {
  crawlRequest: CrawlRequest;
};
export type RedirectRule = {
  redirect?: string;
  displayName?: string;
  serviceType?: string;
};
export type LoginDetails = {
  loginStrategy?:
    | "form"
    | "redirect"
    | "service-account"
    | "redirect-service-account";
  redirectRules?: RedirectRule[];
  isK8S?: boolean;
  animatedLogin?: boolean;
};
export type ApiError = {
  message?: string;
  detailedMessage?: string;
};
export type LoginSuccess = {
  token?: string;
};
export type LoginRequest = {
  accessKey?: string;
  secretKey?: string;
  sts?: string;
  features?: {
    hide_menu?: boolean;
  };
};
export type LoginOauth2AuthRequest = {
  state: string;
  code: string;
};
export type LogoutRequest = {
  state?: string;
};
export type PermissionResource = {
  resource?: string;
  conditionOperator?: string;
  prefixes?: string[];
};
export type EnvironmentConstants = {
  maxConcurrentUploads?: number;
  maxConcurrentDownloads?: number;
};
export type SessionResponse = {
  features?: string[];
  status?: "ok";
  operator?: boolean;
  distributedMode?: boolean;
  serverEndPoint?: string;
  permissions?: {
    [key: string]: string[];
  };
  customStyles?: string;
  allowResources?: PermissionResource[];
  envConstants?: EnvironmentConstants;
};
export type SearchResult = {
  /** Object Key including the bucket where the result is located */
  key?: string;
  text?: string;
  data_source?: string;
  /** Temporary URL to access the document */
  pre_signed_url?: string;
  /** Temporary URL to access linked image (if applicable) */
  linked_image_url?: string;
  /** Temporary URL to access linked actual, related data (if applicable) */
  linked_data_url?: string;
  /** Source webpage URL for content that was scraped from the web (only applicable for Webpage category) */
  web_source_url?: string;
  score?: number;
  category?: string;
  bucket?: string;
  source_chunk?: string;
  source_location?: string;
};
export type SearchDomains = {
  results?: SearchResult[];
};
export type SearchResults = {
  domains?: {
    [key: string]: SearchDomains;
  };
};
export type MinioLambda = object;
export type Index = {
  id?: string;
  collection_id?: string;
  name?: string;
  status?: string;
  is_default?: boolean;
  statistics?: {
    row_count?: number;
  };
};
export type Collection = {
  name?: string;
  sequence_length?: number;
  num_dimensions?: number;
  endpoint?: string;
  query_prefix?: string;
  document_prefix?: string;
  vector_index_func?: string;
  vector_index_type?: string;
  vector_index_params?: {
    [key: string]: {
      [key: string]: any;
    };
  };
  collection_type?: string;
  active_index?: Index;
};
export type Domain = {
  id?: string;
  name?: string;
  description?: string;
  collection_id?: string;
  collection?: Collection;
  match_threshold?: number;
  prompt_template?: string;
  is_default?: boolean;
  is_active?: boolean;
  mode?: "text" | "audio" | "video";
  llm_endpoint?: string;
  max_new_tokens?: number;
  temperature?: number;
  top_p?: number;
  repetition_penalty?: number;
};
export type DomainList = {
  results?: Domain[];
};
export type CollectionList = {
  results?: Collection[];
};
export type IndexList = {
  results?: Index[];
};
export type Room = {
  id?: string;
  title?: string;
  sys_created_date?: number;
  total_messages?: number;
};
export type RoomList = {
  results?: Room[];
};
export type SynthesizeVoiceInput = {
  text?: string;
};
export type QueueCounts = {
  queues?: {
    [key: string]: {
      [key: string]: number;
    };
  };
};
export type Chunk = {
  number?: number;
  text?: string;
};
export type ChunkList = {
  results?: Chunk[];
};
export type Question = {
  text?: string;
  chunk?: string;
};
export type QuestionList = {
  results?: Question[];
};
export type Suggestion = {
  question?: string;
  id?: string;
};
export type SuggestionList = {
  suggestions?: Suggestion[];
};
export type ZoomResponse = {
  plainToken?: string;
  encryptedToken?: string;
};
export type Report = {
  id?: string;
  name?: string;
  sys_created_date?: string;
  domain_id?: string;
  domain_snapshot?: {
    [key: string]: any;
  };
  collection_snapshot?: {
    [key: string]: any;
  };
  counts?: {
    tests?: number;
    completed?: number;
    goods?: number;
    bads?: number;
  };
};
export type ReportList = {
  results?: Report[];
};
export type ReportInputTest = {
  id?: string;
  sys_created_date?: string;
  report?: string;
  input?: string;
  room?: string;
  graded?: string;
  output?: string;
  output_metadata?: {
    [key: string]: any;
  };
};
export type ReportInputTestList = {
  results?: ReportInputTest[];
};
export type GradeRequest = {
  grade?: "good" | "bad";
};
export type TestPrompt = {
  id?: string;
  sys_created_date?: string;
  input_text?: string;
};
export type TestPromptList = {
  results?: TestPrompt[];
};
export type CrawlMessage = {
  id?: string;
  initiated_date?: string;
};
export type CrawlRequest = {
  url?: string;
  crawl?: boolean;
  num_pages_cap?: number;
};
export const {
  useLoginDetailQuery,
  useLoginMutation,
  useLoginOauth2AuthMutation,
  useLogoutMutation,
  useSessionCheckQuery,
  useSearchQuery,
  useIndexingMutation,
  useProcessingMutation,
  useListDomainsQuery,
  useAddDomainMutation,
  useGetDomainQuery,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
  useDuplicateDomainMutation,
  useListCollectionsQuery,
  useAddCollectionMutation,
  useGetCollectionQuery,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
  useListIndexesQuery,
  useAddIndexMutation,
  useGetIndexQuery,
  useUpdateIndexMutation,
  useDeleteIndexMutation,
  useReindexMutation,
  useSetDefaultIndexMutation,
  useListRoomsQuery,
  useCreateRoomMutation,
  useSynthesizeVoiceMutation,
  useQueueTotalQuery,
  useGetTranscriptQuery,
  useListQuestionsQuery,
  useSuggestPromptsQuery,
  useSemanticSuggestionPromptsQuery,
  useZoomMutation,
  useAddReportMutation,
  useListReportsQuery,
  useGetReportQuery,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useListReportTestsQuery,
  useGradeReportTestsMutation,
  useAddTestPromptMutation,
  useListTestPromptsQuery,
  useGetTestPromptQuery,
  useUpdateTestPromptMutation,
  useDeleteTestPromptMutation,
  useDuplicateTestPromptMutation,
  useCrawlMutation,
} = injectedRtkApi;
