import React from "react";

const GoodIcon = () => {
  return (
    <svg
      id="EnabledIcon-full"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_1067"
            data-name="Rectángulo 1067"
            width="21"
            height="21"
            fill="#4ccb92"
          />
        </clipPath>
      </defs>
      <rect
        id="Rectángulo_1066"
        data-name="Rectángulo 1066"
        width="21"
        height="21"
        fill="none"
      />
      <g id="Grupo_2453" data-name="Grupo 2453">
        <g id="Grupo_2452" data-name="Grupo 2452" clip-path="url(#clip-path)">
          <path
            id="Trazado_7231"
            data-name="Trazado 7231"
            d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.5,10.5,0,0,0,10.5,0m6,7.572-.007.007-6.212,7.765a1.169,1.169,0,0,1-.834.373H9.43a1.166,1.166,0,0,1-.824-.342h0L4.49,11.257a1.167,1.167,0,1,1,1.58-1.719q.036.033.07.07L9.4,12.864l5.4-6.885c.01-.012.02-.023.031-.034h0A1.167,1.167,0,0,1,16.5,7.572"
            fill="#4ccb92"
          />
        </g>
      </g>
    </svg>
  );
};
export default GoodIcon;
