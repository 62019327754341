import { api } from "api";
import { ApiError, ContentType, Domain } from "api/eurekaApi";
import {
  Box,
  Button,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  InputBox,
  Loader,
  RefreshIcon,
  Select,
  Switch,
} from "mds";
import { SelectorType } from "mds/dist/cjs/types/global/global.types";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteDomain from "./DeleteDomain";
import FormField from "../FormField";
import EButton from "../../../components/EButton";
import ESectionTitle from "components/ESectionTitle";
import PromptStyle from "../../../components/PromptStyle";
import { useListDomainsQuery } from "../../../api/eurekaApiRTK";

const DomainDetails = ({ newRecord = false }: { newRecord?: boolean }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { refetch } = useListDomainsQuery({});

  const [editMode, setEditMode] = useState<boolean>(newRecord);
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [delOpen, setDelOpen] = useState<boolean>(false);
  const [record, setRecord] = useState<Domain | null>(newRecord ? {} : null);
  const [origRecord, setOrigRecord] = useState<Domain | null>(null);
  const [staging, setStaging] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<ApiError | null>(null);
  const [collections, setCollections] = useState<SelectorType[]>([]);

  const getRecord = useCallback(() => {
    const parts = pathname.split("/");
    api.domains
      .getDomain(parts[parts.length - 1])
      .then((res) => {
        if (res.data) {
          setRecord(res.data);
        }
      })
      .catch((e) => {
        console.warn("Error loading domain", e);
        setError(e.error);
      });
  }, [pathname]);

  const getRelated = useCallback(() => {
    api.collections
      .listCollections()
      .then((res) => {
        if (res.data && res.data.results) {
          let cols: SelectorType[] = [];

          for (let i = 0; i < res.data.results.length; i++) {
            cols.push({
              label: res.data.results[i].name!,
              value: res.data.results[i].name!,
            });
          }
          setCollections(cols);
        }
      })
      .catch((e) => {
        console.warn("Error loading collections", e);
        setError(e.error);
      });
  }, []);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      if (!newRecord) {
        getRecord();
      }
      getRelated();
    }
  }, [iniLoad, getRecord, getRelated, newRecord]);

  return (
    <div style={{ maxWidth: 1000 }}>
      {!record && <Loader />}
      {record && (
        <Fragment>
          {delOpen && (
            <DeleteDomain
              onCancel={() => setDelOpen(false)}
              onDelete={() => {
                const parts = pathname.split("/");
                api.domains
                  .deleteDomain(parts[parts.length - 1], {
                    type: ContentType.Json,
                  })
                  .then((res) => {
                    refetch();
                    navigate("/management/domains/");
                  })
                  .catch((e) => {
                    setError(e.error);
                  });
              }}
            />
          )}
          <ESectionTitle
            actions={
              <Fragment>
                {editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <Button
                      id={"save-edit-domain"}
                      variant={"callAction"}
                      onClick={() => {
                        const outDom: Domain = { ...record };

                        if (staging.hasOwnProperty("match_threshold")) {
                          outDom.match_threshold = parseFloat(
                            staging["match_threshold"],
                          );
                        }
                        if (staging.hasOwnProperty("max_new_tokens")) {
                          outDom.max_new_tokens = parseFloat(
                            staging["max_new_tokens"],
                          );
                        }

                        if (staging.hasOwnProperty("temperature")) {
                          outDom.temperature = parseFloat(
                            staging["temperature"],
                          );
                        }

                        if (staging.hasOwnProperty("top_p")) {
                          outDom.top_p = parseFloat(staging["top_p"]);
                        }

                        if (staging.hasOwnProperty("repetition_penalty")) {
                          outDom.repetition_penalty = parseFloat(
                            staging["repetition_penalty"],
                          );
                        }

                        if (newRecord) {
                          api.domains
                            .addDomain(outDom, { type: ContentType.Json })
                            .then((res) => {
                              refetch();
                              navigate("/management/domains/");
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(e.error);
                            });
                        } else {
                          const parts = pathname.split("/");
                          api.domains
                            .updateDomain(parts[parts.length - 1], outDom, {
                              type: ContentType.Json,
                            })
                            .then((res) => {
                              refetch();
                              setEditMode(false);
                              getRecord();
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(e.error);
                            });
                        }
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      id={"save-edit-domain"}
                      variant={"secondary"}
                      onClick={() => {
                        if (newRecord) {
                          navigate("/management/domains");
                        } else {
                          setRecord(origRecord);
                          setOrigRecord(null);
                          setEditMode(false);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <EButton
                      id={"duplicate-domain"}
                      onClick={() => {
                        const parts = pathname.split("/");
                        api.domains
                          .duplicateDomain(parts[parts.length - 1])
                          .then((res) => {
                            if (res.data) {
                              setIniLoad(false);
                              navigate(`/management/domains/${res.data.id}`);
                            }
                          })
                          .catch((e) => {
                            console.log(e);
                            setError(e.error);
                          });
                      }}
                      icon={<CopyIcon />}
                    >
                      Copy
                    </EButton>
                    <EButton
                      id={"edit-domain"}
                      onClick={() => {
                        setDelOpen(true);
                      }}
                      icon={<DeleteIcon />}
                    >
                      Delete
                    </EButton>
                    <EButton
                      id={"edit-domain"}
                      onClick={() => {
                        setOrigRecord({ ...record });
                        setStaging({
                          match_threshold: `${record?.match_threshold}`,
                          max_new_tokens: `${record?.max_new_tokens}`,
                          temperature: `${record?.temperature}`,
                          top_p: `${record?.top_p}`,
                          repetition_penalty: `${record?.repetition_penalty}`,
                        });
                        setEditMode(true);
                      }}
                      icon={<EditIcon />}
                    >
                      Edit
                    </EButton>
                    <EButton
                      id={"refresh"}
                      onClick={() => {
                        getRecord();
                      }}
                      icon={<RefreshIcon />}
                    >
                      Refresh
                    </EButton>
                  </div>
                )}
              </Fragment>
            }
            separator
          >
            {record.name}
          </ESectionTitle>
          {error && (
            <div
              style={{
                backgroundColor: "#ffa6a6",
                border: "1px solid red",
                borderRadius: 4,
                padding: 8,
                margin: 8,
              }}
            >
              <b>{error.message}</b>
              <p>{error.detailedMessage}</p>
            </div>
          )}
          <div
            style={{
              display: "flex",

              gap: 16,
              marginTop: 16,
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <FormField title={"ID"}>{record.id}</FormField>
              <FormField title={"Name"}>
                {!editMode && <Fragment>{record.name}</Fragment>}
                {editMode && (
                  <InputBox
                    id={"name"}
                    value={record.name}
                    onChange={(e) => {
                      if (record) {
                        setRecord({ ...record, name: e.target.value });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"Active"}>
                {!editMode && (
                  <Fragment>{record.is_active ? "Yes" : "No"}</Fragment>
                )}
                {editMode && (
                  <Switch
                    id="Switch"
                    checked={record.is_active}
                    onClick={(v) => {
                      if (record) {
                        setRecord({
                          ...record,
                          is_active: !record.is_active,
                        });
                      }
                    }}
                  />
                )}
              </FormField>{" "}
              <FormField title={"Default"}>
                {!editMode && (
                  <Fragment>{record.is_default ? "Yes" : "No"}</Fragment>
                )}
                {editMode && (
                  <Switch
                    id="Switch"
                    checked={record.is_default}
                    onClick={(v) => {
                      if (record) {
                        setRecord({
                          ...record,
                          is_default: !record.is_default,
                        });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"Mode"}>
                {!editMode && <Fragment>{record.mode}</Fragment>}
                {editMode && (
                  <Select
                    id="mode"
                    value={record.mode}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          mode: e as "text" | "audio" | "video",
                        });
                      }
                    }}
                    options={[
                      { label: "Text", value: "text" },
                      { label: "Audio", value: "audio" },
                      { label: "Video", value: "video" },
                    ]}
                  />
                )}
              </FormField>
              <FormField title={"Collection"}>
                {!editMode && <Fragment>{record.collection_id}</Fragment>}
                {editMode && (
                  <Select
                    id="collection"
                    value={record.collection_id}
                    onChange={(e) => {
                      if (record) {
                        setRecord({ ...record, collection_id: e });
                      }
                    }}
                    options={collections}
                  />
                )}
              </FormField>
              <FormField title={"Description"}>
                {!editMode && <Fragment>{record.description}</Fragment>}
                {editMode && (
                  <textarea
                    cols={60}
                    rows={20}
                    onChange={(e) => {
                      if (record) {
                        setRecord({ ...record, description: e.target.value });
                      }
                    }}
                  >
                    {record.description}
                  </textarea>
                )}
              </FormField>
            </Box>
            <div style={{ width: "50%" }}>
              <FormField title={"Prompt Template"}>
                {!editMode && (
                  <PromptStyle>
                    {record.prompt_template ?? "No Template"}
                  </PromptStyle>
                )}
                {editMode && (
                  <textarea
                    cols={60}
                    rows={20}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          prompt_template: e.target.value,
                        });
                      }
                    }}
                  >
                    {record.prompt_template}
                  </textarea>
                )}
              </FormField>

              <FormField title={"Treshold"}>
                {!editMode && <Fragment>{record.match_threshold}</Fragment>}
                {editMode && (
                  <InputBox
                    id={"match_threshold"}
                    value={staging.match_threshold}
                    onChange={(e) => {
                      if (record) {
                        setStaging({
                          ...staging,
                          match_threshold: e.target.value,
                        });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"LLM Endpoint"}>
                {!editMode && <Fragment>{record.llm_endpoint}</Fragment>}
                {editMode && (
                  <InputBox
                    id={"llm_endpoint"}
                    value={record.llm_endpoint}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          llm_endpoint: e.target.value,
                        });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"LLM Config"}>
                <ul>
                  <li>
                    <i>max_new_tokens</i>:{" "}
                    {!editMode && <b>{record.max_new_tokens}</b>}
                    {editMode && (
                      <InputBox
                        id={"max_new_tokens"}
                        value={staging.max_new_tokens}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              max_new_tokens: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>temperature</i>:{" "}
                    {!editMode && <b>{record.temperature}</b>}
                    {editMode && (
                      <InputBox
                        id={"temperature"}
                        value={staging.temperature}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              temperature: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>top_p</i>: {!editMode && <b>{record.top_p}</b>}
                    {editMode && (
                      <InputBox
                        id={"top_p"}
                        value={staging.top_p}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              top_p: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>repetition_penalty</i>:{" "}
                    {!editMode && <b>{record.repetition_penalty}</b>}
                    {editMode && (
                      <InputBox
                        id={"repetition_penalty"}
                        value={staging.repetition_penalty}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              repetition_penalty: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                </ul>
              </FormField>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default DomainDetails;
