/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "app/store";

function convertBase64ToBlob(base64String: string): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const binaryAudioData: Uint8Array = Uint8Array.from(
      atob(base64String),
      (c) => c.charCodeAt(0),
    );
    // Create a Blob from the Uint8Array
    const blob = new Blob([binaryAudioData], { type: "audio/wav" });
    resolve(blob);
  });
}

export const speak = createAsyncThunk(
  "talk2me/speak",
  async (_, { getState }) => {
    const state = getState() as AppState;
    const speech_audio = state.talk2me.speech_audio;
    const voiceEnabled = state.system.isVoiceEnabled;
    // TODO: fix repeat speech bug (MessageList.tsx or here)
    if (speech_audio && voiceEnabled) {
      if (typeof speech_audio === "string") {
        // Convert the base 64 encoded string of audio data to a Blob
        return convertBase64ToBlob(speech_audio)
          .then((blob) => {
            return URL.createObjectURL(blob);
          })
          .catch((error) => {
            console.error("Error converting base 64 string to blob:", error);
          });
      }
    }
  },
);
