import React from "react";
import "./Drawer.scss";
import { resetTranscriptViewer } from "screens/document/transcript/transcriptSlice";
import Transcript from "screens/document/transcript/Transcript";
import { useAppDispatch, useAppSelector } from "app/hooks";

const ConversationDrawer = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.transcript.open);

  return (
    <div className={`drawer ${open ? "open" : ""}`}>
      <div
        onClick={() => dispatch(resetTranscriptViewer())}
        className={"drawer-empty"}
      ></div>
      <div className={"drawer-content"}>
        <Transcript />
      </div>
    </div>
  );
};

export default ConversationDrawer;
