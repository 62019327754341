import React from "react";

const BadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_1068"
            data-name="Rectángulo 1068"
            width="21"
            height="21"
            fill="#eb0064"
          />
        </clipPath>
      </defs>
      <g id="DisableIcon-full" transform="translate(-0.5 -0.5)">
        <rect
          id="Rectángulo_1065"
          data-name="Rectángulo 1065"
          width="21"
          height="21"
          transform="translate(0.5 0.5)"
          fill="none"
        />
        <g
          id="Grupo_2455"
          data-name="Grupo 2455"
          transform="translate(0.5 0.5)"
        >
          <g
            id="Grupo_2454"
            data-name="Grupo 2454"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Trazado_7232"
              data-name="Trazado 7232"
              d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.5,10.5,0,0,0,10.5,0m4.245,7.088-2.979,3.641,2.6,3.184a1.125,1.125,0,0,1-1.742,1.425h0l-2.317-2.832L8,15.337a1.125,1.125,0,0,1-1.742-1.425l2.6-3.184L5.879,7.088A1.125,1.125,0,0,1,7.621,5.663l2.692,3.289L13,5.663a1.125,1.125,0,1,1,1.742,1.425"
              transform="translate(-0.065 -0.065)"
              fill="#eb0064"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default BadIcon;
