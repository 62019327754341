import { useAppDispatch } from "app/hooks";
import React, { useEffect, useState } from "react";
import Transcript from "screens/document/transcript/Transcript";
import { openTranscriptDrawer } from "screens/document/transcript/transcriptSlice";
import { getChunksAsync } from "screens/document/transcript/transcriptThunks";

const TestComponent = () => {
  const dispatch = useAppDispatch();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      dispatch(
        openTranscriptDrawer({
          open: false,
          document:
            "ml-data/eureka/videos/Garima Kapoor - Minio VMware Explore 2022.mp4",
        }),
      );
      dispatch(getChunksAsync());
    }
  }, [initialLoad, dispatch]);
  return <Transcript />;
};

export default TestComponent;
