import React, { useEffect } from "react";
import { Loader } from "mds";
import { api } from "api";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { resetSession } from "./systemSlice";
import { clearSession } from "./logout/logout.utils";
import { LogoutRequest } from "api/eurekaApi";

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const logout = () => {
      const deleteSession = () => {
        clearSession();
        localStorage.setItem("userLoggedIn", "");
        localStorage.setItem("redirect-path", "");
        dispatch(resetSession());
        navigate(`/login`);
      };
      const state = localStorage.getItem("auth-state");

      const body: LogoutRequest = {};
      if (state !== null) {
        body.state = state;
      }

      api.logout.logout(body).then((res) => {
        if (res.data === undefined) {
          console.log(res.error);
        }
        deleteSession();
      });
    };
    logout();
  }, [dispatch, navigate]);
  return <Loader />;
};
export default Logout;
