import { api } from "api";
import { ApiError, Collection, ContentType } from "api/eurekaApi";
import {
  Button,
  DeleteIcon,
  EditIcon,
  InputBox,
  Loader,
  RefreshIcon,
  SectionTitle,
  Select,
} from "mds";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteCollection from "./DeleteCollection";
import ListIndexes from "../indexes/ListIndexes";
import ErrorDisplay from "../../../components/ErrorDisplay";
import EButton from "components/EButton";
import FormField from "../FormField";

const CollectionDetails = ({ newRecord = false }: { newRecord?: boolean }) => {
  const { pathname } = useLocation();
  const { collectionID } = useParams();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState<boolean>(newRecord);
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [delOpen, setDelOpen] = useState<boolean>(false);
  const [record, setRecord] = useState<Collection | null>(
    newRecord ? { collection_type: "docs" } : null,
  );
  const [origRecord, setOrigRecord] = useState<Collection | null>(null);
  const [staging, setStaging] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<ApiError | null>(null);

  const getRecord = useCallback(() => {
    const parts = pathname.split("/");
    api.collections
      .getCollection(parts[parts.length - 1])
      .then((res) => {
        if (res.data) {
          setRecord(res.data);
        }
      })
      .catch((e) => {
        console.warn("Error loading collection", e);
        setError(e.error);
      });
  }, [pathname]);

  useEffect(() => {
    if (!iniLoad && !newRecord) {
      setIniLoad(true);
      getRecord();
    }
  }, [iniLoad, getRecord, newRecord]);

  return (
    <Fragment>
      {!record && <Loader />}
      {record && (
        <Fragment>
          {delOpen && (
            <DeleteCollection
              onCancel={() => setDelOpen(false)}
              onDelete={() => {
                const parts = pathname.split("/");
                api.collections
                  .deleteCollection(parts[parts.length - 1], {
                    type: ContentType.Json,
                  })
                  .then((res) => {
                    navigate("/management/collections/");
                  })
                  .catch((e) => {
                    console.log(e);
                    setError(e.error);
                  });
              }}
            />
          )}
          <SectionTitle
            actions={
              <Fragment>
                {editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <Button
                      id={"save-edit-Collection"}
                      variant={"callAction"}
                      onClick={() => {
                        const outDom: Collection = { ...record };

                        if (staging.hasOwnProperty("sequence_length")) {
                          outDom.sequence_length = Number(
                            staging["sequence_length"],
                          );
                        }
                        if (staging.hasOwnProperty("num_dimensions")) {
                          outDom.num_dimensions = Number(
                            staging["num_dimensions"],
                          );
                        }

                        if (newRecord) {
                          api.collections
                            .addCollection(outDom, { type: ContentType.Json })
                            .then((res) => {
                              navigate("/management/collections/");
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(e.error);
                            });
                        } else {
                          const parts = pathname.split("/");
                          api.collections
                            .updateCollection(parts[parts.length - 1], outDom, {
                              type: ContentType.Json,
                            })
                            .then((res) => {
                              setEditMode(false);
                              getRecord();
                            })
                            .catch((e) => {
                              console.log(e);
                              setError(e.error);
                            });
                        }
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      id={"save-edit-Collection"}
                      variant={"secondary"}
                      onClick={() => {
                        if (newRecord) {
                          navigate("/management/collections");
                        } else {
                          setRecord(origRecord);
                          setOrigRecord(null);
                          setEditMode(false);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <EButton
                      id={"edit-Collection"}
                      onClick={() => {
                        setDelOpen(true);
                      }}
                      icon={<DeleteIcon />}
                    >
                      Delete
                    </EButton>
                    <EButton
                      id={"edit-Collection"}
                      onClick={() => {
                        setOrigRecord({ ...record });
                        setStaging({
                          num_dimensions: `${record.num_dimensions}`,
                          sequence_length: `${record?.sequence_length}`,
                        });
                        setEditMode(true);
                      }}
                      icon={<EditIcon />}
                    >
                      Edit
                    </EButton>
                    <EButton
                      id={"refresh"}
                      onClick={() => {
                        setIniLoad(false);
                      }}
                      icon={<RefreshIcon />}
                    >
                      Refresh
                    </EButton>
                  </div>
                )}
              </Fragment>
            }
            separator
          >
            {record.name}
          </SectionTitle>
          {error && <ErrorDisplay err={error} />}
          <div
            style={{
              display: "flex",

              gap: 16,
              marginTop: 16,
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <FormField title={"Name"}>
                {!editMode && <Fragment>{record.name}</Fragment>}
                {editMode && (
                  <InputBox
                    id={"name"}
                    value={record.name}
                    onChange={(e) => {
                      if (record) {
                        setRecord({ ...record, name: e.target.value });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"Endpoint"}>
                {!editMode && <Fragment>{record.endpoint}</Fragment>}
                {editMode && (
                  <InputBox
                    id={"llm_endpoint"}
                    value={record.endpoint}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          endpoint: e.target.value,
                        });
                      }
                    }}
                  />
                )}
              </FormField>
              <FormField title={"Type"}>
                {!editMode && <Fragment>{record.collection_type}</Fragment>}
                {editMode && (
                  <Select
                    id={"col_type"}
                    value={record.collection_type}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          collection_type: e,
                        });
                      }
                    }}
                    options={[
                      {
                        label: "Documents",
                        value: "docs",
                      },
                      {
                        label: "Questions",
                        value: "questions",
                      },
                    ]}
                  />
                )}
              </FormField>
            </div>
            <div style={{ width: "50%" }}>
              <FormField title={"Config"}>
                <ul>
                  <li>
                    <i>sequence_length</i>:{" "}
                    {!editMode && <b>{record.sequence_length}</b>}
                    {editMode && (
                      <InputBox
                        id={"sequence_length"}
                        value={staging.sequence_length}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              sequence_length: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>num_dimensions</i>:{" "}
                    {!editMode && <b>{record.num_dimensions}</b>}
                    {editMode && (
                      <InputBox
                        id={"num_dimensions"}
                        value={staging.num_dimensions}
                        onChange={(e) => {
                          if (record) {
                            setStaging({
                              ...staging,
                              num_dimensions: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>query_prefix</i>:{" "}
                    {!editMode && <b>{record.query_prefix}</b>}
                    {editMode && (
                      <InputBox
                        id={"query_prefix"}
                        value={record.query_prefix}
                        onChange={(e) => {
                          if (record) {
                            setRecord({
                              ...record,
                              query_prefix: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                  <li>
                    <i>document_prefix</i>:{" "}
                    {!editMode && <b>{record.document_prefix}</b>}
                    {editMode && (
                      <InputBox
                        id={"document_prefix"}
                        value={record.document_prefix}
                        onChange={(e) => {
                          if (record) {
                            setRecord({
                              ...record,
                              document_prefix: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </li>
                </ul>
              </FormField>
            </div>
          </div>
          {!editMode && !newRecord && (
            <Fragment>
              {collectionID && (
                <Fragment>
                  <ListIndexes collectionID={collectionID} rerender={iniLoad} />
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default CollectionDetails;
