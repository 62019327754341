import React, { useEffect, useRef } from "react";
import ChatSearchDrawer from "./ChatSearchDrawer";
import ConversationDrawer from "../document/transcript/ConversationDrawer";
import QuestionsDrawer from "../document/questions/QuestionsDrawer";
import avatar from "./avatar-256.jpg";
import ChatMessage from "./ChatMessage";
import TextPrompt from "./TextPrompt/TextPrompt";
import { AudioIcon, AudioIconMute, Box, breakPoints, Button } from "mds";
import { toggleDrawer } from "./chatSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setIsVoiceEnabled } from "../systemSlice";
import { useParams } from "react-router-dom";
import SuggestionBox from "./SugesstionBox";
import ETextIcon from "./ETextIcon";
import { submitPrompt } from "./TextPrompt/textPromptThunks";
import { useRoomMessagesQuery } from "../../api/gatewayApiRTK";
import VoiceChatOverlay from "./VoiceChatOverlay";
import { WsMessage } from "../../protos/gateway";

const MessageList = () => {
  const dispatch = useAppDispatch();

  const { roomID } = useParams();

  useRoomMessagesQuery({ id: roomID ?? "" });

  const scrollTrigger = useAppSelector((state) => state.chat.scrollTrigger);

  const divRef = useRef<null | HTMLDivElement>(null);
  const showMenu = useAppSelector((state) => state.system.showMenu);
  const messages = useAppSelector((state) => {
    if (!roomID) {
      return [];
    }
    if (
      !state.system.selectedDomain ||
      !state.chat.messages[state.system.selectedDomain!]
    ) {
      return [];
    }

    return state.chat.messages[state.system.selectedDomain!][roomID] ?? [];
  });

  const isVoiceEnabled = useAppSelector((state) => state.system.isVoiceEnabled);

  useEffect(() => {
    // Fetch value from localStorage and use value when component mounts
    const storedValue = localStorage.getItem("voiceOutputEnabled");
    if (storedValue) {
      dispatch(setIsVoiceEnabled(storedValue === "true"));
    }
  }, [dispatch]);

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [scrollTrigger]);

  useEffect(() => {
    if (roomID) {
      const rsMsg: WsMessage = {
        subscribeToRoom: {
          roomId: roomID,
        },
      };

      dispatch({ type: "socket/postMessage", payload: rsMsg });
    }
  }, [dispatch, roomID]);

  const toggleVoice = (newValue: boolean) => {
    dispatch(setIsVoiceEnabled(newValue));
    // Store the new value in localStorage whenever it changes
    localStorage.setItem("voiceOutputEnabled", String(newValue));
  };

  const examples: string[] = [
    "How to write a bash loop?",
    "What is MinIO?",
    "How to install Spark?",
  ];

  return (
    <Box
      sx={{
        width: "100%",
        flex: "1 1 auto",
        minHeight: 0,
        height: "100%",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <ChatSearchDrawer />
      <ConversationDrawer />
      <QuestionsDrawer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 1032,
          margin: "auto",
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom:
            !roomID || !messages || (messages && messages.length === 0)
              ? 0
              : 300,
          [`@media (max-width: ${breakPoints.md}px)`]: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        }}
      >
        <div style={{ height: 32 }}></div>
        <VoiceChatOverlay />
        {roomID && (!messages || (messages && messages.length === 0)) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 400px)",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(251, 250, 250)",
                border: "1px solid rgb(226, 226, 226)",
                padding: 32,
                display: "flex",
                alignItems: "center",
                marginTop: 64,
                gap: 16,
              }}
            >
              <div>
                {showMenu
                  ? `There's no messages in this room for the selected domain.`
                  : `No messages in this room`}
              </div>
            </Box>
          </Box>
        )}

        {!roomID && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 230px)",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F7F9FC",
                padding: 32,
                borderRadius: 10,
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                margin: 8,
                gap: 30,
                [`@media (max-width: ${breakPoints.md}px)`]: {
                  "&": {
                    flexWrap: "wrap",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: "0",
                  "& img": {
                    margin: "auto",
                    borderRadius: "50%",
                    width: 200,
                  },
                  [`@media (max-width: ${breakPoints.md}px)`]: {
                    "& img": {
                      width: 100,
                    },
                  },
                }}
              >
                <img alt={"avatar"} src={avatar} />
              </Box>
              <Box
                sx={{
                  flexShrink: "1",
                  flexGrow: "0",
                  [`@media (max-width: ${breakPoints.md}px)`]: {
                    "& h1": {
                      fontSize: "18px !important",
                    },
                  },
                }}
              >
                <h1 style={{ margin: 0, fontSize: 34 }}>
                  Hello! my name is Eureka
                </h1>
                <div style={{ fontSize: 16, marginTop: 8, color: "#12222C" }}>
                  I will provide you with all the necessary information and
                  assistance needed to complete any task or project you may
                  have.
                </div>
                <div style={{ marginTop: 16 }}>
                  <b>EXAMPLES:</b>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 16,
                      marginTop: 8,
                      [`@media (max-width: ${breakPoints.md}px)`]: {
                        gap: 8,
                      },
                    }}
                  >
                    {examples.map((ex, si) => (
                      <SuggestionBox
                        key={`si-${si}`}
                        text={ex}
                        onClick={() => {
                          dispatch(
                            submitPrompt({
                              inPrompt: ex,
                              roomID: roomID,
                            }),
                          );
                        }}
                      />
                    ))}
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        )}
        {messages &&
          messages.map((msg, index, row) => {
            return <ChatMessage key={`c-${msg.domain}-${msg.id}`} msg={msg} />;
          })}
      </Box>
      <div ref={divRef} />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          display: "flex",
          backgroundImage:
            "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 1032,
            margin: "auto",
            paddingLeft: 9,
            paddingRight: 24,
            paddingBottom: 32,
            display: "flex",
            alignItems: "end",
          }}
        >
          <TextPrompt />
          <div style={{ paddingBottom: 3 }}>
            <Button
              id={"shw-rooms"}
              onClick={() => {
                dispatch(toggleDrawer());
              }}
              sx={{
                marginBottom: 11,
                paddingLeft: 16,
                paddingRight: 16,
                height: 40,
                width: 40,
                border: "1px solid #278EAD",
                borderRadius: 6,
              }}
            >
              <ETextIcon />
            </Button>
          </div>
        </Box>
        {showMenu && <div style={{ width: 420 }} />}
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: 110,
          right: 16,
          "& .min-icon": {
            fill: "#278EAD",
            width: 16,
            marginLeft: 2,
            marginTop: 2,
          },
        }}
      >
        <Button
          style={{
            marginBottom: 11,
            paddingLeft: 16,
            paddingRight: 16,
            height: 30,
            width: 30,
            border: "1px solid #278EAD",
            borderRadius: 6,
          }}
          id={"disable-audio"}
          onClick={() => {
            toggleVoice(!isVoiceEnabled);
          }}
        >
          {isVoiceEnabled ? <AudioIcon /> : <AudioIconMute />}
        </Button>
      </Box>
    </Box>
  );
};
export default MessageList;
