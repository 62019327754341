import { store } from "./app/store";
import { WebRTCMsg_WebRTCMsgType, WsMessage } from "./protos/gateway";

type StreamListener = (stream: MediaStream | null) => void;

class WebRTCManager {
  private peerConnection: RTCPeerConnection | null = null;
  private stream: MediaStream | null = null;
  private externalStream: MediaStream | null = null;
  private listeners: StreamListener[] = [];
  private turnIceServer: RTCIceServer | null = null;
  private currentRoomId: string | null = null;
  private timeoutHandler: NodeJS.Timeout | null = null;
  private requestInFlight = false;
  private audioElement: HTMLAudioElement | null = null; // New property to hold the audio element

  startPeerConnection(roomId: string): void {
    this.currentRoomId = roomId;
    let wsMsg: WsMessage = {
      webrtc: {
        type: WebRTCMsg_WebRTCMsgType.SIGNAL,
        payload: "start",
        roomId: roomId,
      },
    };
    store.dispatch({ type: "socket/postMessage", payload: wsMsg });
  }

  async startWebRTCConnection() {
    if (this.requestInFlight) {
      return;
    }
    this.requestInFlight = true;
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: { sampleRate: 16000, channelCount: 1 },
        video: false,
      });
      this.peerConnection = new RTCPeerConnection({
        iceServers: [
          // { urls: "stun:stun.l.google.com:19302" },
          // {
          //   urls: "turn:127.0.0.1:3478",
          //   username: "eureka",
          //   credential: "123456",
          // },
          this.turnIceServer!,
        ],
        // iceTransportPolicy: "relay",
      });

      this.stream.getTracks().forEach((track) => {
        if (this.stream) {
          this.peerConnection?.addTrack(track, this.stream);
        }
      });

      this.peerConnection.onicecandidate = (event) => {
        console.log("got an ICE candidate.", RightNow());
        console.log(event);
        // Check if ICE gathering is complete
        // if (event.candidate != null) {
        if (this.timeoutHandler === null) {
          this.timeoutHandler = setTimeout(() => {
            console.log("ICE gathering being sent.", RightNow());
            // All ICE candidates have been gathered, now send the complete offer
            const offer = this.peerConnection?.localDescription;

            let wsMsg: WsMessage = {
              webrtc: {
                type: WebRTCMsg_WebRTCMsgType.OFFER,
                payload: JSON.stringify(offer),
                roomId: this.currentRoomId ?? "",
              },
            };

            store.dispatch({ type: "socket/postMessage", payload: wsMsg });
            this.timeoutHandler = null;
          });
        }

        // ws.send(JSON.stringify({ type: "offer", offer: offer }));
        // }
      };
      console.log(" 🕊Offer being created.", RightNow());
      this.peerConnection
        .createOffer()
        .then((offer) => {
          // print received plus the current time
          console.log("Offer generated.", RightNow());
          console.log(offer);
          return this.peerConnection?.setLocalDescription(offer);
        })
        .catch((error) => {
          console.error("Failed to create offer:", error);
        });
      console.log("Recording started.");

      this.peerConnection.ontrack = (event) =>
        this.handleExternalTrackEvent(event);
      console.log("Track event handler set.");
    } catch (error) {
      console.error("Error accessing media devices:", error);
    } finally {
      this.requestInFlight = false;
    }
  }

  closeConnection(): void {
    // should we notify the server?
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
    if (this.externalStream) {
      this.externalStream.getTracks().forEach((track) => track.stop());
      this.externalStream = null;
    }
    this.notifyListeners();
    let wsMsg: WsMessage = {
      webrtc: {
        type: WebRTCMsg_WebRTCMsgType.SIGNAL,
        payload: "goingAway",
        roomId: this.currentRoomId ?? "",
      },
    };
    store.dispatch({ type: "socket/postMessage", payload: wsMsg });
  }

  private handleExternalTrackEvent(event: RTCTrackEvent): void {
    console.log(
      "handleExternalTrackEvent: Received a track event.",
      RightNow(),
    );
    console.log(`Track ID: ${event.track.id}`);
    console.log(`Track kind: ${event.track.kind}`);
    console.log(`Track label: ${event.track.label}`);
    console.log(`Track enabled: ${event.track.enabled}`);

    if (!this.externalStream) {
      this.externalStream = new MediaStream();
      console.log(
        "handleExternalTrackEvent: Created a new externalStream.",
        RightNow(),
      );
    } else {
      console.log(
        "handleExternalTrackEvent: Existing externalStream found.",
        RightNow(),
      );
    }

    console.log(
      `ExternalStream state before adding track: Tracks count = ${
        this.externalStream.getTracks().length
      }`,
    );
    this.externalStream.addTrack(event.track);
    console.log(
      "handleExternalTrackEvent: Track added to externalStream.",
      RightNow(),
    );
    console.log(
      `ExternalStream state after adding track: Tracks count = ${
        this.externalStream.getTracks().length
      }`,
    );

    // Notify all listeners about the new remote stream
    this.notifyListeners();
    // Ensure the audio element plays the remote stream
    this.playStreamThroughSpeaker();
  }

  handleOffer(offer: RTCSessionDescriptionInit) {
    if (this.peerConnection) {
      console.log("Offer received.", RightNow());
      this.peerConnection.setRemoteDescription(offer);
      this.peerConnection
        .createAnswer()
        .then((answer) => {
          console.log("Answer generated.", RightNow());
          this.peerConnection?.setLocalDescription(answer);
          // ws.send(JSON.stringify({ type: "answer", answer: answer }));

          let wsMsg: WsMessage = {
            webrtc: {
              type: WebRTCMsg_WebRTCMsgType.ANSWER,
              payload: JSON.stringify(answer),
              roomId: this.currentRoomId ?? "",
            },
          };
          store.dispatch({ type: "socket/postMessage", payload: wsMsg });
        })
        .catch((e) => console.error(e));
    }
  }

  handleAnswer(answer: RTCSessionDescriptionInit) {
    console.log("Answer received.", RightNow());
    this.peerConnection?.setRemoteDescription(answer);
  }

  handleCandidate(candidate: RTCIceCandidate) {
    console.log("Candidate received.", RightNow());
    console.log(candidate);
    this.peerConnection?.addIceCandidate(candidate);
  }

  addListener(listener: StreamListener): void {
    this.listeners.push(listener);
    // Immediately notify new listener with the current stream
    listener(this.stream);
  }

  removeListener(listener: StreamListener): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  private notifyListeners(): void {
    this.listeners.forEach((listener) => listener(this.stream));
    // Additionally, notify listeners with the external stream
    this.listeners.forEach((listener) => listener(this.externalStream));
  }

  private playStreamThroughSpeaker(): void {
    // Check if there's an external stream to play
    if (!this.externalStream) return;

    // Create the audio element if it doesn't exist
    if (!this.audioElement) {
      this.audioElement = document.createElement("audio");
      this.audioElement.autoplay = true; // Enable autoplay to start playing immediately
      document.body.appendChild(this.audioElement); // Append the element to the body to ensure it can play
    }

    // Attach the external stream to the audio element and play
    this.audioElement.srcObject = this.externalStream;
    console.log(
      "Attaching remote stream to audio element and playing.",
      RightNow(),
    );
  }

  handleConfig(rtcIceCandidate: RTCIceServer) {
    console.log("Config received.", RightNow(), rtcIceCandidate);
    this.turnIceServer = rtcIceCandidate;
  }
}

const RightNow = () => {
  return new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
  });
};

// Export as a singleton
export const webrtcManager = new WebRTCManager();
