// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: events.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";
import { MapData, ServiceSource, serviceSourceFromJSON, serviceSourceToJSON } from "./min-common";
import { SearchResults } from "./search";
import { Signal, signalFromJSON, signalToJSON } from "./signal";

export const protobufPackage = "min";

export enum EventType {
  GENERAL = 0,
  ROOM_TITLE = 1,
  SEARCH_RESULT = 2,
  LANG_DETECT = 3,
  TRANSLATING = 4,
  INQUIRE = 5,
  SEARCH_FAILED = 6,
  FOLLOW_UP = 7,
  SUGGESTED_QUESTIONS = 8,
  RESPONSE_DONE = 9,
  SIGNAL = 10,
  UNRECOGNIZED = -1,
}

export function eventTypeFromJSON(object: any): EventType {
  switch (object) {
    case 0:
    case "GENERAL":
      return EventType.GENERAL;
    case 1:
    case "ROOM_TITLE":
      return EventType.ROOM_TITLE;
    case 2:
    case "SEARCH_RESULT":
      return EventType.SEARCH_RESULT;
    case 3:
    case "LANG_DETECT":
      return EventType.LANG_DETECT;
    case 4:
    case "TRANSLATING":
      return EventType.TRANSLATING;
    case 5:
    case "INQUIRE":
      return EventType.INQUIRE;
    case 6:
    case "SEARCH_FAILED":
      return EventType.SEARCH_FAILED;
    case 7:
    case "FOLLOW_UP":
      return EventType.FOLLOW_UP;
    case 8:
    case "SUGGESTED_QUESTIONS":
      return EventType.SUGGESTED_QUESTIONS;
    case 9:
    case "RESPONSE_DONE":
      return EventType.RESPONSE_DONE;
    case 10:
    case "SIGNAL":
      return EventType.SIGNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EventType.UNRECOGNIZED;
  }
}

export function eventTypeToJSON(object: EventType): string {
  switch (object) {
    case EventType.GENERAL:
      return "GENERAL";
    case EventType.ROOM_TITLE:
      return "ROOM_TITLE";
    case EventType.SEARCH_RESULT:
      return "SEARCH_RESULT";
    case EventType.LANG_DETECT:
      return "LANG_DETECT";
    case EventType.TRANSLATING:
      return "TRANSLATING";
    case EventType.INQUIRE:
      return "INQUIRE";
    case EventType.SEARCH_FAILED:
      return "SEARCH_FAILED";
    case EventType.FOLLOW_UP:
      return "FOLLOW_UP";
    case EventType.SUGGESTED_QUESTIONS:
      return "SUGGESTED_QUESTIONS";
    case EventType.RESPONSE_DONE:
      return "RESPONSE_DONE";
    case EventType.SIGNAL:
      return "SIGNAL";
    case EventType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface EventData {
  struct?: { [key: string]: any } | undefined;
  searchResults?: SearchResults | undefined;
  map?: MapData | undefined;
  signal?: Signal | undefined;
}

export interface EurekaEvent {
  data: EventData | undefined;
  domainId: string;
  eventType: EventType;
  id: string;
  roomId: string;
  title: string;
  source: ServiceSource;
  timestamp: number;
}

function createBaseEventData(): EventData {
  return { struct: undefined, searchResults: undefined, map: undefined, signal: undefined };
}

export const EventData = {
  encode(message: EventData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.struct !== undefined) {
      Struct.encode(Struct.wrap(message.struct), writer.uint32(10).fork()).ldelim();
    }
    if (message.searchResults !== undefined) {
      SearchResults.encode(message.searchResults, writer.uint32(18).fork()).ldelim();
    }
    if (message.map !== undefined) {
      MapData.encode(message.map, writer.uint32(26).fork()).ldelim();
    }
    if (message.signal !== undefined) {
      writer.uint32(32).int32(message.signal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.struct = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.searchResults = SearchResults.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.map = MapData.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.signal = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EventData {
    return {
      struct: isObject(object.struct) ? object.struct : undefined,
      searchResults: isSet(object.searchResults) ? SearchResults.fromJSON(object.searchResults) : undefined,
      map: isSet(object.map) ? MapData.fromJSON(object.map) : undefined,
      signal: isSet(object.signal) ? signalFromJSON(object.signal) : undefined,
    };
  },

  toJSON(message: EventData): unknown {
    const obj: any = {};
    if (message.struct !== undefined) {
      obj.struct = message.struct;
    }
    if (message.searchResults !== undefined) {
      obj.searchResults = SearchResults.toJSON(message.searchResults);
    }
    if (message.map !== undefined) {
      obj.map = MapData.toJSON(message.map);
    }
    if (message.signal !== undefined) {
      obj.signal = signalToJSON(message.signal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventData>, I>>(base?: I): EventData {
    return EventData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventData>, I>>(object: I): EventData {
    const message = createBaseEventData();
    message.struct = object.struct ?? undefined;
    message.searchResults = (object.searchResults !== undefined && object.searchResults !== null)
      ? SearchResults.fromPartial(object.searchResults)
      : undefined;
    message.map = (object.map !== undefined && object.map !== null) ? MapData.fromPartial(object.map) : undefined;
    message.signal = object.signal ?? undefined;
    return message;
  },
};

function createBaseEurekaEvent(): EurekaEvent {
  return { data: undefined, domainId: "", eventType: 0, id: "", roomId: "", title: "", source: 0, timestamp: 0 };
}

export const EurekaEvent = {
  encode(message: EurekaEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      EventData.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    if (message.domainId !== "") {
      writer.uint32(18).string(message.domainId);
    }
    if (message.eventType !== 0) {
      writer.uint32(24).int32(message.eventType);
    }
    if (message.id !== "") {
      writer.uint32(34).string(message.id);
    }
    if (message.roomId !== "") {
      writer.uint32(42).string(message.roomId);
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.source !== 0) {
      writer.uint32(56).int32(message.source);
    }
    if (message.timestamp !== 0) {
      writer.uint32(64).int64(message.timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EurekaEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEurekaEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = EventData.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.domainId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.eventType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roomId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timestamp = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EurekaEvent {
    return {
      data: isSet(object.data) ? EventData.fromJSON(object.data) : undefined,
      domainId: isSet(object.domainId) ? globalThis.String(object.domainId) : "",
      eventType: isSet(object.eventType) ? eventTypeFromJSON(object.eventType) : 0,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      roomId: isSet(object.roomId) ? globalThis.String(object.roomId) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      source: isSet(object.source) ? serviceSourceFromJSON(object.source) : 0,
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
    };
  },

  toJSON(message: EurekaEvent): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = EventData.toJSON(message.data);
    }
    if (message.domainId !== "") {
      obj.domainId = message.domainId;
    }
    if (message.eventType !== 0) {
      obj.eventType = eventTypeToJSON(message.eventType);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.roomId !== "") {
      obj.roomId = message.roomId;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.source !== 0) {
      obj.source = serviceSourceToJSON(message.source);
    }
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EurekaEvent>, I>>(base?: I): EurekaEvent {
    return EurekaEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EurekaEvent>, I>>(object: I): EurekaEvent {
    const message = createBaseEurekaEvent();
    message.data = (object.data !== undefined && object.data !== null) ? EventData.fromPartial(object.data) : undefined;
    message.domainId = object.domainId ?? "";
    message.eventType = object.eventType ?? 0;
    message.id = object.id ?? "";
    message.roomId = object.roomId ?? "";
    message.title = object.title ?? "";
    message.source = object.source ?? 0;
    message.timestamp = object.timestamp ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
