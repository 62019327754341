import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../app/store";
import { ApiError } from "../../api/eurekaApi";
import { api } from "../../api";
import { setErrorSnackMessage } from "../systemSlice";

export const searchAsync = createAsyncThunk(
  "search/searchAsync",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as AppState;
    const query = state.search.query;
    const filterKey =
      state.search.filterKey !== "" ? state.search.filterKey : undefined;

    let domain: string | undefined = undefined;

    if (state.search.filterDomain) {
      domain = state.search.filterDomain;
    }

    return api.search
      .search({ query, filterKey, domain })
      .then((res) => {
        if (res.data !== undefined) {
          return res.data;
        }
      })
      .catch(async (res) => {
        const err = (await res.json()) as ApiError;
        dispatch(setErrorSnackMessage(err));
        return rejectWithValue(false);
      });
  },
);
