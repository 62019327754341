import React, { useState } from "react";
import { displayFileIconName } from "./IconUtils";
import { Box } from "mds";
import { MinIODocument } from "./ChatMessage.types";
import { extensionPreview } from "./extensionUtils";
import DocumentOptions from "./DocumentOptions";
import { ChatMsg } from "../../protos/gateway";

const SourceMaterial = ({ msg, doc }: { msg: ChatMsg; doc: MinIODocument }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  let objectType = extensionPreview(doc.key);
  // for now
  if (doc.search_results[0].webSourceUrl) {
    objectType = "web";
  }

  const location = (doc.search_results[0].sourceLocation ?? "").split(",");

  const iframeLoaded = () => {};

  return (
    <Box>
      <div
        style={{
          display: "flex",

          gap: 8,
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            "& .min-icon": {
              width: 16,
              marginRight: 8,
            },
          }}
        >
          <div
            onClick={() => {
              if (objectType === "web") {
                window.open(doc.search_results[0].webSourceUrl, "_blank");
              } else if (objectType !== "none") {
                setExpanded(!expanded);
              }
            }}
            style={{
              fontWeight: expanded ? "bold" : "inherit",
              color: "#55585D",
            }}
          >
            {displayFileIconName(doc)}
          </div>
        </Box>
        <div>
          <DocumentOptions
            onPreview={() => objectType !== "none" && setExpanded(!expanded)}
            msg={msg}
            doc={doc}
          />
        </div>
      </div>
      {expanded && (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          {objectType === "audio" && (
            <audio
              style={{
                width: "100%",
                height: "auto",
              }}
              autoPlay={true}
              controls={true}
              muted={false}
              playsInline={true}
              onPlay={iframeLoaded}
            >
              <source
                src={`${doc.search_results[0].preSignedUrl}#t=${location[0]}`}
                type="audio/mpeg"
              />
            </audio>
          )}
          {objectType === "video" && (
            <video
              style={{
                width: "100%",
                height: "auto",
              }}
              autoPlay={true}
              controls={true}
              muted={false}
              playsInline={true}
              onPlay={iframeLoaded}
            >
              <source
                src={`${doc.search_results[0].preSignedUrl}#t=${location[0]}`}
                type="video/mp4"
              />
            </video>
          )}
          {objectType === "pdf" && (
            <div
              style={{
                backgroundColor: "#ffffff",
              }}
            >
              <iframe
                src={doc.search_results[0].preSignedUrl}
                title="File Preview"
                allowTransparency
                style={{
                  border: "0px",
                  flex: "1 1 auto",
                  width: "100%",
                  height: 250,
                  backgroundColor: "transparent",
                  borderRadius: 5,
                }}
                onLoad={iframeLoaded}
              >
                File couldn't be loaded. Please try Download instead
              </iframe>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default SourceMaterial;
