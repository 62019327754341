import React, { Fragment } from "react";
import "./RoomsDrawer.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddIcon, Box, Button } from "mds";
import { openRooms, toggleDrawer } from "./chatSlice";
import { useNavigate, useParams } from "react-router-dom";
import CloseDrawerIcon from "./CloseDrawerIcon";
import { startNewConversationAsync } from "./chatThunks";
import ETextIcon from "./ETextIcon";
import { useListRoomsQuery } from "../../api/eurekaApiRTK";

const RoomsDrawer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { roomID } = useParams();

  const rooms = useAppSelector((state) => state.chat.rooms);
  const roomDrawerOpen = useAppSelector((state) => state.chat.roomDrawerOpen);

  useListRoomsQuery();

  if (!roomDrawerOpen) {
    return <Fragment />;
  }
  return (
    <div
      style={{
        flexGrow: 0,
        width: 350,
        minWidth: 350,
      }}
    >
      <div
        style={{
          position: "fixed",
          width: 350,
          minWidth: 350,
          padding: 8,
          paddingBottom: 300,
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",

            gap: 8,
            "& .min-icon": {
              fill: "#278EAD !important",
            },
          }}
        >
          <Button
            id={"new-room"}
            style={{
              marginBottom: 11,
              paddingLeft: 16,
              paddingRight: 16,
              height: 40,
              width: "100%",
              border: "1px solid #278EAD",
              color: "#278EAD",
              borderRadius: 6,
            }}
            icon={<AddIcon />}
            onClick={async () => {
              dispatch(startNewConversationAsync()).then((ok) => {
                console.log(ok);
              });
              dispatch(openRooms(false));
              navigate("/");
            }}
          >
            Start New Conversation
          </Button>
          <Button
            id={"close-drawer"}
            style={{
              marginBottom: 11,
              paddingLeft: 16,
              paddingRight: 16,
              height: 40,
              width: 40,
              border: "1px solid #278EAD",
              borderRadius: 6,
            }}
            onClick={() => dispatch(toggleDrawer())}
          >
            <CloseDrawerIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            gap: 16,
            marginTop: 8,
            marginBottom: 8,
            fontSize: 12,
            fontWeight: "bold",
            "& .min-icon": {
              height: 8,
            },
            "& .min-icon rect": {
              fill: "black !important",
            },
          }}
        >
          <div>
            <ETextIcon />
          </div>
          <div>PAST CONVERASTIONS</div>
        </Box>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          {rooms.map((room) => (
            <Box
              sx={{
                lineHeight: "32px",
                cursor: "pointer",
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: "#F7F9FC",
                borderRadius: 6,
                "&:hover": {
                  backgroundColor: "#f1f4fc",
                },
                "&.selected": {
                  color: "white",
                  backgroundColor: "#278EAD",
                },
              }}
              className={room.id === roomID ? "selected" : ""}
              onClick={() => {
                navigate(`/chat/${room?.id}`);
                // dispatch(openRooms(false));
              }}
              key={`room-${room.id}`}
            >
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {room.title}
              </div>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomsDrawer;
