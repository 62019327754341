import { SearchResult } from "../../protos/search";

export class MinIODocument {
  key: string;
  search_results: SearchResult[];

  constructor(key: string, search_results: SearchResult[]) {
    this.key = key;
    this.search_results = search_results;
  }

  display(): string {
    let fileName;
    // handle web content case (show URL instead of object file name)
    if (this.search_results[0].webSourceUrl) {
      fileName = this.search_results[0].webSourceUrl;
      fileName = fileName
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "");
      if (fileName.length > 50) {
        fileName = `${fileName.substring(0, 50)}...`;
      }
    } else {
      const part = this.key.split("/");
      fileName = part[part.length - 1];
      if (fileName.length > 50) {
        const parts = fileName.split(".");
        const extension = parts[parts.length - 1];
        fileName = `${fileName.substring(0, 50)}...${extension}`;
      }
    }
    return fileName;
  }
}
