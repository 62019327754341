import React from "react";

const SendPromptIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.155"
      height="23.151"
      viewBox="0 0 23.155 23.151"
    >
      <path
        id="Trazado_9045"
        data-name="Trazado 9045"
        d="M11.337,16.345a1.333,1.333,0,0,1-.588-.136,1.347,1.347,0,0,1-.5-.426L6.512,10.6l3.9-3.9a.439.439,0,1,0-.621-.621L5.915,9.959.562,6.093a1.4,1.4,0,0,1-.286-.274A1.3,1.3,0,0,1,.093,5.5a1.349,1.349,0,0,1-.078-.7A1.387,1.387,0,0,1,.3,4.149a1.309,1.309,0,0,1,.6-.428l-.032,0L14.638.03l0,.02A1.33,1.33,0,0,1,15,0a1.3,1.3,0,0,1,.169.011,1.319,1.319,0,0,1,.489.14,1.38,1.38,0,0,1,.465.387,1.355,1.355,0,0,1,.25.539A1.325,1.325,0,0,1,16.4,1.4l.021,0-.029.108a1.407,1.407,0,0,1-.039.2l-2.345,8.785,0,0-.99,3.564-.39,1.454,0-.036-.027.1-.007-.042a1.31,1.31,0,0,1-.513.6A1.39,1.39,0,0,1,11.337,16.345Z"
        transform="translate(11.147) rotate(43)"
        fill="#c2cede"
      />
    </svg>
  );
};
export default SendPromptIcon;
