import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ListDomains from "./ListDomains";
import DomainDetails from "./DomainDetails";
import AddDomain from "./AddDomain";

const Domains = () => {
  return (
    <Routes>
      <Route path={"/add"} element={<AddDomain />} />
      <Route path={"/:domainID"} element={<DomainDetails />} />
      <Route path={"/"} element={<ListDomains />} />
      <Route path={"*"} element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Domains;
