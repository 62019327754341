import { api } from "api";
import { ApiError, ContentType, Domain, TestPrompt } from "api/eurekaApi";
import {
  Button,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  Loader,
  RefreshIcon,
} from "mds";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteTestPrompt from "./DeleteTestPrompt";
import ESectionTitle from "components/ESectionTitle";
import EButton from "components/EButton";
import FormField from "../FormField";
import PromptStyle from "../../../components/PromptStyle";

const TestPromptDetails = ({ newRecord = false }: { newRecord?: boolean }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState<boolean>(newRecord);
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [delOpen, setDelOpen] = useState<boolean>(false);
  const [record, setRecord] = useState<TestPrompt | null>(
    newRecord ? {} : null,
  );
  const [origRecord, setOrigRecord] = useState<TestPrompt | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const getRecord = useCallback(() => {
    const parts = pathname.split("/");
    api.testPrompts
      .getTestPrompt(parts[parts.length - 1])
      .then((res) => {
        if (res.data) {
          setRecord(res.data);
        }
      })
      .catch((e) => {
        console.warn("Error loading domain", e);
        setError(e.error);
      });
  }, [pathname]);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      if (!newRecord) {
        getRecord();
      }
    }
  }, [iniLoad, getRecord, newRecord]);

  return (
    <Fragment>
      {!record && <Loader />}
      {record && (
        <Fragment>
          {delOpen && (
            <DeleteTestPrompt
              onCancel={() => setDelOpen(false)}
              onDelete={() => {
                const parts = pathname.split("/");
                api.testPrompts
                  .deleteTestPrompt(parts[parts.length - 1], {
                    type: ContentType.Json,
                  })
                  .then((res) => {
                    navigate("/management/test-prompts/");
                  })
                  .catch((e) => {
                    setError(e.error);
                  });
              }}
            />
          )}
          <ESectionTitle
            actions={
              <Fragment>
                {editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    `
                    <Button
                      id={"save-edit-domain"}
                      variant={"callAction"}
                      onClick={() => {
                        const outDom: Domain = { ...record };

                        if (newRecord) {
                          api.testPrompts
                            .addTestPrompt(outDom, { type: ContentType.Json })
                            .then((res) => {
                              navigate("/management/test-prompts/");
                            })
                            .catch(async (res) => {
                              const err = (await res.json()) as ApiError;
                              setError(err);
                            });
                        } else {
                          const parts = pathname.split("/");
                          api.testPrompts
                            .updateTestPrompt(parts[parts.length - 1], outDom, {
                              type: ContentType.Json,
                            })
                            .then((res) => {
                              setEditMode(false);
                              getRecord();
                            })
                            .catch(async (res) => {
                              const err = (await res.json()) as ApiError;
                              setError(err);
                            });
                        }
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      id={"save-edit-domain"}
                      variant={"secondary"}
                      onClick={() => {
                        if (newRecord) {
                          navigate("/management/test-inputs");
                        } else {
                          setRecord(origRecord);
                          setOrigRecord(null);
                          setEditMode(false);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
                {!editMode && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <EButton
                      id={"duplicate-domain"}
                      onClick={() => {
                        const parts = pathname.split("/");
                        api.testPrompts
                          .duplicateTestPrompt(parts[parts.length - 1])
                          .then((res) => {
                            if (res.data) {
                              setIniLoad(false);
                              navigate(
                                `/management/test-prompts/${res.data.id}`,
                              );
                            }
                          })
                          .catch((e) => {
                            console.log(e);
                            setError(e.error);
                          });
                      }}
                      icon={<CopyIcon />}
                    >
                      Copy
                    </EButton>
                    <EButton
                      id={"edit-domain"}
                      onClick={() => {
                        setDelOpen(true);
                      }}
                      icon={<DeleteIcon />}
                    >
                      Delete
                    </EButton>
                    <EButton
                      id={"edit-domain"}
                      onClick={() => {
                        setOrigRecord({ ...record });
                        setEditMode(true);
                      }}
                      icon={<EditIcon />}
                    >
                      Edit
                    </EButton>
                    <EButton
                      id={"refresh"}
                      onClick={() => {
                        getRecord();
                      }}
                      icon={<RefreshIcon />}
                    >
                      Refresh
                    </EButton>
                  </div>
                )}
              </Fragment>
            }
            separator
          >
            Test Prompt
          </ESectionTitle>
          {error && (
            <div
              style={{
                backgroundColor: "#ffa6a6",
                border: "1px solid red",
                borderRadius: 4,
                padding: 8,
                margin: 8,
              }}
            >
              <b>{error.message}</b>
              <p>{error.detailedMessage}</p>
            </div>
          )}
          <div
            style={{
              display: "flex",

              gap: 16,
              marginTop: 16,
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <FormField title={"ID"}>{record.id}</FormField>
            </div>
            <div style={{ width: "50%" }}>
              <FormField title={"Prompt"}>
                {!editMode && (
                  <PromptStyle>
                    {record.input_text ?? "No Template"}
                  </PromptStyle>
                )}
                {editMode && (
                  <textarea
                    cols={60}
                    rows={20}
                    onChange={(e) => {
                      if (record) {
                        setRecord({
                          ...record,
                          input_text: e.target.value,
                        });
                      }
                    }}
                  >
                    {record.input_text}
                  </textarea>
                )}
              </FormField>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
export default TestPromptDetails;
