import React from "react";
import "./Drawer.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDrawerOpen } from "./chatSlice";
import Search from "screens/search/Search";

const ChatSearchDrawer = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.chat.drawerOpen);

  return (
    <div className={`drawer ${open ? "open" : ""}`}>
      <div
        onClick={() => dispatch(setDrawerOpen(false))}
        className={"drawer-empty"}
      ></div>
      <div className={"drawer-content"}>
        <Search lockFileKey={true} />
      </div>
    </div>
  );
};

export default ChatSearchDrawer;
