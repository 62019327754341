/** @format */

import {
  Box,
  Checkbox,
  InputBox,
  Loader,
  ModalBox,
  SectionTitle,
  TierOfflineIcon,
  TierOnlineIcon,
} from "mds";
import React, { Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { crawlAsync } from "./crawlThunks";
import { setCrawlEntireFlag, setMaxPages, setUrl } from "./crawlSlice";
import EButton from "components/EButton";
import ESectionTitle from "components/ESectionTitle";
import FormField from "../FormField";

const CrawlerConfig = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.crawl.status);
  const url = useAppSelector((state) => state.crawl.url);
  const maxPages = useAppSelector((state) => state.crawl.maxPages);
  const crawlFlag = useAppSelector((state) => state.crawl.crawlEntireFlag);
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);

  return (
    <div>
      <Fragment>
        <ESectionTitle
          separator
          sx={{
            fontSize: 20,
          }}
          actions={
            <div style={{ display: "flex", gap: 8 }}>
              <EButton
                id="crawl-button"
                label="Crawl"
                onClick={() => {
                  dispatch(crawlAsync());
                  if (status !== "failed") {
                    setStatusModalOpen(true);
                  }
                }}
              />
            </div>
          }
        >
          Web Crawler
        </ESectionTitle>
        {status === "loading" && (
          <>
            <Loader />
            <SectionTitle icon={<TierOnlineIcon />}>WORKING</SectionTitle>
          </>
        )}
        {status === "failed" && (
          <>
            <SectionTitle icon={<TierOfflineIcon />}>
              Something went wrong.
            </SectionTitle>
          </>
        )}
        {status === "idle" && (
          <SectionTitle icon={<TierOnlineIcon />}>READY</SectionTitle>
        )}
        <div
          style={{
            display: "flex",

            gap: 16,
            marginTop: 16,
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <FormField title={"URL"}>
              <InputBox
                id="url-input"
                required
                value={url}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setUrl(e.target.value));
                }}
              />
            </FormField>
            <FormField title={"Crawl entire website"}>
              <Checkbox
                id="crawl-entire"
                checked={crawlFlag}
                onClick={(t) => {
                  dispatch(setCrawlEntireFlag(!crawlFlag));
                }}
              />
            </FormField>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <FormField title={"Crawl limit (# of pages)"}>
              <InputBox
                id="no-pages"
                value={maxPages}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value =
                    e.target.value === "" ? 0 : parseInt(e.target.value);
                  dispatch(setMaxPages(value));
                }}
              />
            </FormField>
          </Box>
        </div>
        <ModalBox
          onClose={function noRefCheck() {
            setStatusModalOpen(false);
          }}
          title="Crawl Successfully Initiated"
          titleIcon={<TierOnlineIcon />}
          open={statusModalOpen}
        >
          <p>
            Hang tight! Shortly, I will know everything on this website and can
            start answering questions about it.
          </p>
          <div
            style={{
              textAlign: "right",
            }}
          >
            - Eureka
          </div>
        </ModalBox>
      </Fragment>
    </div>
  );
};

export default CrawlerConfig;
