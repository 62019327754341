import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RoomMessagesApiArg } from "./gatewayApiRTK";
import { EventList, MessageList } from "../protos/gateway";

export const eurekaApi = createApi({
  reducerPath: "eurekaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/",
    credentials: "include",
  }),
  endpoints: () => ({}),
});

export const eurekaApiV2 = createApi({
  reducerPath: "eurekaApiV2",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    credentials: "include",
  }),
  endpoints: (build) => ({
    roomMessages: build.query<MessageList, RoomMessagesApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/room/${queryArg.id}/messages`,
      }),
      transformResponse: (rawResult: object, meta, args): MessageList => {
        return MessageList.fromJSON(rawResult);
      },
    }),
    roomEvents: build.query<EventList, RoomMessagesApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/room/${queryArg.id}/events`,
      }),
      transformResponse: (rawResult: object, meta, args): EventList => {
        return EventList.fromJSON(rawResult);
      },
    }),
  }),
});
