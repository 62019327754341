import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CrawlerConfig from "./CrawlerConfig";

const Crawler = () => {
  return (
    <Routes>
      <Route path={"/"} element={<CrawlerConfig />} />
      <Route path={"*"} element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Crawler;
