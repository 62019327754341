import React from "react";

const PromptStyle = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div
      style={{
        border: "1px solid #CBD8EF",
        borderRadius: 6,
        backgroundColor: "#F7F9FC",
        padding: 16,
        font: "normal normal normal 14px/28px Courier New",
      }}
    >
      <pre
        style={{
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
      >
        {children}
      </pre>
    </div>
  );
};
export default PromptStyle;
