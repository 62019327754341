import React from "react";
import { Box, IconButton } from "mds";
import { IconButtonProps } from "mds/dist/esm/types/components/IconButton/IconButton.types";

const STIconButton: React.FC<IconButtonProps> = ({ ...props }) => (
  <Box
    sx={{
      "& button": {
        backgroundColor: "unset !important",
        "& .min-icon": {
          fill: "#c5cddb",
          color: "#c5cddb",
        },
      },
    }}
  >
    <IconButton {...props} />
  </Box>
);
export default STIconButton;
