import { Box } from "mds";
import React, { Fragment, useState } from "react";
import { useAppSelector } from "../../app/hooks";

const SearchResults = () => {
  const currentDomain = useAppSelector((state) => {
    if (state.search.filterDomain) {
      return state.search.filterDomain;
    }
    return state.system.selectedDomain;
  });

  const domain = useAppSelector((state) => {
    if (state.system.selectedDomain) {
      for (let i = 0; i < state.system.domains.length; i++) {
        if (state.system.domains[i].id === state.system.selectedDomain) {
          return state.system.domains[i];
        }
      }
    }
  });

  const results = useAppSelector((state) => {
    if (
      currentDomain &&
      state.search.results &&
      state.search.results.domains &&
      state.search.results.domains.hasOwnProperty(currentDomain)
    ) {
      return state.search.results.domains[currentDomain].results;
    }
  });
  const filterKey = useAppSelector((state) => state.search.filterKey);

  const [expanded, setExpanded] = useState<boolean>(false);

  if (results === null) {
    return <Fragment>No Results</Fragment>;
  }

  const chunkNumber = (chunkKey: string) => {
    const parts = chunkKey.split("/");
    const lastPart = parts[parts.length - 1]
      .split(".")[0]
      .replace("chunk_", "");
    return lastPart;
  };

  return (
    <div
      style={{
        marginTop: 32,
        width: "100%",
        maxWidth: 700,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 64,
        fontSize: 16,
      }}
    >
      {results &&
        results.map((result, index) => (
          <Box
            sx={{
              "& .result-box:hover": {
                backgroundColor: "#eeeeee",
              },
              color:
                domain && result.score! >= domain.match_threshold!
                  ? "#999999"
                  : "inherit",
              cursor: "pointer",
            }}
            onClick={() => setExpanded(!expanded)}
          >
            <div
              style={{ paddingLeft: 16, paddingRight: 16 }}
              className={"result-box"}
            >
              {filterKey === "" && (
                <Fragment>
                  <div>
                    <b>Object: </b>
                    {result.key ?? "n/a"}
                  </div>
                  <div>
                    <b>Score: </b>
                    {result.score ? `${result.score}` : "n/a"}
                  </div>
                </Fragment>
              )}
              <div>
                <b>Chunk: </b>
                <span style={{ fontSize: 11, color: "#999999" }}>
                  {result.source_chunk
                    ? `${chunkNumber(result.source_chunk)}`
                    : "n/a"}
                </span>
              </div>
              {expanded && (
                <Fragment>
                  <div>
                    <b>Chunk Key: </b>
                    <span style={{ fontSize: 11, color: "#999999" }}>
                      {result.source_chunk ? `${result.source_chunk}` : "n/a"}
                    </span>
                  </div>
                  <div>
                    <b>Score: </b>
                    <span style={{ fontSize: 11, color: "#999999" }}>
                      {result.score ? `${result.score}` : "n/a"}
                    </span>
                  </div>
                </Fragment>
              )}

              <div>
                {result.linked_image_url ? (
                  <>
                    <img
                      style={{ width: "100%" }}
                      src={result.linked_image_url}
                      alt="Relevant figure from document."
                    />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "10px",
                      }}
                      sx={{
                        "& div:hover": { fontWeight: "bold" },
                      }}
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(result.linked_data_url, "_blank");
                        }}
                      >
                        <strong>Download Data</strong>
                      </div>
                    </Box>
                  </>
                ) : (
                  <span></span>
                )}
              </div>

              <div>{result.text ?? "n/a"}</div>
            </div>
          </Box>
        ))}
    </div>
  );
};
export default SearchResults;
