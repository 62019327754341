import React, { Fragment } from "react";
import { useAppSelector } from "app/hooks";

const Questions = () => {
  const questions = useAppSelector((state) => state.questions.questions);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 720,
          margin: "auto",
          paddingLeft: 16,
          paddingRight: 16,
        }}
        id={"scrollableDiv"}
      >
        {!questions && <div>No Questions for this document</div>}
        {questions && (
          <Fragment>
            <h1>Auto Generated Questions</h1>
            {questions.map((q) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 4,
                }}
                key={q.text}
              >
                <div>{q.text}</div>
                <div style={{ fontSize: 11, color: "#dedede" }}>{q.chunk}</div>
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Questions;
