import React from "react";
import { ApiError } from "../api/eurekaApi";

const ErrorDisplay = ({ err }: { err: ApiError }) => {
  return (
    <div
      style={{
        backgroundColor: "#ffa6a6",
        border: "1px solid red",
        borderRadius: 4,
        padding: 8,
        margin: 8,
      }}
    >
      <b>{err.message}</b>
      {err.detailedMessage !== "" && <p>{err.detailedMessage}</p>}
    </div>
  );
};
export default ErrorDisplay;
