// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: signal.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ServiceSource, serviceSourceFromJSON, serviceSourceToJSON } from "./min-common";

export const protobufPackage = "min";

export enum Signal {
  NONE = 0,
  SPEECH_STARTED = 1,
  SPEECH_DONE = 2,
  STOP_REQUEST = 3,
  SPEECH_CANCELLED = 4,
  SESSION_TERMINATED = 5,
  THINKING = 6,
  IDLE = 7,
  SPEECH_SUBMITTED = 8,
  UNRECOGNIZED = -1,
}

export function signalFromJSON(object: any): Signal {
  switch (object) {
    case 0:
    case "NONE":
      return Signal.NONE;
    case 1:
    case "SPEECH_STARTED":
      return Signal.SPEECH_STARTED;
    case 2:
    case "SPEECH_DONE":
      return Signal.SPEECH_DONE;
    case 3:
    case "STOP_REQUEST":
      return Signal.STOP_REQUEST;
    case 4:
    case "SPEECH_CANCELLED":
      return Signal.SPEECH_CANCELLED;
    case 5:
    case "SESSION_TERMINATED":
      return Signal.SESSION_TERMINATED;
    case 6:
    case "THINKING":
      return Signal.THINKING;
    case 7:
    case "IDLE":
      return Signal.IDLE;
    case 8:
    case "SPEECH_SUBMITTED":
      return Signal.SPEECH_SUBMITTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Signal.UNRECOGNIZED;
  }
}

export function signalToJSON(object: Signal): string {
  switch (object) {
    case Signal.NONE:
      return "NONE";
    case Signal.SPEECH_STARTED:
      return "SPEECH_STARTED";
    case Signal.SPEECH_DONE:
      return "SPEECH_DONE";
    case Signal.STOP_REQUEST:
      return "STOP_REQUEST";
    case Signal.SPEECH_CANCELLED:
      return "SPEECH_CANCELLED";
    case Signal.SESSION_TERMINATED:
      return "SESSION_TERMINATED";
    case Signal.THINKING:
      return "THINKING";
    case Signal.IDLE:
      return "IDLE";
    case Signal.SPEECH_SUBMITTED:
      return "SPEECH_SUBMITTED";
    case Signal.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SignalMessage {
  signal: Signal;
  source: ServiceSource;
}

function createBaseSignalMessage(): SignalMessage {
  return { signal: 0, source: 0 };
}

export const SignalMessage = {
  encode(message: SignalMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signal !== 0) {
      writer.uint32(8).int32(message.signal);
    }
    if (message.source !== 0) {
      writer.uint32(16).int32(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignalMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignalMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.signal = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignalMessage {
    return {
      signal: isSet(object.signal) ? signalFromJSON(object.signal) : 0,
      source: isSet(object.source) ? serviceSourceFromJSON(object.source) : 0,
    };
  },

  toJSON(message: SignalMessage): unknown {
    const obj: any = {};
    if (message.signal !== 0) {
      obj.signal = signalToJSON(message.signal);
    }
    if (message.source !== 0) {
      obj.source = serviceSourceToJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SignalMessage>, I>>(base?: I): SignalMessage {
    return SignalMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SignalMessage>, I>>(object: I): SignalMessage {
    const message = createBaseSignalMessage();
    message.signal = object.signal ?? 0;
    message.source = object.source ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
