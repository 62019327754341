import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";
import { setErrorSnackMessage } from "screens/systemSlice";
import { setRoomIdle, setRoomType } from "../chatSlice";
import {
  setNavigateTo,
  setPrompt,
  setSemanticSuggestions,
  setShowSuggestions,
  setSuggestions,
} from "./textPromptSlice";
import { ApiError } from "../../../api/eurekaApi";
import {
  ChatMsg,
  ChatMsg_ChatMsgFrom,
  WsMessage,
} from "../../../protos/gateway";
import { RoomTypes } from "../chatSlice.types";

export interface SubmitRequest {
  inPrompt: string;
  roomID: string | undefined;
}

export const submitPrompt = createAsyncThunk(
  "textPrompt/submitPrompt",
  async (opts: SubmitRequest, { getState, rejectWithValue, dispatch }) => {
    console.log("submitPrompt thunk");
    if (opts.inPrompt === "") {
      return;
    }
    dispatch(setShowSuggestions(false));
    dispatch(setSuggestions([]));
    dispatch(setSemanticSuggestions([]));
    let roomId = opts.roomID;
    if (!roomId) {
      let room = await api.room
        .createRoom()
        .then((res) => {
          if (res.data !== undefined) {
            return res.data;
          }
          return null;
        })
        .catch(async (res) => {
          const err = (await res.json()) as ApiError;
          dispatch(setErrorSnackMessage(err));
          return null;
        });
      if (room && room.id) {
        roomId = room?.id;
        dispatch(setRoomIdle(roomId));
        dispatch(setNavigateTo(`/chat/${room?.id}`));
      }
    }

    let msg: ChatMsg = {
      roomId: roomId!,
      text: opts.inPrompt,
      from: ChatMsg_ChatMsgFrom.USER,
      id: "",
      sysCreatedDate: 0,
    };
    let wsMsg: WsMessage = {
      chatMsg: msg,
    };
    console.log("submitting message", wsMsg);
    dispatch({ type: "socket/postMessage", payload: wsMsg });
    dispatch(setPrompt(""));
  },
);

export interface RoomRequest {
  roomID?: string;
  roomType: RoomTypes;
}

export const newTypedRoom = createAsyncThunk(
  "textPrompt/newTypedRoom",
  async (opts: RoomRequest, { getState, rejectWithValue, dispatch }) => {
    console.log("newTypedRoom thunk");

    dispatch(setShowSuggestions(false));
    dispatch(setSuggestions([]));
    dispatch(setSemanticSuggestions([]));
    let roomId = opts.roomID;
    if (!roomId) {
      let room = await api.room
        .createRoom()
        .then((res) => {
          if (res.data !== undefined) {
            return res.data;
          }
          return null;
        })
        .catch(async (res) => {
          const err = (await res.json()) as ApiError;
          dispatch(setErrorSnackMessage(err));
          return null;
        });
      if (room && room.id) {
        roomId = room?.id;
        dispatch(setRoomIdle(roomId));
        dispatch(setRoomType({ roomId: roomId, roomType: opts.roomType }));
        dispatch(setNavigateTo(`/chat/${room?.id}`));
      }
    }
  },
);
