import React, { Fragment, useState } from "react";
import { Box, DocumentationIcon, IconButton, ModalBox } from "mds";
import {
  EurekaEvent,
  EventData,
  EventType,
  eventTypeToJSON,
} from "../../protos/events";

const EventItem = ({ event }: { event: EurekaEvent }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  let expendedItem;

  if (expanded) {
    switch (event.eventType) {
      case EventType.INQUIRE:
        expendedItem = (
          <Fragment>
            <b>Results:</b> {event.data?.struct?.data["using_results"]}
            <br />
            <b>Prompt:</b>
            <br />
            <pre>
              {event.data?.struct?.data["llm_prompt"].replace("\\n", "\n")}
            </pre>
          </Fragment>
        );
        break;
      case EventType.FOLLOW_UP:
        expendedItem = (
          <Fragment>
            <b>Question:</b> {event.data?.map?.data["question"]}
            <br />
            <b>Extracted Info:</b>
            <br />
            <p>
              {event.data?.map?.data["extracted_info"].replace("\\n", "\n")}
            </p>
          </Fragment>
        );
        break;
      case EventType.TRANSLATING:
        expendedItem = (
          <Fragment>
            <b>Generated Text:</b>
            <br />
            <pre>
              {event.data?.map?.data["generated_text"].replace("\\n", "\n")}
            </pre>
            <br />
            <b>Prompt:</b>
            <br />
            <pre>
              {event.data?.map?.data["llm_prompt"].replace("\\n", "\n")}
            </pre>
          </Fragment>
        );
        break;
      default:
        expendedItem = (
          <Fragment>
            <b>Type: </b>
            {eventTypeToJSON(event.eventType)}
            <br />
            <pre>
              {event.data &&
                JSON.stringify(EventData.toJSON(event.data), null, 2)}
            </pre>
          </Fragment>
        );
    }
  }

  return (
    <Box
      sx={{
        "& :hover": {
          backgroundColor: "#eeeeee",
          cursor: event.data ? "pointer" : "inherit",
        },
      }}
    >
      <div
        style={{
          border: "1px solid #dedede",
          borderTop: "0px  solid #dedede",
          borderLeft: "0px  solid #dedede",
          padding: 2,
          paddingLeft: 8,
        }}
        onClick={() => {
          if (event.data) {
            setExpanded(!expanded);
          }
        }}
      >
        <div>{event.title}</div>
      </div>
      {expanded && (
        <div style={{ overflowY: "scroll" }}>
          <div
            style={{
              float: "right",
              margin: 4,
            }}
          >
            <IconButton onClick={() => setOpen(true)}>
              <DocumentationIcon />
            </IconButton>
          </div>
          {expendedItem}
          <ModalBox
            open={open}
            onClose={() => setOpen(false)}
            title={event.title}
          >
            {expendedItem}
          </ModalBox>
        </div>
      )}
    </Box>
  );
};
export default EventItem;
