import { eurekaApiV2 as api } from "./eurekaApiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    roomEvents: build.query<RoomEventsApiResponse, RoomEventsApiArg>({
      query: (queryArg) => ({ url: `/api/v2/room/${queryArg.id}/events` }),
    }),
    roomMessages: build.query<RoomMessagesApiResponse, RoomMessagesApiArg>({
      query: (queryArg) => ({ url: `/api/v2/room/${queryArg.id}/messages` }),
    }),
    emitSignal: build.mutation<EmitSignalApiResponse, EmitSignalApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/signal`,
        method: "POST",
        body: queryArg.minRoomSignal,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as gatewayApiRTK };
export type RoomEventsApiResponse =
  /** status 200 A successful response. */ MinEventList;
export type RoomEventsApiArg = {
  id: string;
};
export type RoomMessagesApiResponse =
  /** status 200 A successful response. */ MinMessageList;
export type RoomMessagesApiArg = {
  id: string;
};
export type EmitSignalApiResponse =
  /** status 200 A successful response. */ MinEmpty;
export type EmitSignalApiArg = {
  minRoomSignal: MinRoomSignal;
};
export type MinSearchResult = {
  bucket?: string;
  category?: string;
  dataSource?: string;
  key?: string;
  linkedDataUrl?: string;
  linkedImageUrl?: string;
  preSignedUrl?: string;
  score?: number;
  sourceChunk?: string;
  sourceLocation?: string;
  text?: string;
  webSourceUrl?: string;
};
export type MinSearchResults = {
  results?: MinSearchResult[];
};
export type MinMapData = {
  data?: {
    [key: string]: string;
  };
};
export type MinEventData = {
  struct?: object;
  searchResults?: MinSearchResults;
  map?: MinMapData;
};
export type MinEventType =
  | "GENERAL"
  | "ROOM_TITLE"
  | "SEARCH_RESULT"
  | "LANG_DETECT"
  | "TRANSLATING"
  | "INQUIRE"
  | "SEARCH_FAILED"
  | "FOLLOW_UP"
  | "SUGGESTED_QUESTIONS"
  | "RESPONSE_DONE";
export type MinEventSource =
  | "UNKNOWN"
  | "GATEWAY"
  | "LPU"
  | "STT"
  | "TTS"
  | "VAD"
  | "ANIMATION";
export type MinEurekaEvent = {
  data?: MinEventData;
  domainId?: string;
  eventType?: MinEventType;
  id?: string;
  roomId?: string;
  title?: string;
  source?: MinEventSource;
};
export type MinEventList = {
  events?: MinEurekaEvent[];
  roomId?: string;
};
export type ProtobufAny = {
  "@type"?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type ChatMsgChatMsgFrom = "USER" | "SYSTEM";
export type MessageMetadataSubType =
  | "UNKNOWN"
  | "ERROR"
  | "THINKING"
  | "SUPPLEMENTING"
  | "DONE";
export type ChatMsgMessageMetadata = {
  extractedInfo?: string;
  isFollowUpQuestion?: string;
  prompt?: string;
  rephraseFollowUpQuestion?: string;
  searchResults?: MinSearchResult[];
  subType?: MessageMetadataSubType;
  suggestedQuestions?: string[];
};
export type MinChatMsg = {
  domain?: string;
  from?: ChatMsgChatMsgFrom;
  id?: string;
  metadata?: ChatMsgMessageMetadata;
  roomId?: string;
  sysCreatedDate?: string;
  text?: string;
};
export type MinMessageList = {
  messages?: MinChatMsg[];
  roomId?: string;
};
export type MinEmpty = object;
export type MinSignal =
  | "NONE"
  | "SPEECH_STARTED"
  | "SPEECH_DONE"
  | "STOP_REQUEST"
  | "SPEECH_CANCELLED"
  | "SESSION_TERMINATED"
  | "THINKING"
  | "IDLE";
export type MinRoomSignal = {
  signal?: MinSignal;
  roomId?: string;
};
export const {
  useRoomEventsQuery,
  useRoomMessagesQuery,
  useEmitSignalMutation,
} = injectedRtkApi;
