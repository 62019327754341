import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ListCollections from "./ListCollections";
import CollectionDetails from "./CollectionDetails";
import AddCollection from "./AddCollection";

const Collections = () => {
  return (
    <Routes>
      <Route path={"/add"} element={<AddCollection />} />
      <Route path={"/:collectionID"} element={<CollectionDetails />} />
      <Route path={"/"} element={<ListCollections />} />
      <Route path={"*"} element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Collections;
