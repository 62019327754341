import { Switch } from "mds";
import React__default from "react";
import { SwitchProps } from "mds/dist/esm/types/components/Switch/Switch.types";

const ESwitch: React.FC<
  SwitchProps & React__default.InputHTMLAttributes<HTMLInputElement>
> = ({ ...props }) => (
  <Switch
    sx={{
      "& input:checked ~ .switchRail": {
        backgroundColor: "#278EAD",
      },
    }}
    {...props}
  />
);

export default ESwitch;
