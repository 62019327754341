import { api } from "api";
import React, { Fragment, useEffect, useState } from "react";
import { QueueCounts } from "../../api/eurekaApi";
import { Loader, RefreshIcon } from "mds";
import ESectionTitle from "../../components/ESectionTitle";
import EButton from "components/EButton";

const Queues = () => {
  const [initLoad, setIniLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [counts, setCounts] = useState<QueueCounts | null>(null);

  useEffect(() => {
    if (!initLoad) {
      setIniLoad(true);
      setLoading(true);
      api.queues.queueTotal({}).then((response) => {
        setCounts(response.data);
        setLoading(false);
      });
    }
  }, [initLoad]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <ESectionTitle
          actions={
            <EButton
              id={"refresh"}
              onClick={() => setIniLoad(false)}
              icon={<RefreshIcon />}
            >
              Refresh
            </EButton>
          }
        >
          Queues
        </ESectionTitle>
      </div>
      <div
        style={{
          display: "flex",

          width: "100%",
          justifyContent: "center",
          alignItems: "start",
          flexGrow: 1,
        }}
      >
        <div style={{ maxWidth: 420, width: "100%" }}>
          {counts && (
            <Fragment>
              {!counts.queues && <div>No items in the queue</div>}
              {counts.queues && (
                <table
                  style={{
                    width: "100%",
                  }}
                >
                  {Object.keys(counts.queues!).map((key) => (
                    <Fragment>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ borderBottom: "1px solid #dedede" }}
                        >
                          <b>{key}</b>
                        </td>
                      </tr>
                      {Object.keys(counts.queues![key]).map((m) => (
                        <tr>
                          <td
                            style={{
                              borderBottom: "1px solid #dedede",
                              borderRight: "1px solid #dedede",
                            }}
                          >
                            {m}
                          </td>
                          <td style={{ borderBottom: "1px solid #dedede" }}>
                            {counts.queues![key][m].toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </table>
              )}
              {loading && <Loader />}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default Queues;
