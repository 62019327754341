import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Chat from "screens/chat/Chat";
import TestComponent from "../test/TestComponent";
import Management from "../management/Management";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={"/chat/:roomID"} element={<Chat />} />
      <Route path={"/chat"} element={<Chat />} />
      <Route path={"/management/*"} element={<Management />} />
      <Route path={"/test"} element={<TestComponent />} />
      <Route path={"*"} element={<Navigate to="/chat" replace />} />
    </Routes>
  );
};

export default AppRoutes;
