// src/middleware/webrtcMiddleware.ts
import { Middleware } from "@reduxjs/toolkit";
import { webrtcManager } from "../WebRTCManager";
import { AppState } from "../app/store";

const WEBSOCKET_CONNECT = "webrtc/connect";
const WEBSOCKET_CONNECT_DONE = "webrtc/startDone";
const WEBSOCKET_DISCONNECT = "webrtc/disconnect";
const WEBRTC_OFFER = "webrtc/offer";
const WEBRTC_ANSWER = "webrtc/answer";
const WEBRTC_ICE_CANDIDATE = "webrtc/candidate";

interface WebsocketConnectAction {
  type: typeof WEBSOCKET_CONNECT;
  roomID: string;
}

interface WebsocketConnectDoneAction {
  type: typeof WEBSOCKET_CONNECT_DONE;
  roomID: string;
}

interface WebsocketDisconnectAction {
  type: typeof WEBSOCKET_DISCONNECT;
  roomID: string;
}

interface WebRTCAnswerAction {
  type: typeof WEBRTC_ANSWER;
  payload: RTCSessionDescriptionInit;
}

interface WebRTCOfferAction {
  type: typeof WEBRTC_OFFER;
  payload: RTCSessionDescriptionInit;
}

interface WebRTCIceCandidateAction {
  type: typeof WEBRTC_ICE_CANDIDATE;
  payload: RTCIceCandidate;
}

type WebRTCAction =
  | WebsocketConnectAction
  | WebsocketConnectDoneAction
  | WebsocketDisconnectAction
  | WebRTCAnswerAction
  | WebRTCOfferAction
  | WebRTCIceCandidateAction;

export const webrtcMiddleware = (): Middleware<{}, AppState> => {
  return (storeApi) => (next) => async (action) => {
    // const dispatch = storeApi.dispatch;
    const castedAction = action as WebRTCAction;

    if (castedAction) {
      if (castedAction.type === "webrtc/connect") {
        await webrtcManager.startPeerConnection(castedAction.roomID);
      } else if (castedAction.type === "webrtc/startDone") {
        await webrtcManager.startWebRTCConnection();
      } else if (castedAction.type === "webrtc/disconnect") {
        webrtcManager.closeConnection();
      } else if (castedAction.type === "webrtc/offer") {
        webrtcManager.handleOffer(castedAction.payload);
      } else if (castedAction.type === "webrtc/answer") {
        webrtcManager.handleAnswer(castedAction.payload);
      } else if (castedAction.type === "webrtc/candidate") {
        webrtcManager.handleCandidate(castedAction.payload);
      }
    }
    return next(action);
  };
};

export default webrtcMiddleware;
