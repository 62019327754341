import React, { useEffect, useState } from "react";
import { ApiError, Domain, Report } from "../../../api/eurekaApi";
import { api } from "../../../api";
import { AddIcon, RefreshIcon } from "mds";
import { useNavigate } from "react-router-dom";
import AddReport from "./AddReport";
import EButton from "components/EButton";
import ESectionTitle from "components/ESectionTitle";
import EDataTable from "components/EDataTable";

const ListReports = () => {
  const navigate = useNavigate();
  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [results, setResults] = useState<Report[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  useEffect(() => {
    if (!iniLoad) {
      setIniLoad(true);
      api.reports
        .listReports()
        .then((res) => {
          if (res.data && res.data.results) {
            setResults(res.data.results);
          }
        })
        .catch(async (res) => {
          const err = (await res.json()) as ApiError;
          setError(err);
        });
    }
  }, [iniLoad]);

  return (
    <div>
      {addOpen && (
        <AddReport
          onCancel={() => setAddOpen(false)}
          onDone={() => {
            setAddOpen(false);
            setIniLoad(false);
          }}
        />
      )}
      <ESectionTitle
        actions={
          <div style={{ display: "flex", gap: 8 }}>
            <EButton
              id={"add-test-prompts"}
              onClick={() => setAddOpen(true)}
              icon={<AddIcon />}
            >
              Add
            </EButton>
            <EButton
              id={"refresh-reports"}
              onClick={() => setIniLoad(false)}
              icon={<RefreshIcon />}
            >
              Refresh
            </EButton>
          </div>
        }
      >
        Reports
      </ESectionTitle>
      {error && (
        <div
          style={{
            backgroundColor: "#ffa6a6",
            border: "1px solid red",
            borderRadius: 4,
            padding: 8,
            margin: 8,
          }}
        >
          <b>{error.message}</b>
          <p>{error.detailedMessage}</p>
        </div>
      )}
      <EDataTable
        itemActions={[
          {
            type: "view",
            onClick: (item: Domain) => {
              navigate(`/management/reports/${item.id}`);
            },
          },
        ]}
        columns={[
          {
            label: "Name",
            elementKey: "name",
          },
          {
            label: "Total Tests",
            elementKey: "count",
            renderFullObject: true,
            renderFunction: (i: Report) => i.counts?.tests,
            width: 100,
          },
          {
            label: "Positives",
            elementKey: "count",
            renderFullObject: true,
            renderFunction: (i: Report) => i.counts?.goods,
            width: 100,
          },
          {
            label: "Negatives",
            elementKey: "count",
            renderFullObject: true,
            renderFunction: (i: Report) => i.counts?.bads,
            width: 100,
          },
          {
            label: "Executed",
            elementKey: "count",
            renderFullObject: true,
            renderFunction: (i: Report) => i.counts?.completed,
            width: 100,
          },
        ]}
        entityName="Test Inputs"
        records={results}
      />
    </div>
  );
};
export default ListReports;
