import React, { useEffect, useState } from "react";
import "./TextTyper.css";

const TextTyper = ({
  text,
  duration = 800,
}: {
  text: string;
  duration?: number;
}) => {
  const [hasDisplayed, setHasDisplayed] = useState<boolean>(false);
  const [displayedText, setDisplayedText] = useState<string>("");

  useEffect(() => {
    if (!hasDisplayed) {
      let i = 0;
      const typing = setInterval(() => {
        if (i > text.length) {
          clearInterval(typing);
          setHasDisplayed(true);
          return;
        }
        setDisplayedText(text.substring(0, i));
        i++;
      }, duration / text.length);

      return () => clearInterval(typing);
    }
  }, [text, hasDisplayed, duration]);

  return (
    <span className={`text-typer ${hasDisplayed ? "done-typing" : ""}`}>
      {displayedText}
      <span className="cursor" />
    </span>
  );
};

export default TextTyper;
