/** @format */

import React from "react";
import "./Chat.scss";
import MessageList from "./MessageList";
import RoomsDrawer from "./RoomsDrawer";
import DevMenu from "./DevMenu";

const Chat = () => {
  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      <RoomsDrawer />
      <MessageList />
      <DevMenu />
    </div>
  );
};
export default Chat;
