import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../../app/store";
import { crawlAsync } from "./crawlThunks";

export interface CrawlState {
  status: "idle" | "loading" | "failed";
  url: string;
  crawlEntireFlag: boolean;
  maxPages: number;
}

const initialState: CrawlState = {
  status: "idle",
  url: "",
  crawlEntireFlag: false,
  maxPages: 1,
};

export const crawlSlice = createSlice({
  name: "crawl",
  initialState,
  reducers: {
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setCrawlEntireFlag: (state, action: PayloadAction<boolean>) => {
      state.crawlEntireFlag = action.payload;
    },
    setMaxPages: (state, action: PayloadAction<number>) => {
      state.maxPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(crawlAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(crawlAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(crawlAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

// Reducer actions
export const { setUrl, setCrawlEntireFlag, setMaxPages } = crawlSlice.actions;

// Reducer selectors
export const sidebarOpen = (state: AppState) => state.system.sidebarOpen;

export default crawlSlice.reducer;
