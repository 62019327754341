// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: search.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "min";

export interface SearchResult {
  bucket: string;
  category: string;
  dataSource: string;
  /** Object Key including the bucket where the result is located */
  key: string;
  /** Temporary URL to access linked actual, related data (if applicable) */
  linkedDataUrl: string;
  /** Temporary URL to access linked image (if applicable) */
  linkedImageUrl: string;
  /** Temporary URL to access the document */
  preSignedUrl: string;
  score: number;
  sourceChunk: string;
  sourceLocation: string;
  text: string;
  /** Source webpage URL for content that was scraped from the web (only applicable for Webpage category) */
  webSourceUrl: string;
}

export interface SearchResults {
  results: SearchResult[];
}

function createBaseSearchResult(): SearchResult {
  return {
    bucket: "",
    category: "",
    dataSource: "",
    key: "",
    linkedDataUrl: "",
    linkedImageUrl: "",
    preSignedUrl: "",
    score: 0,
    sourceChunk: "",
    sourceLocation: "",
    text: "",
    webSourceUrl: "",
  };
}

export const SearchResult = {
  encode(message: SearchResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bucket !== "") {
      writer.uint32(10).string(message.bucket);
    }
    if (message.category !== "") {
      writer.uint32(18).string(message.category);
    }
    if (message.dataSource !== "") {
      writer.uint32(26).string(message.dataSource);
    }
    if (message.key !== "") {
      writer.uint32(34).string(message.key);
    }
    if (message.linkedDataUrl !== "") {
      writer.uint32(42).string(message.linkedDataUrl);
    }
    if (message.linkedImageUrl !== "") {
      writer.uint32(50).string(message.linkedImageUrl);
    }
    if (message.preSignedUrl !== "") {
      writer.uint32(58).string(message.preSignedUrl);
    }
    if (message.score !== 0) {
      writer.uint32(69).float(message.score);
    }
    if (message.sourceChunk !== "") {
      writer.uint32(74).string(message.sourceChunk);
    }
    if (message.sourceLocation !== "") {
      writer.uint32(82).string(message.sourceLocation);
    }
    if (message.text !== "") {
      writer.uint32(90).string(message.text);
    }
    if (message.webSourceUrl !== "") {
      writer.uint32(98).string(message.webSourceUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bucket = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.category = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dataSource = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.key = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.linkedDataUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.linkedImageUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.preSignedUrl = reader.string();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.score = reader.float();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sourceChunk = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sourceLocation = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.text = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.webSourceUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchResult {
    return {
      bucket: isSet(object.bucket) ? globalThis.String(object.bucket) : "",
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      dataSource: isSet(object.dataSource) ? globalThis.String(object.dataSource) : "",
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      linkedDataUrl: isSet(object.linkedDataUrl) ? globalThis.String(object.linkedDataUrl) : "",
      linkedImageUrl: isSet(object.linkedImageUrl) ? globalThis.String(object.linkedImageUrl) : "",
      preSignedUrl: isSet(object.preSignedUrl) ? globalThis.String(object.preSignedUrl) : "",
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
      sourceChunk: isSet(object.sourceChunk) ? globalThis.String(object.sourceChunk) : "",
      sourceLocation: isSet(object.sourceLocation) ? globalThis.String(object.sourceLocation) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      webSourceUrl: isSet(object.webSourceUrl) ? globalThis.String(object.webSourceUrl) : "",
    };
  },

  toJSON(message: SearchResult): unknown {
    const obj: any = {};
    if (message.bucket !== "") {
      obj.bucket = message.bucket;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.dataSource !== "") {
      obj.dataSource = message.dataSource;
    }
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.linkedDataUrl !== "") {
      obj.linkedDataUrl = message.linkedDataUrl;
    }
    if (message.linkedImageUrl !== "") {
      obj.linkedImageUrl = message.linkedImageUrl;
    }
    if (message.preSignedUrl !== "") {
      obj.preSignedUrl = message.preSignedUrl;
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    if (message.sourceChunk !== "") {
      obj.sourceChunk = message.sourceChunk;
    }
    if (message.sourceLocation !== "") {
      obj.sourceLocation = message.sourceLocation;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.webSourceUrl !== "") {
      obj.webSourceUrl = message.webSourceUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchResult>, I>>(base?: I): SearchResult {
    return SearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchResult>, I>>(object: I): SearchResult {
    const message = createBaseSearchResult();
    message.bucket = object.bucket ?? "";
    message.category = object.category ?? "";
    message.dataSource = object.dataSource ?? "";
    message.key = object.key ?? "";
    message.linkedDataUrl = object.linkedDataUrl ?? "";
    message.linkedImageUrl = object.linkedImageUrl ?? "";
    message.preSignedUrl = object.preSignedUrl ?? "";
    message.score = object.score ?? 0;
    message.sourceChunk = object.sourceChunk ?? "";
    message.sourceLocation = object.sourceLocation ?? "";
    message.text = object.text ?? "";
    message.webSourceUrl = object.webSourceUrl ?? "";
    return message;
  },
};

function createBaseSearchResults(): SearchResults {
  return { results: [] };
}

export const SearchResults = {
  encode(message: SearchResults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.results) {
      SearchResult.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchResults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchResults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.results.push(SearchResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchResults {
    return {
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => SearchResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SearchResults): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => SearchResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchResults>, I>>(base?: I): SearchResults {
    return SearchResults.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchResults>, I>>(object: I): SearchResults {
    const message = createBaseSearchResults();
    message.results = object.results?.map((e) => SearchResult.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
