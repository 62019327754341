import { AppState } from "app/store";
import { Middleware } from "redux";
import { wsProtocol } from "../utils/wsUtils";
import { addEvent, addMsg, addRoom } from "screens/chat/chatSlice";
import { webrtcManager } from "../WebRTCManager";
import { WebRTCMsg_WebRTCMsgType, WsMessage } from "../protos/gateway";
import { setPrompt } from "../screens/chat/TextPrompt/textPromptSlice";

let wsInFlight: boolean = false;

// Define the WebSocket actions
const SOCKET_CONNECT = "socket/connect";
const SOCKET_DISCONNECT = "socket/disconnect";
const SOCKET_POST_MESSAGE = "socket/postMessage";

interface SocketConnectAction {
  type: typeof SOCKET_CONNECT;
  payload: {
    url: string;
  };
}

interface SocketDisconnectAction {
  type: typeof SOCKET_DISCONNECT;
}

interface SocketPostMessageAction {
  type: typeof SOCKET_POST_MESSAGE;
  payload: WsMessage;
}

let backlog: Uint8Array[] = [];

type KnownAction =
  | SocketConnectAction
  | SocketDisconnectAction
  | SocketPostMessageAction;

export const websocketMiddleware = (
  objectsWS: WebSocket,
): Middleware<{}, AppState> => {
  return (storeApi) => (next) => (action) => {
    const dispatch = storeApi.dispatch;
    //check if action is KnownAction
    let castedAction = action as KnownAction;
    if (castedAction) {
      switch (castedAction.type) {
        case "socket/connect":
          if (wsInFlight) {
            return;
          }
          wsInFlight = true;
          const url = new URL(window.location.toString());
          const isDev = process.env.NODE_ENV === "development";
          const port = isDev ? "8444" : url.port;

          const wsProt = wsProtocol(url.protocol);

          objectsWS = new WebSocket(
            `${wsProt}://${url.hostname}:${port}/ws/chat`,
          );

          console.log("WebSocket connection established:", objectsWS.url);

          objectsWS.onopen = () => {
            wsInFlight = false;
            if (backlog.length > 0) {
              backlog.forEach((msg) => objectsWS.send(msg));
              backlog = [];
            }
          };

          objectsWS.onmessage = async (message) => {
            // Text message (JSON-fied WsMsg struct) received from Proxy
            if (message.data instanceof Blob) {
              const data = await message.data.arrayBuffer();

              try {
                const wsMsgPb = WsMessage.decode(new Uint8Array(data));

                if (wsMsgPb.chatMsg) {
                  console.log("Received chat message", wsMsgPb.chatMsg.id);
                  dispatch(addMsg(wsMsgPb.chatMsg));
                } else if (wsMsgPb.room) {
                  dispatch(addRoom(wsMsgPb.room));
                } else if (wsMsgPb.eurekaEvent) {
                  console.log("👾", wsMsgPb.eurekaEvent);
                  dispatch(addEvent(wsMsgPb.eurekaEvent));
                } else if (wsMsgPb.voiceTranscription) {
                  dispatch(setPrompt(wsMsgPb.voiceTranscription.text));
                } else if (wsMsgPb.webrtc) {
                  console.log("Received webrtc message", wsMsgPb.webrtc);

                  switch (wsMsgPb.webrtc.type) {
                    case WebRTCMsg_WebRTCMsgType.SIGNAL:
                      if (wsMsgPb.webrtc.payload === "startDone") {
                        dispatch({ type: "webrtc/startDone" });
                      }
                      break;
                    case WebRTCMsg_WebRTCMsgType.OFFER:
                      webrtcManager.handleOffer(
                        new RTCSessionDescription(
                          JSON.parse(wsMsgPb.webrtc.payload),
                        ),
                      );
                      break;
                    case WebRTCMsg_WebRTCMsgType.ANSWER:
                      webrtcManager.handleAnswer(
                        new RTCSessionDescription(
                          JSON.parse(wsMsgPb.webrtc.payload),
                        ),
                      );
                      break;
                    case WebRTCMsg_WebRTCMsgType.CANDIDATE:
                      webrtcManager.handleCandidate(
                        new RTCIceCandidate(JSON.parse(wsMsgPb.webrtc.payload)),
                      );
                      break;
                    case WebRTCMsg_WebRTCMsgType.CONFIG:
                      webrtcManager.handleConfig(
                        JSON.parse(wsMsgPb.webrtc.payload) as RTCIceServer,
                      );
                      dispatch({ type: "webrtc/startDone" });
                      break;
                  }
                }
              } catch (e) {
                console.error("Error decoding protobuf message", e);
              }
            }
          };

          objectsWS.onclose = () => {
            wsInFlight = false;
            console.warn("Websocket Disconnected. Attempting Reconnection...");

            // We reconnect after 3 seconds
            setTimeout(() => dispatch({ type: "socket/connect" }), 3000);
          };

          objectsWS.onerror = () => {
            wsInFlight = false;
            console.error(
              "Error in websocket connection. Attempting reconnection...",
            );
            // Onclose will be triggered by specification, reconnect function will be executed there to avoid duplicated requests
          };
          break;

        case "socket/postMessage":
          const jsonPayload = WsMessage.encode(castedAction.payload).finish();
          console.log("Sending message", objectsWS);
          if (objectsWS && objectsWS.readyState === WebSocket.OPEN) {
            objectsWS.send(jsonPayload);
          } else {
            backlog.push(jsonPayload);
          }

          break;
        case "socket/disconnect":
          objectsWS.close();
          break;

        default:
          break;
      }
    }

    return next(action);
  };
};
