import { Box, MenuItemProps } from "mds";
import React, { Fragment } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Queues from "./Queues";
import Domains from "./domains/Domains";
import Collections from "./collections/Collections";
import Reports from "./reports/Reports";
import TestPrompts from "./test-prompts/TestPrompts";
import Crawler from "./crawler/Crawler";
import { appRoutes } from "../../components/menu/valid-routes";
import Search from "screens/search/Search";

const Management = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(pathname);

  let routes: MenuItemProps[] = appRoutes();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: 320,
          backgroundColor: "#F7F9FC",
          flexGrow: 0,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 50,
          font: " normal normal normal 14px/50x Inter",

          "& .min-icon": {
            fill: "#C5CDDB",
            width: 13,
          },
        }}
      >
        {routes.map((mitem) => (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                fontWeight: "bold",
                gap: 16,
                height: 40,
                lineHeight: "40px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#EDF1F6",
                },
                "&.selected": {
                  color: "#278EAD",
                },
                "&.selected .min-icon": {
                  fill: "#278EAD",
                },
              }}
              onClick={() => navigate(mitem.path!)}
              className={
                pathname === mitem.path! ||
                mitem.children?.find((c) => pathname.startsWith(c.path!))
                  ? "selected"
                  : ""
              }
            >
              <div>{mitem.icon}</div>
              <Box sx={{ textTransform: "uppercase" }}>{mitem.name}</Box>
            </Box>
            {mitem.children?.map((childItem) => (
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: 48,
                  height: 48,
                  lineHeight: "48px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#EDF1F6",
                  },
                  "&.selected": {
                    backgroundColor: "#EDF1F6",
                    borderRadius: 6,
                    fontWeight: "bold",
                  },
                }}
                onClick={() => navigate(childItem.path!)}
                className={
                  pathname.startsWith(childItem.path!) ? "selected" : ""
                }
              >
                <div>{childItem.name}</div>
              </Box>
            ))}
          </Fragment>
        ))}
      </Box>
      <div
        style={{
          flexGrow: 1,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Routes>
          <Route path={"/search/*"} element={<Search />} />
          <Route path={"/domains/*"} element={<Domains />} />
          <Route path={"/collections/*"} element={<Collections />} />
          <Route path={"/queues/*"} element={<Queues />} />
          <Route path={"/test-prompts/*"} element={<TestPrompts />} />
          <Route path={"/reports/*"} element={<Reports />} />
          <Route path={"/crawler/*"} element={<Crawler />} />
          <Route path={"*"} element={<Navigate to="/queues" replace />} />
        </Routes>
      </div>
    </div>
  );
};
export default Management;
