import React from "react";

interface IIconWithLabel {
  icon: JSX.Element;
  strings: string[];
}

const IconWithLabel = ({ icon, strings }: IIconWithLabel) => {
  return (
    <div>
      {icon}
      <span>{strings[strings.length - 1]}</span>
    </div>
  );
};

export default IconWithLabel;
