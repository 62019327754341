// Copyright 2024 - MinIO, Inc. All rights reserved.

import { Box } from "mds";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const VoiceChatOverlay = () => {
  const dispatch = useAppDispatch();
  const { roomID } = useParams();

  const roomType = useAppSelector((state) => state.chat.roomType[roomID!]);

  const useVoice = roomType !== undefined && roomType === "audio";

  useEffect(() => {
    if (roomID && useVoice) {
      console.log("VoiceChatOverlay", roomID);
      dispatch({ type: "webrtc/connect", roomID: roomID });
      return () => {
        dispatch({ type: "webrtc/disconnect" });
      };
    }
  }, [roomID, useVoice, dispatch]);

  if (!roomID || !useVoice) {
    return <Fragment />;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 54,
        left: 16,
        zIndex: 9999,
        borderRadius: 6,
        border: "1px solid #278EAD",
        padding: 8,
        backgroundColor: "white",
      }}
    >
      Voice Chat Overlay `{roomID}`
    </Box>
  );
};
export default VoiceChatOverlay;
