import { RedirectRule } from "../../api/eurekaApi";

export const redirectRules = (a: RedirectRule, b: RedirectRule) => {
  if (a.displayName && b.displayName) {
    if (a.displayName > b.displayName) {
      return 1;
    }
    if (a.displayName < b.displayName) {
      return -1;
    }
  }
  return 0;
};
