import React, { ReactElement } from "react";
import { CopyIcon, IconButton } from "mds";

type CodeCopyButtonProps = {
  children: React.ReactNode | React.ReactNode[];
};

const CodeCopyButton = ({ children }: CodeCopyButtonProps) => {
  const handleClick = (_: any) => {
    if (children) {
      const firstChild = React.Children.toArray(children)[0] as ReactElement;
      const secondChild = React.Children.toArray(firstChild.props.children);
      if (secondChild) {
        const textToCopy = secondChild.toString();
        navigator.clipboard.writeText(textToCopy);
      }
    }
  };
  return (
    <div className="code-copy-btn">
      <IconButton onClick={handleClick} size="small">
        <CopyIcon />
      </IconButton>
    </div>
  );
};

export default CodeCopyButton;
