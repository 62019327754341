import MainError from "components/MainError";
import React, { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import AppMenu from "components/menu/AppMenu";
import { useAppDispatch } from "app/hooks";
import { KBarProvider } from "kbar";
import { startNewConversationAsync } from "../chat/chatThunks";
import { useNavigate } from "react-router-dom";
import CommandBar from "./CommandBar";
import { toggleMenu } from "screens/systemSlice";
import { appRoutes } from "../../components/menu/valid-routes";
import { Box } from "mds";

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "socket/connect" });
  }, [dispatch]);

  const actions = [
    {
      id: "new-conversation",
      name: "Start New Conversation",
      shortcut: ["n"],
      keywords: "new conversation",
      perform: () => {
        dispatch(startNewConversationAsync()).then((ok) => {
          console.log(ok);
        });
        navigate("/");
      },
    },
    {
      id: "toggle-menu",
      name: "Toggle Menu",
      shortcut: ["m"],
      keywords: "toggle menu",
      perform: () => dispatch(toggleMenu()),
    },
  ];

  const menuRoutes = appRoutes();
  menuRoutes.forEach((m) => {
    actions.push({
      id: m.id!,
      name: m.name,
      shortcut: [],
      keywords: m.name,
      perform: () => {
        navigate(m.path!);
      },
    });
    if (m.children?.length! > 0) {
      m.children?.forEach((m2) => {
        actions.push({
          id: m2.id!,
          name: m2.name,
          shortcut: [],
          keywords: m2.name,
          perform: () => {
            navigate(m2.path!);
          },
        });
      });
    }
  });

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
      actions={actions}
    >
      <CommandBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <AppMenu />
        <MainError />
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <AppRoutes />
        </Box>
      </Box>
    </KBarProvider>
  );
};

export default App;
