import React, { Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getChunksAsync } from "./transcriptThunks";

const Transcript = () => {
  const dispatch = useAppDispatch();

  const conversation = useAppSelector((state) => state.transcript.conversation);
  const speakers = useAppSelector((state) => state.transcript.speakers);
  const moreResults = useAppSelector((state) => state.transcript.moreResults);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          maxWidth: 720,
          margin: "auto",
          paddingLeft: 16,
          paddingRight: 16,
        }}
        id={"scrollableDiv"}
      >
        <InfiniteScroll
          dataLength={conversation.length}
          next={() => {
            dispatch(getChunksAsync());
          }}
          hasMore={moreResults}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          // height={"calc(100vh - 92px)"}
          height={"calc(100vh)"}
          endMessage={
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                padding: 32,
                color: "#999999",
              }}
            >
              End of the conversation
            </div>
          }
        >
          {conversation.map((conv) => (
            <div
              style={{
                display: "flex",

                gap: 8,
                backgroundColor: speakers[conv.from],
                borderRadius: 4,
                padding: 16,
                marginTop: 16,
              }}
              key={conv.id}
            >
              <div style={{ fontWeight: "bold" }}>{conv.from}</div>
              <div>{conv.text}</div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </Fragment>
  );
};

export default Transcript;
