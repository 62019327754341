import React from "react";
import "./Drawer.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Questions from "./Questions";
import { resetQuestionsViewer } from "./questionsSlice";

const QuestionsDrawer = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.questions.open);

  return (
    <div className={`drawer ${open ? "open" : ""}`}>
      <div
        onClick={() => dispatch(resetQuestionsViewer())}
        className={"drawer-empty"}
      ></div>
      <div className={"drawer-content"}>
        <Questions />
      </div>
    </div>
  );
};

export default QuestionsDrawer;
