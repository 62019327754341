import { Select } from "mds";
import { SelectProps } from "mds/dist/esm/types/components/Select/Select.types";

const ESelect: React.FC<SelectProps> = ({ ...props }) => (
  <Select
    sx={{
      color: "#278ead",
      "& .col_type-select div:first-child": {
        borderColor: "#278ead",
      },
      "& svg.min-icon": {
        fill: "#278ead",
        color: "#278ead",
      },
    }}
    {...props}
  />
);

export default ESelect;
