import storage from "local-storage-fallback";

export const deleteCookie = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const clearSession = () => {
  storage.removeItem("eureka-token");
  storage.removeItem("auth-state");
  deleteCookie("eureka-token");
  deleteCookie("idp-refresh-token");
};
