import { Api, ApiError, FullRequestParams, HttpResponse } from "./eurekaApi";

export let api = new Api();
const internalRequestFunc = api.request;
api.request = async <T = any, E = any>({
  body,
  secure,
  path,
  type,
  query,
  format,
  baseUrl,
  cancelToken,
  ...params
}: FullRequestParams): Promise<HttpResponse<T, E>> => {
  const internalResp = internalRequestFunc({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params,
  });
  return internalResp.catch((e) => CommonAPIValidation(e));
};

export function CommonAPIValidation<D, E>(
  res: HttpResponse<D, E>,
): HttpResponse<D, E> {
  const err = res.error as ApiError;
  if (err && res.status === 403 && err.message === "invalid session") {
    document.location = "/";
  }
  throw res;
}
